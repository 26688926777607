import React, { useState, useEffect, useRef } from 'react';
import HandleScroll from '../../util/functions/handleScroll';
import { t } from 'i18next';
import { useForm } from 'react-hook-form';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { auth } from '../../api/auth';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { signup as rules } from '../../constants/rules';
import { eyeclosed, eyeopen } from '../../constants/icons';
import NavBuyer from '../../layouts/NavBuyer';
import LoginBackground from '../../components/pages/common/LoginBackgound';
import Footer from '../../layouts/Footer';
import GeneralTitle from '../../components/ui/Title';
import InputField from '../../components/ui/InputField';

const ConfirmForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const [email, setEmail] = useState();
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resend, setResend] = useState(false);

  const navigate = useNavigate();

  const password = watch('password') || '';

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    setEmail(JSON.parse(localStorage.getItem('email')));
    HandleScroll();
  }, []);

  const [passwordType, setPasswordType] = useState('password');
  const [passwordType1, setPasswordType1] = useState('password');

  const togglePassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
    } else {
      setPasswordType('password');
    }
  };

  const togglePassword1 = () => {
    if (passwordType1 === 'password') {
      setPasswordType1('text');
    } else {
      setPasswordType1('password');
    }
  };

  const inputRefs = Array(6)
    .fill(0)
    .map(() => useRef(null));

  const handleCodeChange = (index, e) => {
    const value = e.target.value;
    if (value > 9) {
      if (value !== '' && index < 5) {
        inputRefs[index + 1].current.focus();
      }
      return;
    }

    if (
      value !== '' &&
      verificationCode.length <= 6 &&
      verificationCode[index] !== value
    ) {
      setVerificationCode((prevCode) => {
        const codeArr = prevCode.split('');
        codeArr.splice(index, 1, value);
        return codeArr.join('');
      });
      if (index < 5) {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleCodePaste = (e) => {
    const pastedCode = e.clipboardData.getData('text');
    if (/^\d{6}$/.test(pastedCode)) {
      setVerificationCode(pastedCode);
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.code === 'Backspace' || event.code === 'Delete') {
      console.log('Se presionó la tecla de borrar');

      if (event.target?.value === '' && index > 0) {
        setVerificationCode((prevCode) => {
          const codeArr = prevCode.split('');

          codeArr.splice(index, 1, '');

          return codeArr.join('');
        });
        inputRefs[index - 1].current.focus();
      } else {
        event.target.value = '';
      }
    }
  };

  const onSubmit = (data) => {
    if (verificationCode.length === 6) {
      setError(false);
      setLoading(true);

      const { password } = data;

      auth
        .confirm_forgot_password({ email, code: verificationCode, password })
        .then((response) => {
          if (response.status === 200) {
            navigate('/login');
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    } else {
      setError(true);
      setLoading(false);
    }
  };

  const resend_confirmation_code = () => {
    if (!resend) {
      setResend(true);
      auth
        .resend_confirmation_code({ email })
        .then((response) => {
          if (response.status === 200) {
            Swal.fire(
              'Enhorabuena',
              'Hemos enviado un nuevo codigo de verificación a tu correo',
              'success',
            );
          }
        })
        .catch((err) => console.error(err))
        .finally(() =>
          setTimeout(() => {
            setResend(false);
          }, 60000),
        );
    }
  };

  return (
    <>
      <NavBuyer />
      <LoginBackground>
        <div className="h-screen w-screen flex justify-center items-start">
          <form
            className="flex flex-col w-[95%] md:w-[70%] lg:w-1/3 my-[10vh] px-[2rem] md:px-[4rem]"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-col justify-center items-center h-full">
              <div className="text-center my-4">
                <GeneralTitle title={t('Nueva contraseña')} />
              </div>

              {error && <p className="text-[#FF0000]">Llena todos los campos</p>}

              <div className="text-center mb-[1rem]">
                <p>Escribe tu nueva contraseña</p>
              </div>

              <div className='mb-5 w-full'>
                <div className="flex gap-[4px]">
                  {/*                   <input
                    type={passwordType}
                    name="password"
                    className={`border-b-[0.2rem] border-primary p-[0.8rem] placeholder-primary text-primary ${'buyer' === 'buyer'
                      ? 'bg-[#0F7C79]/[.15]'
                      : 'bg-[#FFBA6E]/[.15]'
                      } rounded focus:outline-none text-sm w-full`}
                    placeholder="New password"
                    style={{ transition: 'all .15s ease' }}
                    {...register('password', rules.signup.password)}
                  /> */}
                  <InputField
                    label='Nueva contraseña'
                    type={passwordType}
                    field={register('password', rules.signup.password)}
                  />
                  {/*                   <div
                    className="flex items-center px-[1rem] border-b-[0.2rem] border-[0.1rem] border-primary rounded cursor-pointer"
                    onClick={togglePassword}
                  >
                    {passwordType === 'password' ? eyeclosed : eyeopen}
                  </div> */}
                </div>

                {errors['password'] ? (
                  <div className="h-[1rem] flex items-center">
                    <p className="text-xs text-red-700">
                      {errors['password'].message}
                    </p>
                  </div>
                ) : (
                  <div className="h-[1rem]" />
                )}

                <div className="flex gap-[4px]">
                  {/*                   <input
                    type={passwordType1}
                    className={`border-b-[0.2rem] border-primary p-[0.8rem] placeholder-primary text-primary ${'buyer' === 'buyer'
                      ? 'bg-[#0F7C79]/[.15]'
                      : 'bg-[#FFBA6E]/[.15]'
                      } rounded focus:outline-none text-sm w-full`}
                    placeholder="Confirm password"
                    style={{ transition: 'all .15s ease' }}
                    {...register('confirmPassword', {
                      ...rules.signup.confirmPassword,
                      validate: (value) =>
                        value === password || 'Las contraseñas no coinciden',
                    })}
                  /> */}
                  <InputField
                    label='Confirmar contraseña'
                    type={passwordType1}
                    field={{
                      ...register('confirmPassword', {
                        ...rules.signup.confirmPassword,
                        validate: (value) =>
                          value === password || 'Las contraseñas no coinciden',
                      })
                    }}
                  />
                  {/*                   <div
                    className="flex items-center px-[1rem] border-b-[0.2rem] border-[0.1rem] border-primary rounded cursor-pointer"
                    onClick={togglePassword1}
                  >
                    {passwordType1 === 'password' ? eyeclosed : eyeopen}
                  </div> */}
                </div>
              </div>

              {errors['confirmPassword'] ? (
                <div className="h-[2rem] flex items-center">
                  <p className="text-xs text-red-700">
                    {errors['confirmPassword'].message}
                  </p>
                </div>
              ) : (
                <div className="h-[2rem]" />
              )}

              <div className='my-4'>
                <button
                  type="submit"
                  className="flex items-center text-primary bg-secondary rounded-[50px] py-[0.6rem] md:py-[0.8rem] p-[1.2rem] md:p-[1.6rem] font-Montserrat font-[700] text-[10px] md:text-[14px] shadow-lg hover:text-secondary hover:bg-primary"
                >
                  {loading && <Spin indicator={antIcon} />}
                  <p className="font-[600]">{t('CAMBIAR CONTRASEÑA')}</p>
                </button>
              </div>
            </div>
          </form>
        </div>
      </LoginBackground>
      <Footer text="¡Queremos conectar contigo!" />
    </>
  );
};

export default ConfirmForgotPassword;

import React, { useState } from "react";
import { t } from 'i18next';
import GeneralCard from "../../../ui/GeneralCard";
import GeneralTitle from "../../../ui/Title";

const OrderInvoice = ({ use, status }) => {


  const CenteredRow = ({ text1, text2, fontWeight1, fontWeight2 }) => {
    return (
      <div className="w-[80%] mx-auto">
        <div className="flex flex-row justify-between items-center">
          <div className={`flex items-start font-${fontWeight1}`}>
            <p>{text1}</p>
          </div>
          <div className={`flex items-end font-${fontWeight2}`}>
            <p>{text2}</p>
          </div>
        </div>
      </div>
    );
  };


  return (
    <div className="max-w-screen-lg mx-auto bg-white rounded-[20px] border border-solid border-[#ececec] shadow mb-4 py-4 px-2">
      <div className="w-[90%] mx-auto text-start">
        <GeneralTitle title={t('Factura de orden N°00')} />
      </div>

      <div>
        <div className="flex justify-center py-[1rem]">
        </div>

        <CenteredRow text1="Estado 1" text2="Estado 2" />
        <CenteredRow text1="Estado 1" text2="Estado 2" />
        <CenteredRow text1="Estado 1" text2="Estado 2" />
        <CenteredRow text1="Estado 1" text2="Estado 2" />

        <div className="flex justify-center py-1">
          <div className="h-[3px] w-[90%] rounded-lg" style={{ backgroundColor: '#A1A1A1' }} />
        </div>

        <CenteredRow fontWeight1="[700]" fontWeight2="[700]" text1="Estado 1" text2="Estado 2" />
      </div>
    </div>
  );
};

export default OrderInvoice;
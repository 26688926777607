import { FaQuestionCircle } from 'react-icons/fa';
import SubTitle from "../../../components/ui/SubTitle";
import DescriptionSmall from "../../../components/ui/DescriptionSmall";

const Help = () => {
  return (
    <div className="max-w-lg mx-auto p-4">
      <SubTitle title="¿Necesitas ayuda?" />
      <DescriptionSmall description="En Birdyback trabajamos para que tengas un servicio eficiente de principio a fin. Si tienes alguna duda, sugerencia o comentario, puedes comunicarte con nosotros a cualquiera de las siguientes opciones:" />
      <div className="flex items-center">
        <div className="mr-2 text-blue-500">
          <FaQuestionCircle size={24} />
        </div>
        <div>
          <h3 className="text-lg font-semibold">Soporte 24/7</h3>
          <p className="text-gray-600">Estamos aquí para ayudarte en cualquier momento.</p>
        </div>
      </div>
    </div>
  );
};

export default Help;

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from '@material-tailwind/react';
import React from 'react';
import { check, dash } from '../../../../../constants/icons';
import { Button } from 'flowbite-react';
import { useDispatch } from 'react-redux';
import { changeStatus } from '../../../../../features/user/servicesSlide';
import { useNavigate } from 'react-router-dom';

const ProductReceipt = ({ currentService, stepNumber }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      <Accordion
        icon={stepNumber > 4 ? check : dash}
        open={stepNumber === 4}
        disabled={stepNumber !== 4}
      >
        <AccordionHeader>
          <h5>Recepción de producto</h5>
        </AccordionHeader>
        <AccordionBody>
          <p>Me encantó el trabajo que realizo el viajero con mi producto</p>
          <div className="flex justify-center">
            <Button
              color="success"
              onClick={() => {
                navigate(-1);
                setTimeout(() => {
                  dispatch(
                    changeStatus({ id: currentService.id, status: 'finished' }),
                  );
                }, 1000);
              }}
            >
              OK
            </Button>
          </div>
        </AccordionBody>
      </Accordion>
    </>
  );
};

export default ProductReceipt;

import { Fragment, useEffect, useState } from 'react';
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Link, Outlet, redirect } from 'react-router-dom';
import main from '../assets/js/main';
import logo from '../assets/logos/logo-birdyback-sin-fuente.png';
import { useTranslation } from 'react-i18next';
import { changeAuth } from '../features/auth/authSlide';
import { useDispatch } from 'react-redux';

function classNames (...classes) {
  return classes.filter(Boolean).join(' ');
}

const NavTraveler = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   main();
  // }, []);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const logout = () => {
    localStorage.removeItem('auth');
    // dispatch(logoutUser());
    window.location.reload(true);
    redirect('/landing');
  };

  return (
    <div className="relative">
      <header
        className="header sticky bg-white top-[-2px] left-0 z-[1] flex w-full items-center"
        style={
          mobileMenuOpen
            ? { transition: 'top 0.5s', display: 'none' }
            : { transition: 'top 0.5s' }
        }
      >
        <nav
          // className="flex w-full items-center justify-between px-[1rem] lg:px-[8rem]"
          // className="nav-shadow flex w-full items-center justify-between px-[1rem] py-[11px] xl:px-[8rem] shadow-lg"
          className="nav-shadow flex w-full items-center justify-between px-[1rem] xl:px-[8rem] shadow-lg"
          aria-label="Global"
        >
          <div>
            <Link to="/">
              <img src={logo} alt="logo" className="w-[80px]" alt="Logo de Birdyback sin fuentes" />
            </Link>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-primary"
              onClick={() => setMobileMenuOpen(true)}
            >
              {mobileMenuOpen ? (
                <XMarkIcon className="w-[50px]" aria-hidden="true" />
              ) : (
                <Bars3Icon className="w-[50px]" aria-hidden="true" />
              )}
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12 items-center">
            <Popover.Group className="hidden lg:flex lg:gap-x-12">
              <Link
                className="text-[16px] font-medium leading-6 text-primary font-Montserrat"
                to="/"
              >
                {t('home')}
              </Link>
              <Link
                className="text-[16px] font-medium leading-6 text-primary font-Montserrat hover:text-primary"
                to="/services"
              >
                {t('Servicios')}
              </Link>
              <Link
                className="text-[16px] font-medium leading-6 text-primary font-Montserrat hover:text-primary"
                to="/settings"
              >
                {t('settings')}
              </Link>
              <Popover className="relative">
                <Popover.Button className="flex items-center gap-x-1 text-[16px] font-medium leading-6 text-primary font-Montserrat">
                  {t('lan')}
                  <ChevronDownIcon
                    className="h-5 w-5 flex-none text-primary font-Montserrat"
                    aria-hidden="true"
                  />
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-[10rem] max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-primary/5">
                    <div className="p-4">
                      <div className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50">
                        <div className="flex-auto">
                          <button
                            className="block font-semibold text-primary font-Montserrat"
                            onClick={() => i18n.changeLanguage('en')}
                          >
                            {t('Inglés')}
                            <span className="absolute inset-0" />
                          </button>
                        </div>
                      </div>
                      <div className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50">
                        <div className="flex-auto">
                          <button
                            className="block font-semibold text-primary font-Montserrat"
                            onClick={() => i18n.changeLanguage('es')}
                          >
                            {t('Español')}
                            <span className="absolute inset-0" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </Popover>
              <Link
                className="text-[16px] font-medium leading-6 text-primary font-Montserrat hover:text-primary"
                onClick={logout}
              >
                {t('Cerrar sesión')}
              </Link>
            </Popover.Group>
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between px-[1rem]">
              <Link to="/">
                <img src={logo} alt="logo" className="w-[80px]" alt="Logo de Birdyback sin fuentes" />
              </Link>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <XMarkIcon className="w-[50px]" aria-hidden="true" />
              </button>
            </div>
            <div className="flow-root px-6">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <Link
                    onClick={() => setMobileMenuOpen(false)}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold font-Montserrat leading-7 text-gray-900 hover:bg-gray-50"
                    to="/"
                  >
                    {t('home')}
                  </Link>
                  <Link
                    onClick={() => setMobileMenuOpen(false)}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold font-Montserrat leading-7 text-gray-900 hover:bg-gray-50"
                    to="/services"
                  >
                    {t('Servicios')}
                  </Link>
                  <Link
                    onClick={() => setMobileMenuOpen(false)}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold font-Montserrat leading-7 text-gray-900 hover:bg-gray-50"
                    to="/settings"
                  >
                    {t('settings')}
                  </Link>
                  <Disclosure as="div" className="-mx-3">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold font-Montserrat leading-7 text-gray-900 hover:bg-gray-50">
                          {t('lan')}
                          <ChevronDownIcon
                            className={classNames(
                              open ? 'rotate-180' : '',
                              'h-5 w-5 flex-none',
                            )}
                            aria-hidden="true"
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-2 space-y-2">
                          <Disclosure.Button
                            as="button"
                            onClick={() => i18n.changeLanguage('en')}
                            className="block rounded-lg py-2 text-sm font-semibold font-Montserrat leading-7 text-gray-900 hover:bg-gray-50 w-full"
                          >
                            {t('Inglés')}
                          </Disclosure.Button>
                          <Disclosure.Button
                            as="button"
                            onClick={() => i18n.changeLanguage('es')}
                            className="block rounded-lg py-2 text-sm font-semibold font-Montserrat leading-7 text-gray-900 hover:bg-gray-50 w-full"
                          >
                            {t('Español')}
                          </Disclosure.Button>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>
                <div className="py-6">
                  <Link
                    onClick={() => {
                      setMobileMenuOpen(false);
                      logout();
                    }}
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold font-Montserrat leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {t('Cerrar sesión')}
                  </Link>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
      <Outlet />
    </div>
  );
};

export default NavTraveler;

import React from 'react';

import Section from '../../ui/Section';

import smallCloudTopRight from '../../../assets/background/small-cloud-top-right.png';
import smallCloudTopLeft from '../../../assets/background/small-cloud-top-left.png';
import rightMountain from '../../../assets/background/montaña-derecha-azul-birdybacker.svg';

const LoginBackground = ({ children }) => {

  const styleRotateX = {
    transform: 'rotateY(3.142rad)',
  }

  return (
    <Section>
      {/*       <img
        className="block md:hidden lg:hidden absolute w-[240px] md:w-auto top-[4vh] left-[208px] z-[-1]"
        src={smallCloudTopRight}
        alt="Nube derecha grande naranja ilustrada representando al Birdybuyer (comprador) de Birdyback"
      /> */}

      <img
        style={styleRotateX}
        className="block md:hidden lg:hidden absolute w-[240px] md:w-auto top-[9vh] left-[-78px] z-[-1]"
        src={smallCloudTopRight}
        alt="Nube derecha grande naranja ilustrada representando al Birdybuyer (comprador) de Birdyback"
      />

      <img
        style={styleRotateX}
        className="absolute rotate-90 h-full md:h-auto w-full top-[260px] md:top-auto bottom-0 right-0 z-[-1]"
        src={rightMountain}
        alt="Montaña derecha azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      <div className="hidden lg:block md:block absolute bg-[#CFF6EB] h-[72px] md:h-[2vh] lg:h-[10vh] w-full bottom-[-2px] right-0 z-[-1]" />

      {children}
    </Section>
  );
};

export default LoginBackground;

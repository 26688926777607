import React, { useState } from 'react';
import { Button, Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ModalService from '../../components/traveler/services/ModalService';
import Swal from 'sweetalert2';
import { t } from 'i18next';
import { Link, useNavigate } from 'react-router-dom';
import GoTraveler from '../../components/traveler/GoTraveler';
import { pencil, trash } from '../../constants/icons';
import GoBuyer from '../../components/buyer/GoBuyer';
import RoundedButton from '../../components/ui/RoundedButton';
import { deleteOrder } from '../../features/user/ordersSlide';

import HeaderBuyerOrTraveler from '../../components/ui/HeaderBuyerOrTraveler';
import CardOrden from '../../components/buyer/components/Product/CardOrden';
import StepCardProduct from '../../components/buyer/components/Product/StepCardProduct';
import OrderInvoice from '../../components/buyer/components/Product/OrderInvoice';
import BirdybuyerOffer from '../../components/buyer/components/Product/BirdybuyerOffer';

const Orders = () => {
  const orders = useSelector((store) => store.orders);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [page, setpage] = useState(0);
  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState();

  const nameElementsHeader = {
    firstNameElement: 'Activas',
    SecondNameElement: 'Por pagar'
  }

  const [selectedOption, setSelectedOption] = useState(nameElementsHeader.firstNameElement);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const deleteCurrentOrder = (id) => {
    Swal.fire({
      title: 'Deseas eliminar esta orden?',
      text: 'Verifica que sea correcta la orden que deseas eliminar',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'No, cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteOrder(id));
      }
    });
  };

  return (
    <HeaderBuyerOrTraveler
      selectedOption={selectedOption}
      handleOptionClick={handleOptionClick}
      firstNameElement={nameElementsHeader.firstNameElement}
      SecondNameElement={nameElementsHeader.SecondNameElement}
      content={
        selectedOption === nameElementsHeader.firstNameElement
          ?
          <div className='lg:w-[80%] w-full mt-10'>
            <OrdersPage />
          </div>
          :
          <div className='lg:w-[80%] w-full mt-10'>
            <CardOrden use={"active"} status={"por pagar"} />
            <OrderInvoice />
            <BirdybuyerOffer />
          </div>
      }
    />
  );

  function OrdersPage () {
    return (
      <>
        <CardOrden use={"active"} status={"en espera"} />
        <CardOrden use={"active"} status={"activo"} />
        <CardOrden use={"active"} status={"cancelado"} />
        <CardOrden use={"active"} status={"finalizado"} />

        {/*         <StepCardProduct /> */}
        <RoundedButton
          text="Añadir nueva orden"
          onClick={() => {
            setOrder();
            setOpen(true);
          }}
        />
        {open && <GoBuyer open={open} setOpen={setOpen} Order={order} />}
      </>
    );
  }
};

export default Orders;

import React, { useEffect, useState } from 'react';
import { Button, Card } from 'antd';
import {
  PlusOutlined,
  ShoppingCartOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { addAddress, addOrder } from '../../features/user/ordersSlide';
import { deleteProduct, reset } from '../../features/user/productsSlide';
import { useNavigate } from 'react-router-dom';
import { deleteAddress } from '../../features/user/userAddressesSlide';
import Swal from 'sweetalert2';
import ModalAddress from '../traveler/settings/AddressModal';

const SelectAddress = ({ setPage, Order }) => {
  useEffect(() => {
    document.querySelector('.header').classList.add('top-[-80px]');
    return () => {
      if (document.querySelector('.header')) {
        document.querySelector('.header').classList.remove('top-[-80px]');
      }
    };
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addresses = useSelector((state) => state.addresses);
  const orders = useSelector((state) => state.orders);

  const initialState = { id: -1 };

  const [address, setaddress] = useState(initialState);
  const [modal, setmodal] = useState(false);

  return (
    <div className="flex flex-col gap-[1rem]">
      <div className="bg-secondary grid grid-cols-3 gap-[1rem] p-[1rem] rounded-[10px]">
        {addresses.map((addresss) => (
          <Card
            key={addresss.id}
            onClick={() => setaddress(addresss)}
            className={addresss.id === address.id ? 'bg-primary' : null}
          >
            <h5 className="text-[24px]">{addresss.department}</h5>
            <p className="text-[16px]">{addresss.description}</p>

            <div className="ignore flex justify-around gap-[1rem]">
              <Button
                onClick={() => {
                  setaddress(addresss);
                  setmodal(true);
                }}
                className="ignore flex items-center"
                icon={<EditOutlined />}
              >
                Editar
              </Button>

              <Button
                onClick={() => {
                  dispatch(deleteAddress(addresss.id));
                }}
                className="ignore flex items-center"
                icon={<DeleteOutlined />}
                danger
              >
                Eliminar
              </Button>
            </div>
          </Card>
        ))}
        <div className="flex items-center">
          <Button
            onClick={() => setmodal(true)}
            className="flex items-center"
            size="large"
            icon={<PlusOutlined />}
          >
            Nueva direccion
          </Button>
        </div>
      </div>
      <div className="flex justify-between">
        <Button onClick={() => setPage('ShoppingCart')}>Anterior</Button>
        <Button
          onClick={() => {
            if (address !== initialState) {
              if (Order) {
                dispatch(addAddress({ id: Order.id, address: address }));
              } else {
                if (localStorage.getItem('id')) {
                  const id = JSON.parse(localStorage.getItem('id'));
                  dispatch(addAddress({ id: id, address: address }));
                }
              }
              localStorage.setItem('address', JSON.stringify(address));
              setPage('Result');
            } else {
              Swal.fire(
                'Ups',
                'Selecciona una direccion para continuar',
                'question',
              );
            }
          }}
        >
          Siguiente
        </Button>
      </div>
      <ModalAddress modal={modal} setmodal={setmodal} address={address} />
    </div>
  );
};

export default SelectAddress;

import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import * as Yup from 'yup';
import { t } from 'i18next';
import { ConfigProvider, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import smallCenteredOrangeCloud from '../../assets/background/nube-pequeña-centrada-naranja-birdybuyer.svg';
import largeLeftOrangeCloud from '../../assets/background/nube-grande-izquierda-naranja-birdybuyer.svg';
import SmallRightCloud from '../../assets/background/nube-pequeña-derecha-azul-birdybacker.svg';

import InputField from './InputField';
import InputAreaField from './InputAreaField';
import config from '../../app/config';

import './../../assets/css/tailwind.css';
import Swal from 'sweetalert2';

// Endpoint
import { contactUs } from '../../api/contactUs';

const ModalRegisterEmail = ({ open, setOpen }) => {

  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const keyCapcha = "6Lfw2gIpAAAAAHbv1OglI62yvua4sa0RyPQkb18o";

  const [formData, setFormData] = useState({
    email: '',
    name: '',
    subject: '',
    message: '',
  });

  const [errors, setErrors] = useState({
    email: '',
    name: '',
    subject: '',
    message: '',
  });

  const handleChange = (e, field) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleSubmit = async () => {
    const validationSchema = Yup.object().shape({
      email: Yup.string().email('Ingresa un correo electrónico válido').required('El correo electrónico es requerido'),
      name: Yup.string().required('El nombre es requerido'),
      subject: Yup.string().required('El asunto es requerido'),
      message: Yup.string().required('Por favor, cuéntanos más'),
    });

    // Si el reCAPTCHA no ha sido verificado, muestra un error
    if (!recaptchaValue) {
      // Muestra un mensaje de error indicando que se necesita la verificación de reCAPTCHA
      console.error('Por favor, completa el reCAPTCHA');
      return;
    }

    try {
      await validationSchema.validate(formData, { abortEarly: false });

      const res = await contactUs.contact(formData);
      console.log(res);

      setOpen(false);


      // Trigger SweetAlert2 here
      // Styles from the tailwinds.css in sec folder
      if (res.status === 200) {
        // Birdybacker success message
        Swal.fire({
          title: `<p class="swal-title">Mensaje Enviado</p>`,
          html: `<p class="swal-text">¡Gracias por contactarnos! Pronto te nuestro equipo se comunicará contigo.</p>`,
          confirmButtonText: 'Entendido',
          customClass: {
            confirmButton: 'swal-custom-button',
            popup: 'swal-frame'
          },
          showClass: {
            popup: 'animate__animated animate__fadeInUp animate__faster',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutDown animate__faster',
          },
        });
      } else {
        // Handle other status codes (e.g., show an error message)
        Swal.fire({
          title: 'Error de conexión',
          text: 'Se ha producido un error. Intenta más tarde',
          confirmButtonText: 'Entendido',
          customClass: {
            confirmButton: 'custom-swal-button',
          },
          showClass: {
            popup: 'animate__animated animate__fadeInUp animate__faster',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutDown animate__faster',
          },
        });
      }




    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const newErrors = {};

        error.inner.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });

        setErrors(newErrors);
      } else {
        console.error('Error:', error);
      }
    }
  };


  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            borderRadiusLG: 40,
            paddingContentHorizontal: 0,
            paddingContentVertical: 0,
            paddingLG: 0,
            marginLG: 0,
            paddingMD: 0,
            paddingXL: 0,
            paddingSM: 0,
            paddingContentHorizontalLG: 0,
          },
        },
      }}
    >
      <Modal
        centered
        closeIcon={false}
        open={open}
        onOk={null}
        onCancel={null}
        footer={null}
        width={600}
      >
        <BackgroundModal>
          <div className="relative flex flex-col items-center gap-[1rem] py-[2rem] overflow-hidden z-[1]">
            <FontAwesomeIcon
              className="absolute top-[17px] right-[16px] text-[24px] cursor-pointer"
              onClick={() => setOpen(false)}
              icon={faXmark}
            />

            <div className="text-center">
              <h4 className="font-Montserrat text-primary font-[700]">
                {t('¡Somos todo oidos!')}
              </h4>

              <p className="text-primary lg:px-[8rem]">
                {t(
                  'Sin importar lo que necesites aquí estamos para escucharte',
                )}
              </p>
            </div>

            <div className="flex py-[1rem]">
              <div className="bg-[#C6D12D] h-[4px] w-[160px] rounded-lg" />
            </div>

            {/*Campos del formulario*/}
            <div className='w-[80%] grid justify-items-center'>
              <InputField
                type="text"
                placeholder={t('Nombre completo')}
                label={t('Nombre completo')}
                value={formData.name}
                onChange={(e) => handleChange(e, 'name')}
              />
              <InputField
                type="email"
                placeholder={t('Correo electrónico')}
                label={t('Correo electrónico')}
                value={formData.email}
                onChange={(e) => handleChange(e, 'email')}
              />

              <InputField
                type="text"
                placeholder={t('Asunto')}
                label={t('Asunto')}
                value={formData.subject}
                onChange={(e) => handleChange(e, 'subject')}
              />
              <InputAreaField
                placeholder={t('Cuentanos más')}
                label={t('Cuentanos más')}
                value={formData.message}
                onChange={(e) => handleChange(e, 'message')}
              />
            </div>

            {/* Integra el reCAPTCHA */}
            <ReCAPTCHA
              sitekey={keyCapcha}
              onChange={handleRecaptchaChange}
            />

            {/* Mensaje de error para reCAPTCHA */}
            {!recaptchaValue && <p style={{ color: 'red' }}>Por favor, completa el reCAPTCHA</p>}


            {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
            {(errors.name || errors.subject || errors.message) && (
              <p style={{ color: 'red' }}>{t('Por favor llenar todos los campos.')}</p>
            )}
            <button
              className="bg-secondary font-Montserrat text-[16px] text-primary font-[600] px-[1.5rem] py-[0.8rem] rounded-[50px] w-[300px] lg:w-[400px] md:w-[400px]"
              onClick={handleSubmit}
            >
              {t('Enviar')}
            </button>
          </div>
        </BackgroundModal>
      </Modal>
    </ConfigProvider>
  );
};

const BackgroundModal = ({ children }) => {
  return (
    <section className="relative">
      <img
        className="absolute top-[5vh] left-[32px] z-[0]"
        src={smallCenteredOrangeCloud}
        alt="Nube centrada pequeña naranja ilustrada representando al Birdybuyer (comprador) de Birdyback"
      />

      <img
        className="absolute bottom-[0] left-0 z-[0]"
        src={largeLeftOrangeCloud}
        alt="Nube izquierda grande naranja ilustrada representando al Birdybuyer (comprador) de Birdyback"
      />

      <img
        className="absolute bottom-[160px] right-0 z-[0]"
        src={SmallRightCloud}
        alt="Nube derecha pequeña azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      {children}
    </section>
  );
};

export default ModalRegisterEmail;
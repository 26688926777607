import React, { useState } from 'react';
import ModalAddress from './AddressModal';
import RoundedButton from '../../ui/RoundedButton';
import Swal from 'sweetalert2';

import CardAddress from '../../ui/CardAddress';

import { useDispatch, useSelector } from 'react-redux';
import {
  deleteAddress,
  updateMainAddress,
} from '../../../features/user/userAddressesSlide';
import { t } from 'i18next';

const Addresses = () => {
  const menu = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-three-dots-vertical"
      viewBox="0 0 16 16"
    >
      <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
    </svg>
  );

  const addresses = useSelector((state) => state.addresses);

  const dispatch = useDispatch();

  const [modal, setmodal] = useState(false);

  const [address, setaddress] = useState({
    id: null,
    department: '',
    location: '',
    neighborhood: '',
    kindStreet: '',
    street: '',
    number: false,
    numberStreet: '',
    numberStreet2: '',
    place: '',
  });

  const handleAdd = () => {
    setaddress({
      id: null,
      department: '',
      location: '',
      neighborhood: '',
      kindStreet: '',
      street: '',
      number: false,
      numberStreet: '',
      numberStreet2: '',
      place: '',
    });
    setmodal(true);
  };

  const handleUptade = (id) => {
    setaddress(addresses.find((address) => address.id === id));
    setmodal(true);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: `${t('Are you sure?')}`,
      text: 'Borrarás la direccion seleccionada!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      try {
        if (result.isConfirmed) {
          dispatch(deleteAddress(id));
          Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  return (
    <div className="bg-white w-full p-[1rem]">
      <div className="flex flex-col gap-[1rem]">
        <h2 className="text-[1.5rem] text-[#0A7379]">{t('Mi direccion principal')}</h2>
        <div className="grid grid-cols-1 lg:grid-cols-1 gap-[2rem]">
          {addresses.length > 0 ? (
            addresses.map((address) => (
              address.mainAddress ? (
                <CardAddress address={address} />
              ) :
                null
            ))
          ) : (
            <div className="text-center text-white p-[4rem] col-span-2">
              <h1>{t('Aún no hay direcciones.')}</h1>
            </div>
          )}
        </div>


        <div className='border-t-2 border-[#A1A1A1] mt-10'></div>
        <h2 className="text-[1.5rem] text-[#0A7379]">{t('Otras direcciones')}</h2>

        <div className="grid grid-cols-1 lg:grid-cols-1 gap-[2rem]">
          {addresses.length > 0 ? (
            addresses.map((address) => (
              address.mainAddress ? (
                null
              ) :
                <CardAddress address={address} />
            ))
          ) : (
            <div className="text-center text-white p-[4rem] col-span-2">
              <h1>{t('Aún no hay direcciones.')}</h1>
            </div>
          )}
        </div>

        <div className="flex justify-center">
          <RoundedButton
            onClick={() => handleAdd()}
            text="Nueva dirección"
          />
        </div>
      </div>
      <ModalAddress modal={modal} setmodal={setmodal} address={address} />
    </div>
  );
};

export default Addresses;

import React from 'react';
import { t } from 'i18next';
import { Button, Card } from 'antd';
import { check } from '../../../constants/icons';

const StatusPanelCard = ({ cancelService, currentService }) => {
  return (
    <div className="flex justify-center h-[30rem]">
      <Card key={currentService.id}>
        <h2 className="text-[20px]">{t('Descripción')}</h2>
        <p>
          {t('Aerolinea')}: {currentService.airline}
        </p>
        <p>
          {t('Número de vuelo')}: {currentService.flight_number}
        </p>
        <p>
          {t('Fecha de vuelo')}: {currentService.date_flight}
        </p>
        <p className="flex items-center gap-[0.5rem]">
          {currentService.status === 'accepted' ? (
            <>
              {currentService.status_history.map((status) =>
                status.status === 'accepted' ? status.date_from : null,
              )}{' '}
              -{' Producto aceptado'}
              {check}
            </>
          ) : null}
        </p>
        {/* <p className="flex items-center gap-[0.5rem]">
          {currentService.status === 'accepted' ? (
            <>
              {currentService.status_history.map((status) =>
                status.status === 'accepted' ? status.date_from : null,
              )}{' '}
              -{' Verificación de compra y producto'}
              {check}
            </>
          ) : null}
        </p> */}
        {/* <p className="flex items-center gap-[0.5rem]">
          {currentService.process.step > 1 ? (
            <>
              {currentService.process.condition} -
              {' Condición fisica del producto'}
              {check}
            </>
          ) : null}
        </p>
        <p className="flex items-center gap-[0.5rem]">
          {currentService.process.subStep > 0 ? (
            <>
              {currentService.process.start} -{' Inicio'}
              {check}
            </>
          ) : null}
        </p>
        <p className="flex items-center gap-[0.5rem]">
          {currentService.process.subStep > 1 ? (
            <>
              {currentService.process.onBoard} -{' Producto abordo'}
              {check}
            </>
          ) : null}
        </p>
        <p className="flex items-center gap-[0.5rem]">
          {currentService.process.step > 2 ? (
            <>
              {currentService.process.arrival} -{' Llegada producto'}
              {check}
            </>
          ) : null}
        </p>
        <p className="flex items-center gap-[0.5rem]">
          {currentService.process.step > 3 ? (
            <>
              {currentService.process.shipping} -{' Envio producto'}
              {check}
            </>
          ) : null}
        </p> */}
        <div className="flex justify-center">
          <Button onClick={() => cancelService()} danger>
            Cancelar Servicio
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default StatusPanelCard;

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth, setUser } from '../features/auth/authSlide';
import { auth } from '../api/auth';
import Swal from 'sweetalert2';

export const useLogin = () => {
  const dispatch = useDispatch();

  const authUser = useSelector((state) => state.auth);

  const [userType, setUserType] = useState(
    authUser.userType === 'owner' ? 'owner' : 'buyer',
  );

  const [loading, setloading] = useState(false);

  const onSubmit = async ({ email, password }) => {
    setloading(true);

    await auth
      .signin({
        email,
        password,
        userType: userType,
      })
      .then(async (response) => {
        const birdyuser =
          response.data.result.AuthenticationResult.TokenType +
          ' ' +
          response.data.result.AuthenticationResult.IdToken;
        localStorage.setItem('birdyuser', JSON.stringify(birdyuser));
        await auth
          .get_user(birdyuser)
          .then((responseUser) => {
            if (responseUser.data.success) {
              dispatch(setAuth(response.data.result.AuthenticationResult));
              dispatch(setUser(responseUser.data.result));
            }
          })
          .catch((error) => console.error(error));
      })
      .catch((error) =>
        Swal.fire('Ups', error.response.data.error.message, 'error'),
      )
      .finally(() => setloading(false));
  };

  return { loading, userType, setUserType, onSubmit };
};

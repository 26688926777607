import { useState } from 'react';
import InputField from './InputField';
import GeneralTitle from './Title';

const ChangePassword = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });

  const handleChange = (e, field) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  const changePassword = () => {
    // Aquí podrías implementar la lógica para cambiar la contraseña
  };

  return (
    <div className="m-auto rounded-lg flex flex-col justify-center items-center gap-[2rem]">
      <GeneralTitle title="Cambiar Contraseña" />

      <div className='w-9/12'>
        <InputField
          type="email"
          label="Correo Electrónico"
          value={formData.email}
          onChange={(e) => handleChange(e, 'email')}
        />
        <InputField
          type="password"
          label="Nueva Contraseña"
          value={formData.password}
          onChange={(e) => handleChange(e, 'password')}
        />
        <InputField
          type="password"
          label="Confirmar Contraseña"
          value={formData.confirmPassword}
          onChange={(e) => handleChange(e, 'confirmPassword')}
        />
      </div>
    </div>
  );
};

export default ChangePassword;

import React, { useState } from 'react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

// components
import CardProduct from '../../components/buyer/components/Product/CardProduct';
import Discount from '../../components/pages/home/Discount';
import ProductGridLayout from '../../components/pages/home/ProductGridLayout';
import RoundedButton from '../../components/ui/RoundedButton';
import RemoveAccount from '../../components/ui/RemoveAccount';
import OrderCancellation from '../../components/ui/OrderCancellation';
import OrderInvoice from '../../components/ui/OrderInvoice';
import OrderResume from '../../components/ui/OrderResume';



const Home = () => {
  const auth = useSelector((state) => state.auth);

  const { user } = auth;

  const [open, setOpen] = useState(false);
  const [RemoveAccountIsOpen, setRemoveAccountIsOpen] = useState(false);
  const [OrderCancellationIsOpen, setOrderCancellationIsOpen] = useState(false);
  const [OrderInvoiceIsOpen, setOrderInvoiceIsOpen] = useState(false);
  const [OrderResumeIsOpen, setOrderResumeIsOpen] = useState(false);

  return (
    <>
      <div className='text-[#0A7379]'>
        <h5 className="inline-flex w-[362px] h-[50px] top-[97px] left-[2px] font-Montserrat font-bold">
          {t('Categoria de productos')}
        </h5>
      </div >

      {RemoveAccountIsOpen ? (
        <RemoveAccount
          modalOpen={RemoveAccountIsOpen}
          setModalOpen={setRemoveAccountIsOpen}
        />
      ) : null}

      {OrderCancellationIsOpen ? (
        <OrderCancellation
          modalOpen={OrderCancellationIsOpen}
          setModalOpen={setOrderCancellationIsOpen}
        />
      ) : null}

      {/*       <RoundedButton
        text="Remover cuenta"
        onClick={() => setRemoveAccountIsOpen(true)}
      /> */}

      <RoundedButton
        text="Cancelacion de orden"
        onClick={() => setOrderCancellationIsOpen(true)}
      />

      {OrderInvoiceIsOpen ? (
        <OrderInvoice
          modalOpen={OrderInvoiceIsOpen}
          setModalOpen={setOrderInvoiceIsOpen}
        />
      ) : null}

      <RoundedButton
        text="Factura de orden"
        onClick={() => setOrderInvoiceIsOpen(true)}
      />


      {OrderResumeIsOpen ? (
        <OrderResume
          modalOpen={OrderResumeIsOpen}
          setModalOpen={setOrderResumeIsOpen}
        />
      ) : null}

      <RoundedButton
        text="Resumen de orden"
        onClick={() => setOrderResumeIsOpen(true)}
      />






      {/*categoria de descuentos Carousel*/}
      <Swiper
        style={{
          '--swiper-navigation-color': '#005151',
        }
        }
        slidesPerView={3}
        spaceBetween={30}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
        navigation={true}
        modules={[Autoplay, Navigation]}
        className="max-w-[1600px] z-0"
      >
        <SwiperSlide className="flex">
          <Discount bgColor="a8efd9" />
        </SwiperSlide>

        <SwiperSlide className="flex">
          <Discount bgColor="E9ED4A" />
        </SwiperSlide>

        <SwiperSlide className="flex">
          <Discount bgColor="FFBB69" />
        </SwiperSlide>

        <SwiperSlide className="flex">
          <Discount bgColor="E9ED4A" />
        </SwiperSlide>
      </Swiper >
      {/* Grid productos */}
      < div className="flex flex-wrap justify-center" >
        <div className="w-full md:w-[10%] p-4 bg-red-300">
        </div>
        <div className="w-full md:w-[80%] p-4">
          <ProductGridLayout />
        </div>
        <div className="w-full md:w-[10%] p-4 bg-red-300">
        </div>
      </div >
      {/*  Mis productos favoritos */}
      < div className='mt-10 text-[#0A7379]' >
        <h5 className="inline-flex w-[362px] h-[50px] top-[97px] left-[2px] font-Montserrat font-bold">
          {t('Mis favoritos')}
        </h5>
        <div className="flex justify-center">
          <CardProduct />
          <CardProduct />
          <CardProduct />
        </div>
      </div >
    </>
  );
};

export default Home;

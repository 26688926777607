import React, { useState } from 'react';
import Footer from '../../layouts/Footer';
import GoTraveler from '../../components/traveler/GoTraveler';
import { Button, Carousel, Rating } from 'flowbite-react';
import { useSelector } from 'react-redux';

const Home = () => {
  const [visible, setvisible] = useState(false);
  const { user } = useSelector((state) => state.auth);

  return (
    <>
      <div className="bg-white flex h-[10vh] w-full" />
      <div className="bg-primary flex h-[10vh] w-full">
        <div className="m-auto">
          <p className="text-white text-[10px] md:text-[30px] font-[900] leading-[12px] md:leading-[35px] tracking-[1px]">
            Hola, Buen día Birdybacker! {user.name}
          </p>
        </div>
      </div>

      <div className="bg-black flex h-[20vh] w-full">
        <div className="w-[25vw] flex justify-center m-auto">
          <Button onClick={() => setvisible(true)}>GO!</Button>
        </div>
        <div className="w-[75vw]" />
      </div>

      {visible && <GoTraveler visible={visible} setvisible={setvisible} />}

      <div className="grid grid-cols-5 grid-flow-row-dense">
        <div className="shadow-xl min-h-[100px] row-span-4">
          <Carousel>
            <img
              className="bg-white shadow-xl min-h-[100px] row-span-3"
              src="https://cdn.shopify.com/s/files/1/0076/1439/8546/products/O81001LadiesFitT-shirt_DustyPurple_850x.jpg?v=1672744034"
              alt="a"
            />
            <img
              className="shadow-xl min-h-[100px] row-span-2 col-span-2"
              src="https://i0.wp.com/imgs.hipertextual.com/wp-content/uploads/2022/10/iPad-10-07-scaled.jpg?fit=2560%2C1600&quality=50&strip=all&ssl=1"
              alt="a"
            />
            <img
              className="shadow-xl min-h-[100px] col-span-2"
              src="https://static.nike.com/a/images/w_1920,c_limit/6ca98205-3a29-4635-b884-4f2f33e26ffe/los-mejores-calzados-de-golf-de-nike-para-tracci%C3%B3n-estabilidad-y-comodidad.jpg"
              alt="a"
            />
            <img
              className=" shadow-xl min-h-[100px]"
              src="https://cdn.vox-cdn.com/thumbor/uA7gh5OHRGuJcemXgEqo4I4INFA=/0x0:2040x1360/2400x1356/filters:focal(1020x680:1021x681)/cdn.vox-cdn.com/uploads/chorus_asset/file/24128006/226361_Apple_iPad_10.9_10th_gen_DSeifert_0004.jpg"
              alt="a"
            />
          </Carousel>
        </div>
        <div className="shadow-xl min-h-[100px] row-span-4">
          <Carousel>
            <img
              className="bg-white shadow-xl min-h-[100px] row-span-3"
              src="https://cdn.shopify.com/s/files/1/0076/1439/8546/products/O81001LadiesFitT-shirt_DustyPurple_850x.jpg?v=1672744034"
              alt="a"
            />
            <img
              className="shadow-xl min-h-[100px] row-span-2 col-span-2"
              src="https://i0.wp.com/imgs.hipertextual.com/wp-content/uploads/2022/10/iPad-10-07-scaled.jpg?fit=2560%2C1600&quality=50&strip=all&ssl=1"
              alt="a"
            />
            <img
              className="shadow-xl min-h-[100px] col-span-2"
              src="https://static.nike.com/a/images/w_1920,c_limit/6ca98205-3a29-4635-b884-4f2f33e26ffe/los-mejores-calzados-de-golf-de-nike-para-tracci%C3%B3n-estabilidad-y-comodidad.jpg"
              alt="a"
            />
            <img
              className=" shadow-xl min-h-[100px]"
              src="https://cdn.vox-cdn.com/thumbor/uA7gh5OHRGuJcemXgEqo4I4INFA=/0x0:2040x1360/2400x1356/filters:focal(1020x680:1021x681)/cdn.vox-cdn.com/uploads/chorus_asset/file/24128006/226361_Apple_iPad_10.9_10th_gen_DSeifert_0004.jpg"
              alt="a"
            />
          </Carousel>
        </div>
        <div className="shadow-xl min-h-[100px] row-span-4">
          <Carousel>
            <img
              className="bg-white shadow-xl min-h-[100px] row-span-3"
              src="https://cdn.shopify.com/s/files/1/0076/1439/8546/products/O81001LadiesFitT-shirt_DustyPurple_850x.jpg?v=1672744034"
              alt="a"
            />
            <img
              className="shadow-xl min-h-[100px] row-span-2 col-span-2"
              src="https://i0.wp.com/imgs.hipertextual.com/wp-content/uploads/2022/10/iPad-10-07-scaled.jpg?fit=2560%2C1600&quality=50&strip=all&ssl=1"
              alt="a"
            />
            <img
              className="shadow-xl min-h-[100px] col-span-2"
              src="https://static.nike.com/a/images/w_1920,c_limit/6ca98205-3a29-4635-b884-4f2f33e26ffe/los-mejores-calzados-de-golf-de-nike-para-tracci%C3%B3n-estabilidad-y-comodidad.jpg"
              alt="a"
            />
            <img
              className=" shadow-xl min-h-[100px]"
              src="https://cdn.vox-cdn.com/thumbor/uA7gh5OHRGuJcemXgEqo4I4INFA=/0x0:2040x1360/2400x1356/filters:focal(1020x680:1021x681)/cdn.vox-cdn.com/uploads/chorus_asset/file/24128006/226361_Apple_iPad_10.9_10th_gen_DSeifert_0004.jpg"
              alt="a"
            />
          </Carousel>
        </div>
        <div className="shadow-xl min-h-[100px] row-span-4">
          <Carousel>
            <img
              className="bg-white shadow-xl min-h-[100px] row-span-3"
              src="https://cdn.shopify.com/s/files/1/0076/1439/8546/products/O81001LadiesFitT-shirt_DustyPurple_850x.jpg?v=1672744034"
              alt="a"
            />
            <img
              className="shadow-xl min-h-[100px] row-span-2 col-span-2"
              src="https://i0.wp.com/imgs.hipertextual.com/wp-content/uploads/2022/10/iPad-10-07-scaled.jpg?fit=2560%2C1600&quality=50&strip=all&ssl=1"
              alt="a"
            />
            <img
              className="shadow-xl min-h-[100px] col-span-2"
              src="https://static.nike.com/a/images/w_1920,c_limit/6ca98205-3a29-4635-b884-4f2f33e26ffe/los-mejores-calzados-de-golf-de-nike-para-tracci%C3%B3n-estabilidad-y-comodidad.jpg"
              alt="a"
            />
            <img
              className=" shadow-xl min-h-[100px]"
              src="https://cdn.vox-cdn.com/thumbor/uA7gh5OHRGuJcemXgEqo4I4INFA=/0x0:2040x1360/2400x1356/filters:focal(1020x680:1021x681)/cdn.vox-cdn.com/uploads/chorus_asset/file/24128006/226361_Apple_iPad_10.9_10th_gen_DSeifert_0004.jpg"
              alt="a"
            />
          </Carousel>
        </div>
        <div className="shadow-xl min-h-[100px] row-span-4">
          <Carousel>
            <img
              className="bg-white shadow-xl min-h-[100px] row-span-3"
              src="https://cdn.shopify.com/s/files/1/0076/1439/8546/products/O81001LadiesFitT-shirt_DustyPurple_850x.jpg?v=1672744034"
              alt="a"
            />
            <img
              className="shadow-xl min-h-[100px] row-span-2 col-span-2"
              src="https://i0.wp.com/imgs.hipertextual.com/wp-content/uploads/2022/10/iPad-10-07-scaled.jpg?fit=2560%2C1600&quality=50&strip=all&ssl=1"
              alt="a"
            />
            <img
              className="shadow-xl min-h-[100px] col-span-2"
              src="https://static.nike.com/a/images/w_1920,c_limit/6ca98205-3a29-4635-b884-4f2f33e26ffe/los-mejores-calzados-de-golf-de-nike-para-tracci%C3%B3n-estabilidad-y-comodidad.jpg"
              alt="a"
            />
            <img
              className=" shadow-xl min-h-[100px]"
              src="https://cdn.vox-cdn.com/thumbor/uA7gh5OHRGuJcemXgEqo4I4INFA=/0x0:2040x1360/2400x1356/filters:focal(1020x680:1021x681)/cdn.vox-cdn.com/uploads/chorus_asset/file/24128006/226361_Apple_iPad_10.9_10th_gen_DSeifert_0004.jpg"
              alt="a"
            />
          </Carousel>
        </div>
        <div className="bg-[#ff0f00] flex flex-col items-center justify-center shadow-xl min-h-[100px]">
          <h2 className="text-white text-[10px] md:text-[30px] font-[900] leading-[12px] md:leading-[35px] tracking-[1px]">
            Hola, Buen día! {user.name}
          </h2>
        </div>
        <div className="bg-primary flex flex-col items-center justify-center shadow-xl min-h-[100px] row-span-2 col-span-2">
          <img
            className="flex w-[5rem] h-[5rem] mb-[1rem] rounded-full"
            src={user.picture}
            alt="a"
          />
          {user.name}
          <Rating>
            <Rating.Star />
            <p className="ml-2 text-sm font-bold text-gray-900 dark:text-white">
              4.95
            </p>
            <span className="mx-1.5 h-1 w-1 rounded-full bg-gray-500 dark:bg-gray-400" />
            <div className="text-sm font-medium text-gray-900 underline hover:no-underline dark:text-white">
              73 reviews
            </div>
          </Rating>
          Millas: 1200
        </div>
        <div className="bg-[#ffff00] shadow-xl min-h-[100px] " />

        <img
          className="flex w-full h-full shadow-xl min-h-[100px] row-span-3 col-span-2"
          src="https://www.apple.com/newsroom/images/product/iphone/standard/Apple_announce-iphone12pro_10132020.jpg.og.jpg?202303302033"
          alt="a"
        />

        <div className="bg-pink-500 shadow-xl min-h-[100px]" />
        <div className="bg-teal-500 shadow-xl min-h-[100px]" />
        <div className="bg-[#ff0ff0] shadow-xl min-h-[100px] row-span-4" />
        <div className="bg-red-500 shadow-xl min-h-[100px] row-span-3" />
        <div className="bg-black shadow-xl min-h-[100px]" />

        <img
          className="bg-white shadow-xl min-h-[100px] row-span-3"
          src="https://cdn.shopify.com/s/files/1/0076/1439/8546/products/O81001LadiesFitT-shirt_DustyPurple_850x.jpg?v=1672744034"
          alt="a"
        />
        <img
          className="bg-white shadow-xl min-h-[100px] row-span-3"
          src="https://cdn.shopify.com/s/files/1/0497/8277/products/W231107-BLK_1238_900x.png?v=1674016994"
          alt="a"
        />

        <img
          className="flex w-full h-full shadow-xl min-h-[100px]"
          src="https://media.gq-magazine.co.uk/photos/6321d9f25e12bea61b490b79/4:3/w_1440,h_1080,c_limit/HHH_.jpg"
          alt="a"
        />
        <div className="bg-purple-500 shadow-xl min-h-[100px]" />
        <div className="bg-orange-500 shadow-xl min-h-[100px]" />
        <div className="bg-[#000f00] shadow-xl min-h-[100px] row-span-2 col-span-2" />
        <div className="bg-green-500 shadow-xl min-h-[100px] row-span-5" />
        <div className="bg-blue-500 shadow-xl min-h-[100px]" />
        <img
          className="shadow-xl min-h-[100px] row-span-2 col-span-2"
          src="https://i0.wp.com/imgs.hipertextual.com/wp-content/uploads/2022/10/iPad-10-07-scaled.jpg?fit=2560%2C1600&quality=50&strip=all&ssl=1"
          alt="a"
        />
        <img
          className=" shadow-xl min-h-[100px]"
          src="https://content.instructables.com/F32/WM4Q/JMKVGGML/F32WM4QJMKVGGML.jpg?auto=webp&fit=bounds&frame=1&height=1024&width=1024auto=webp&frame=1&height=150"
          alt="a"
        />
        <div className="bg-blue-500 shadow-xl min-h-[100px]" />
        <div className="bg-orange-800 shadow-xl min-h-[100px] " />
        <div className="bg-indigo-500 shadow-xl min-h-[100px] row-span-3" />
        <img
          className=" shadow-xl min-h-[100px]"
          src="https://cdn.vox-cdn.com/thumbor/uA7gh5OHRGuJcemXgEqo4I4INFA=/0x0:2040x1360/2400x1356/filters:focal(1020x680:1021x681)/cdn.vox-cdn.com/uploads/chorus_asset/file/24128006/226361_Apple_iPad_10.9_10th_gen_DSeifert_0004.jpg"
          alt="a"
        />
        <div className="bg-primary flex flex-col items-center justify-center shadow-xl min-h-[100px] row-span-4">
          <img
            className="flex w-[5rem] h-[5rem] mb-[1rem] rounded-full"
            src={user.picture}
            alt="a"
          />
          {user.name}
          <Rating>
            <Rating.Star />
            <p className="ml-2 text-sm font-bold text-gray-900 dark:text-white">
              4.95
            </p>
            <span className="mx-1.5 h-1 w-1 rounded-full bg-gray-500 dark:bg-gray-400" />
            <div className="text-sm font-medium text-gray-900 underline hover:no-underline dark:text-white">
              73 reviews
            </div>
          </Rating>
        </div>

        <div className="bg-black shadow-xl min-h-[100px] row-span-4" />
        <div className="bg-indigo-500 shadow-xl min-h-[100px] row-span-3" />
        <div className="bg-purple-500 shadow-xl min-h-[100px]" />
        <img
          className="shadow-xl min-h-[100px] col-span-2"
          src="https://static.nike.com/a/images/w_1920,c_limit/6ca98205-3a29-4635-b884-4f2f33e26ffe/los-mejores-calzados-de-golf-de-nike-para-tracci%C3%B3n-estabilidad-y-comodidad.jpg"
          alt="a"
        />
        <img
          className=" shadow-xl min-h-[100px]"
          src="https://cdn.vox-cdn.com/thumbor/uA7gh5OHRGuJcemXgEqo4I4INFA=/0x0:2040x1360/2400x1356/filters:focal(1020x680:1021x681)/cdn.vox-cdn.com/uploads/chorus_asset/file/24128006/226361_Apple_iPad_10.9_10th_gen_DSeifert_0004.jpg"
          alt="a"
        />
        <img
          className="bg-white shadow-xl min-h-[100px] row-span-3"
          src="https://cdn.shopify.com/s/files/1/0076/1439/8546/products/O81001LadiesFitT-shirt_DustyPurple_850x.jpg?v=1672744034"
          alt="a"
        />

        <img
          className="flex w-full h-full shadow-xl min-h-[100px] row-span-3 col-span-2"
          src="https://cloudfront-us-east-1.images.arcpublishing.com/infobae/2GQ4KWBIGPU3ULRVZEOH67RNOY.jpg"
          alt="a"
        />
        <div className="bg-purple-500 shadow-xl min-h-[100px]" />
        <div className="bg-pink-500 shadow-xl min-h-[100px] row-span-3" />
        <img
          className="flex w-full h-full shadow-xl min-h-[100px] row-span-2 col-span-2"
          src="https://consumer.huawei.com/content/dam/huawei-cbg-site/latam/co/mkt/plp/laptops/matebook-x-pro.jpg"
          alt="a"
        />

        <div className="bg-blue-500 shadow-xl min-h-[100px]" />
        <div className="bg-orange-800 shadow-xl min-h-[100px] " />
        <div className="bg-indigo-500 shadow-xl min-h-[100px] row-span-3" />
      </div>

      <Footer />
    </>
  );
};

export default Home;

import React, { useEffect, useState } from 'react';
import { Button, Card, Modal } from 'antd';
import {
  PlusOutlined,
  ShoppingCartOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { addOrder } from '../../features/user/ordersSlide';
import { deleteProduct, reset } from '../../features/user/productsSlide';
import { useNavigate } from 'react-router-dom';

const ProductDetails = ({ showModal, setshowModal, product }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const products = useSelector((state) => state.products);
  const orders = useSelector((state) => state.orders);

  //   useEffect(() => {
  //     if (!product) {
  //       return;
  //     }
  //   }, []);

  return (
    <Modal open={showModal} onCancel={() => setshowModal(false)}>
      {product ? (
        <>
          <div className="flex justify-center">
            <img
              className="w-[200px]"
              src={product.product_link}
              alt={product.product_link}
            />
          </div>
          <h4>{product.name}</h4>
          <div>
            <h5>
              price: {product.price} {product.price_unity}
            </h5>
          </div>
        </>
      ) : null}
    </Modal>
  );
};

export default ProductDetails;

import React, { useState } from "react";

import StatusCardOrden from "../Orden/StatusCardOrden";
import { TbPlane } from "react-icons/tb";
import { BsBoxSeam } from "react-icons/bs";
import { RiTruckLine } from "react-icons/ri";
import { GoPerson } from "react-icons/go";
import { FaSuitcaseRolling } from "react-icons/fa";
import GeneralCard from "../../../ui/GeneralCard";

const CardContentRight = ({ showDetail, use }) => {
  return (
    <>
      <div class="col-start-4 row-start-  flex justify-end">
        <FaSuitcaseRolling className="inline text-[#0A7379]" size={17} />
      </div>


      <div class="flex justify-end col-start-4 row-start-3">
        <p className="cardDescription font-[700] text-[#0A7379]">
          $ 0.00
        </p>
      </div>
      <div class="self-end col-start-4 text-right row-start-4">
        <p className="inline cardDescription font-[500] text-[#a1a1a1]">
          Junio 30, 2023
        </p>
      </div>
    </>
  );
};

const CardContentLeft = ({ use }) => {

  const statusTest = [
    {
      status: 'Cancelada',
      color: '#F39595'
    },
    {
      status: 'Finalizada',
      color: '#868484'
    }
  ];

  return (
    <>
      <div class="row-span-4">
        <div class="h-full w-[110%] col-start-1 row-start-1 bg-black">
          Foto
        </div>
      </div>

      <div className="ml-1">
        <StatusCardOrden status={statusTest[1].status} color={statusTest[1].color} />
      </div>


      <div class="col-start-2 row-start-3 ml-1">
        <p className="phoneTitle text-[#A1A1A1] font-[700]">Orden 1</p>
      </div>

      <div class="col-start-2 row-start-4 self-end ml-1">
        <GoPerson className="inline font-[500] text-[#a1a1a1]" />
        <p className="inline cardDescription font-[500] text-[#a1a1a1]">Sin emparejar</p>
      </div>
    </>
  );
};

const CardDetail = ({ MainIcon, CategoryIcon, description }) => {
  return (
    <div className="flex justify-between" >
      <div className="flex justify-start ml-[40px] w-65">
        <MainIcon className="mr-2" size={25} color="#a1a1a1" />
        <CategoryIcon className="ml-5 mr-3" size={25} color="#a1a1a1" />
        <p className="font-[500] text-[#a1a1a1]">{description}</p>
      </div>
      <div className="flex justify-end mr-12">
        <input type="radio" class="text-[#0A7379] bg-gray-100 border-[#0A7379] focus:ring-[#0A7379]" />
      </div>
    </div >
  );
};

const CardOrden = () => {
  const [showDetail, setShowDetail] = useState(false);

  const toggleDetail = () => {
    setShowDetail((prev) => !prev);
  };

  return (
    <GeneralCard
      showDetail={showDetail}
      toggleDetail={toggleDetail}
      detailComponent={<CustomDetailComponent />}
      dropdownPosition="center"
    >
      <CardContentLeft />
      <CardContentRight />
    </GeneralCard>
  );
};

const CustomDetailComponent = () => {
  // Tu componente detalles de la tarjeta
  return (
    <div className="w-full">
      <CardDetail MainIcon={BsBoxSeam} CategoryIcon={RiTruckLine} description="Caja 1" />
      <CardDetail MainIcon={TbPlane} CategoryIcon={GoPerson} description="Caja 2" />
    </div>
  );
};

export default CardOrden;
import React from 'react';
import { pages, useGoTraveler } from '../../hooks/useGoTraveler';
import Swal from 'sweetalert2';
import { Modal } from 'antd';

const GoTraveler = ({ visible, setvisible }) => {
  const { page, conditionalComponent } = useGoTraveler(setvisible);

  return (
    <Modal
      centered
      destroyOnClose
      open={visible}
      footer={false}
      width={page === pages.summary ? 900 : 700}
      onCancel={() =>
        Swal.fire({
          title: 'Do you want to save the changes?',
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: 'Save',
          denyButtonText: `Don't save`,
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire('Saved!', '', 'success');
            setvisible(false);
          } else if (result.isDenied) {
            Swal.fire('Changes are not saved', '', 'info');
            setvisible(false);
          }
        })
      }
    >
      {conditionalComponent()}
    </Modal>
  );
};

export default GoTraveler;

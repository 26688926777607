import React, { useState } from "react";
import RoundedButton from '../../ui/RoundedButton';
import Swal from "sweetalert2";

import ChangePassword from '../../ui/ChangePassword';

const Password = ({ setPage }) => {
  const [ok, setok] = useState(false);
  const [newPassword, setnewPassword] = useState("");
  const [accept, setaccept] = useState(true);

  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text");
    } else {
      setPasswordType1("password");
    }
  };

  const changePassword = () => {
    Swal.fire("Saved!", "", "success");
    setPage("profile");
  };

  const verifyPhone = () => {
    return (
      <ChangePassword />
    );
  };

  return (
    <div className="">
      {verifyPhone()}
      <div className="flex justify-center mt-8">
        <RoundedButton onClick={() => setok(true)}
          text={"Registrarse"}
        />
      </div>
    </div>
  );
};

export default Password;

import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { useGoBuyer } from '../../hooks/useGoBuyer';

const GoBuyer = ({ open, setOpen, Order }) => {
  const { page, setPage, conditionalComponent } = useGoBuyer(Order);

  const [onModal, setonModal] = useState(false);

  return (
    <Modal
      title={
        page === 'AddOrder'
          ? 'Añadir encargo'
          : page === 'ShoppingCart'
            ? 'Lista de productos'
            : page === 'FormGo'
              ? 'Añadir producto'
              : page === 'SelectAddress'
                ? 'Añadir dirección de envio'
                : 'Descripción de envio'
      }
      centered
      open={open}
      onCancel={() => (page === 'AddOrder' ? setOpen(false) : setonModal(true))}
      destroyOnClose
      footer={false}
      width={page === 'Result' ? 1000 : 800}
      zIndex={1}
    >
      {conditionalComponent()}
      {onModal && (
        <Modal
          title={<h5>Alerta</h5>}
          centered
          open={onModal}
          onCancel={() => {
            setonModal(false);
            setOpen(false);
            setPage(0);
          }}
          onOk={() => {
            setonModal(false);
            setOpen(false);
            setPage(0);
          }}
        >
          <p>Deseas guardar los cambios?</p>
        </Modal>
      )}
    </Modal>
  );
};

export default GoBuyer;

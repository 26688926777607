import { t } from 'i18next';

const GeneralTitle = ({ title }) => {
  return (
    <div className='text-[#0A7379] mb-4'>
      <h5 className="inline-flex h-[50px] top-[97px] left-[2px] font-Montserrat font-[600]">
        {t(title)}
      </h5>
    </div>
  );
}

export default GeneralTitle;
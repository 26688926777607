import { Button, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Result = ({ setPage }) => {
  const [address, setaddress] = useState({});
  const [products, setproducts] = useState({});
  const [order, setorder] = useState({});

  useEffect(() => {
    document.querySelector('.header').classList.add('top-[-80px]');
    setaddress(JSON.parse(localStorage.getItem('address')));
    setproducts(JSON.parse(localStorage.getItem('products')));
    setorder(JSON.parse(localStorage.getItem('order')));
    return () => {
      if (document.querySelector('.header')) {
        document.querySelector('.header').classList.remove('top-[-80px]');
      }
    };
  }, []);

  const navigate = useNavigate();

  const columnsAddress = [
    {
      title: 'department',
      dataIndex: 'department',
      key: 'department',
      render: (text) => <p className="text-[12px]">{text}</p>,
    },
    {
      title: 'location',
      dataIndex: 'location',
      key: 'location',
      render: (text) => <p className="text-[12px]">{text}</p>,
    },
    {
      title: 'neighborhood',
      dataIndex: 'neighborhood',
      key: 'neighborhood',
      render: (text) => <p className="text-[12px]">{text}</p>,
    },
    {
      title: 'kindStreet',
      dataIndex: 'kindStreet',
      key: 'kindStreet',
      render: (text) => <p className="text-[12px]">{text}</p>,
    },
    {
      title: 'street',
      dataIndex: 'street',
      key: 'street',
      render: (text) => <p className="text-[12px]">{text}</p>,
    },
    {
      title: 'numberStreet',
      dataIndex: 'numberStreet',
      key: 'numberStreet',
      render: (text) => <p className="text-[12px]">{text}</p>,
    },
    {
      title: 'numberStreet2',
      dataIndex: 'numberStreet2',
      key: 'numberStreet2',
      render: (text) => <p className="text-[12px]">{text}</p>,
    },
    {
      title: 'place',
      dataIndex: 'place',
      key: 'place',
      render: (text) => <p className="text-[12px]">{text}</p>,
    },
    {
      title: 'description',
      dataIndex: 'description',
      key: 'description',
      render: (text) => <p className="text-[12px]">{text}</p>,
    },
  ];

  const columnsProducts = [
    {
      title: 'name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <p className="text-[12px]">{text}</p>,
    },
    {
      title: 'quantity',
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => <p className="text-[12px]">{text}</p>,
    },
    {
      title: 'price',
      dataIndex: 'price',
      key: 'price',
      render: (text) => <p className="text-[12px]">{text}</p>,
    },
    {
      title: 'price_unity',
      dataIndex: 'price_unity',
      key: 'price_unity',
      render: (text) => <p className="text-[12px]">{text}</p>,
    },
    {
      title: 'category',
      dataIndex: 'category',
      key: 'category',
      render: (text) => <p className="text-[12px]">{text}</p>,
    },
    {
      title: 'volume',
      dataIndex: 'volume',
      key: 'volume',
      render: (text) => <p className="text-[12px]">{text}</p>,
    },
    {
      title: 'space',
      dataIndex: 'space',
      key: 'space',
      render: (text) => <p className="text-[12px]">{text}</p>,
    },
  ];

  const columns = [
    {
      title: 'section',
      dataIndex: 'section',
      key: 'section',
      render: (text) => <p className="text-[12px]">{text}</p>,
    },
    // {
    //   section: 'section',
    //   type: '2',
    //   cm: 'Cm',
    //   kg: 'Lb',
    // },
  ];

  return (
    <div className="flex flex-col gap-[1rem]">
      {address.department && (
        <Table
          pagination={false}
          columns={columnsAddress}
          dataSource={[address]}
        />
      )}

      <Table
        pagination={false}
        columns={columnsProducts}
        dataSource={products.length > 1 ? products : [products]}
      />

      <Table pagination={false} columns={columns} dataSource={[order]} />

      <div className="flex justify-between">
        {address.department && (
          <Button onClick={() => setPage('SelectAddress')}>
            Volver a direcciones
          </Button>
        )}

        <Button onClick={() => setPage('ShoppingCart')}>
          Volver al carrito
        </Button>

        <Button onClick={() => setPage('AddOrder')}>
          Volver a elegir sección
        </Button>

        <Button
          onClick={() => {
            if (address.department) {
              navigate(`/checkout/${JSON.parse(localStorage.getItem('id'))}`);
            } else {
              navigate(
                `/checkout/address/${JSON.parse(localStorage.getItem('id'))}`,
              );
            }
          }}
        >
          {address.department
            ? 'Eligir metodo de pago'
            : 'Seleccionar dirección'}
        </Button>
      </div>
    </div>
  );
};

export default Result;

import axios from './axios';

export const luggageApi = {
  path: '/luggage',
  async list_luggages() {
    return await axios.get(`${this.path}`, {
      headers: { Authorization: JSON.parse(localStorage.getItem('birdyuser')) },
    });
  },
};

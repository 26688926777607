import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Slider,
  Upload,
} from 'antd';
import { useForm } from 'react-hook-form';
import { rulesFirstBuyer as rules } from '../../constants/rules';
import { useGoBuyer } from '../../hooks/useGoBuyer';
import { today } from '../../util/functions/getToday';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { addProduct, updateProduct } from '../../features/user/productsSlide';
import { Table } from 'flowbite-react';
import { updateOrder } from '../../features/user/ordersSlide';

const AddOrder = ({ setPage, product, Order }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    document.querySelector('.header').classList.add('top-[-80px]');
    if (Order) {
      form.setFieldValue({ ...Order.section });
    } else if (product) {
      form.setFieldsValue({ ...product });
    }
    return () => {
      if (document.querySelector('.header')) {
        document.querySelector('.header').classList.remove('top-[-80px]');
      }
      if (Order) {
        form.resetFields();
      } else if (product) {
        form.resetFields();
      }
    };
  }, []);

  const dispatch = useDispatch();

  const config = {
    rules: [
      {
        // type: 'object',
        required: true,
        message: 'Es requerido!',
      },
    ],
  };

  const onFinish = (values) => {
    // if (product) {
    //   dispatch(updateProduct({ ...product, ...values }));
    // } else {
    //   dispatch(addProduct(values));
    // }
    if (Order) {
      console.log(values);
      dispatch(updateOrder({ id: Order.id, section: values }));
    }
    localStorage.setItem('order', JSON.stringify(values));
    setPage('ShoppingCart');
  };

  const [section, setsection] = useState(null);
  const [cm, setcm] = useState(true);
  const [kg, setkg] = useState(true);
  const [value, setvalue] = useState(1);

  const conditionalComponent = () => {
    switch (section) {
      case 'flexible':
        return (
          <div>
            <h2 className='w-[282px] h-6 shrink-0 text-[#0A7379] text-[15px] not-italic font-bold leading-[normal]'>Información del viajero</h2>
            <div className="flex justify-center items-center">

              <div className="w-[376px] h-32 border shadow-[5px_7px_20px_0px_rgba(0,0,0,0.05)] rounded-[20px] border-solid border-[#EDEDED] flex items-center p-4 space-x-4">

                {/* Foto */}
                <div className="w-16 h-16 rounded-full bg-blue-200"></div>

                <div className="flex flex-col space-y-1">
                  {/* Nombre */}
                  <div className="w-20 h-4 bg-green-200"></div>

                  {/* Icono */}
                  <div className="w-8 h-8 bg-yellow-200"></div>

                  {/* Dirección */}
                  <div className="w-32 h-4 bg-pink-200"></div>
                </div>

              </div>
            </div>
          </div>
        );
      case 'section':
        return (
          <div className="flex justify-between items-center h-[300px]">
            <div className="w-1/2">
              <Form.Item name="type" label="type" rules={[{ required: true }]}>
                <Select onClick={(e) => setvalue(parseInt(e.target.innerText))}>
                  <Select.Option value="1">1</Select.Option>
                  <Select.Option value="2">2</Select.Option>
                  <Select.Option value="3">3</Select.Option>
                  <Select.Option value="4">4</Select.Option>
                  <Select.Option value="5">5</Select.Option>
                  <Select.Option value="6">6</Select.Option>
                  <Select.Option value="7">7</Select.Option>
                  <Select.Option value="8">8</Select.Option>
                  <Select.Option value="9">9</Select.Option>
                  <Select.Option value="10">10</Select.Option>
                  <Select.Option value="11">11</Select.Option>
                  <Select.Option value="12">12</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="flex justify-center gap-2">
              <Table striped={true}>
                <Table.Body className="divide-y">
                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      Dimensiones
                    </Table.Cell>
                    <Table.Cell>{cm ? value * 10 : value * 14}</Table.Cell>
                    <Table.Cell>
                      <div>
                        <Form.Item
                          name="cm"
                          label="unidad de medición"
                          rules={[{ required: true }]}
                        >
                          <Select
                            onClick={(e) => {
                              if (e.target.innerText === 'Cm') {
                                setcm(true);
                              } else {
                                setcm(false);
                              }
                            }}
                          >
                            <Select.Option value="Cm">Cm</Select.Option>
                            <Select.Option value="In">In</Select.Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      Peso
                    </Table.Cell>
                    <Table.Cell>{kg ? value * 100 : value * 200}</Table.Cell>
                    <Table.Cell>
                      <div>
                        <Form.Item
                          name="kg"
                          label="unidad de medición"
                          rules={[{ required: true }]}
                        >
                          <Select
                            onClick={(e) => {
                              if (e.target.innerText === 'Kg') {
                                setkg(true);
                              } else {
                                setkg(false);
                              }
                            }}
                          >
                            <Select.Option value="Kg">Kg</Select.Option>
                            <Select.Option value="Lb">Lb</Select.Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
          </div>
        );
      case 'floating':
        return (
          <div className="flex w-full items-center h-[300px]">
            <div className="w-1/2">
              <Form.Item
                name="tipo"
                label="tipo de sección"
                rules={[{ required: true }]}
              >
                <Select>
                  <Select.Option value="interna">interna</Select.Option>
                  <Select.Option value="externa">externa</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="flex justify-center gap-2">
              <Table striped={true}>
                <Table.Body className="divide-y">
                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      Dimensiones
                    </Table.Cell>
                    <Table.Cell>
                      <Form.Item name="a" rules={[{ required: true }]}>
                        <InputNumber min={1} />
                      </Form.Item>
                    </Table.Cell>
                    <Table.Cell>
                      <div>
                        <Form.Item
                          name="cmFloating"
                          label="unidad de medición"
                          rules={[{ required: true }]}
                        >
                          <Select
                            onClick={(e) => {
                              if (e.target.title === 'Cm') {
                                setcm(true);
                              } else {
                                setcm(false);
                              }
                            }}
                          >
                            <Select.Option value="Cm">Cm</Select.Option>
                            <Select.Option value="In">In</Select.Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      Peso
                    </Table.Cell>
                    <Table.Cell>
                      <Form.Item name="b" rules={[{ required: true }]}>
                        <InputNumber min={1} />
                      </Form.Item>
                    </Table.Cell>
                    <Table.Cell>
                      <div>
                        <Form.Item
                          name="kgFloating"
                          label="unidad de medición"
                          rules={[{ required: true }]}
                        >
                          <Select
                            onClick={(e) => {
                              if (e.target.title === 'Kg') {
                                setkg(true);
                              } else {
                                setkg(false);
                              }
                            }}
                          >
                            <Select.Option value="Kg">Kg</Select.Option>
                            <Select.Option value="Lb">Lb</Select.Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
          </div>
        );

      default:
        return <div className="h-[300px]"></div>;
    }
  };

  return (
    <Form
      layout="vertical"
      style={{
        margin: 10,
      }}
      onFinish={onFinish}
      requiredMark={false}
      form={form}
    >
      <Form.Item
        name="section"
        label="Selecciona el espacio de maleta"
        rules={[{ required: true, message: 'Selecciona uno' }]}
      >
        <div className="flex justify-around">
          <Radio.Group>
            <Radio onClick={() => setsection('flexible')} value="flexible">
              flexible
            </Radio>
            <Radio onClick={() => setsection('section')} value="section">
              section
            </Radio>
            <Radio onClick={() => setsection('floating')} value="floating">
              floating
            </Radio>
          </Radio.Group>
        </div>
      </Form.Item>

      {conditionalComponent()}

      <div className="flex justify-end">
        <Button htmlType="submit">Siguiente</Button>
      </div>
    </Form>
  );
};

export default AddOrder;

import React, { useEffect, useState } from 'react';
import { Button, Card } from 'antd';
import {
  PlusOutlined,
  ShoppingCartOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { addOrder } from '../../features/user/ordersSlide';
import { deleteProduct, reset } from '../../features/user/productsSlide';
import { useNavigate } from 'react-router-dom';

const ShoppingCart = ({ setPage }) => {
  useEffect(() => {
    document.querySelector('.header').classList.add('top-[-80px]');
    return () => {
      if (document.querySelector('.header')) {
        document.querySelector('.header').classList.remove('top-[-80px]');
      }
      setmethod(null);
    };
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const products = useSelector((state) => state.products);
  const orders = useSelector((state) => state.orders);

  const [method, setmethod] = useState(null);

  const conditionalComponent = () => {
    switch (method) {
      case null:
        return (
          <div>
            <p>Productos a pagar</p>
            <div className="bg-[#502000] grid grid-cols-3 gap-[1rem] p-[1rem] rounded-[10px]">
              {products.map((product) =>
                product ? (
                  <Card key={product.id}>{product.category}</Card>
                ) : null,
              )}
            </div>
          </div>
        );
      case 'Pasarela':
        return (
          <div>
            <p>Ingresar datos</p>
          </div>
        );
      case 'Efectivo':
        return (
          <div>
            <p>Seleccina un metodo</p>
            <div className="bg-[#105050] grid grid-cols-2 gap-[1rem] p-[1rem] rounded-[10px]">
              <Card>Baloto</Card>
              <Card>Efecty</Card>
            </div>
          </div>
        );
      case 'Tranferencia':
        return (
          <div>
            <p>Seleccina un metodo</p>
            <div className="bg-[#505020] grid grid-cols-2 gap-[1rem] p-[1rem] rounded-[10px]">
              <Card>QR</Card>
              <Card>Billetera Movil</Card>
            </div>
          </div>
        );
      default:
        return (
          <div>
            <p>Productos a pagar</p>
            <div className="bg-[#502000] grid grid-cols-3 gap-[1rem] p-[1rem] rounded-[10px]">
              {products.map((product) =>
                product ? (
                  <Card key={product.id}>{product.category}</Card>
                ) : null,
              )}
            </div>
          </div>
        );
    }
  };

  return (
    <div className="flex flex-col gap-[1rem]">
      <div className="bg-secondary grid grid-cols-4 gap-[1rem] p-[1rem] rounded-[10px]">
        {/* {products.map((product) =>
          product ? <Card key={product.id}>{product.category}</Card> : null,
        )} */}
        <Card onClick={() => setmethod(null)}>Productos</Card>
        <Card onClick={() => setmethod('Pasarela')}>Pasarela de pago</Card>
        <Card onClick={() => setmethod('Efectivo')}>Efectivo</Card>
        <Card onClick={() => setmethod('Tranferencia')}>
          Tranferencia bancaria
        </Card>
      </div>
      <div>{conditionalComponent()}</div>
      {/* <div className="flex justify-around">
        <Button
          onClick={() => setPage(1)}
          className="flex items-center"
          size="large"
          icon={<PlusOutlined />}
          type="dashed"
        >
          Añadir producto
        </Button>
        <Button
          onClick={
            () => setPage(2)
            dispatch(addOrder(products));
            navigate('/orders');
            setTimeout(() => {
              dispatch(reset());
            }, 2000);
          }
          className="flex items-center"
          size="large"
          icon={<ShoppingCartOutlined />}
          type="dashed"
        >
          Procesar compra
        </Button>
      </div> */}
      <div className="flex justify-between">
        <Button onClick={() => setPage('Result')}>Anterior</Button>
        <Button onClick={() => setPage(4)}>Siguiente</Button>
      </div>
    </div>
  );
};

export default ShoppingCart;

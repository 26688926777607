import React, { useState } from "react";
import { t } from 'i18next';
import { GiWorld } from "react-icons/gi";
import CollapseSetting from "./CollapseSetting";
import { TbHelpSquare } from "react-icons/tb";
import { FiInfo } from "react-icons/fi";
import { TfiLock } from "react-icons/tfi";
import { FaMapMarkedAlt } from "react-icons/fa";

// My collapse options
import Addresses from "../../../components/buyer/settings/Addresses";
import Password from "../../../components/buyer/settings/Password";
import General from "../../../components/buyer/settings/General";
import Help from "../../../components/buyer/settings/Help";
import Language from "../../../components/buyer/settings/Language";
import GeneralTitle from "../../../components/ui/Title";
import RoundedButton from "../../../components/ui/RoundedButton";

const Settings = () => {
  const [activeSection, setActiveSection] = useState(null);

  const handleSectionToggle = (sectionId) => {
    setActiveSection(activeSection === sectionId ? null : sectionId);
  };

  const logout = () => {
    localStorage.removeItem('auth');
    dispatch(logoutUser());
    navigate('/');
  };


  const data = [
    {
      icon: <FaMapMarkedAlt className="text-3xl text-[#A1A1A1]" size={50} />,
      title: t('Direcciones'),
      description: t('Gestiona tus direcciones de envío, añade o modifica ubicaciones para recibir tus pedidos.'),
      id: 'addresses'
    },
    {
      icon: <TfiLock className="text-[#A1A1A1]" size={50} />,
      title: t('Contraseña'),
      description: t('Controla la seguridad de tu cuenta actualizando o restableciendo tu contraseña.'),
      id: 'password'
    },
    {
      icon: <FiInfo className="text-3xl text-[#A1A1A1]" size={50} />,
      title: t('Información General'),
      description: t('Accede a Términos y Condiciones, y Politicas de Privacidad. Actualiza tu información de contacto.'),
      id: 'general'
    },
    {
      icon: <TbHelpSquare className="text-3xl text-[#A1A1A1]" size={50} />,
      title: t('Ayuda'),
      description: t('Encuentra respuestas a tus dudas y preguntas frecuentes.'),
      id: 'help'
    }
    /*     {
          icon: <GiWorld className="text-3xl text-[#A1A1A1]" size={40} />,
          title: t('Idioma'),
          description: t('Selecciona el idioma de tu preferencia para la interfaz de Birdyback.'),
          id: 'language'
        } */
  ];

  const changeSetting = (option) => {
    console.log('Settings', option);
    switch (option) {
      case 'addresses':
        return <Addresses />
      case 'password':
        return <Password />
      case 'general':
        return <General />
      case 'help':
        return <Help />
      case 'language':
        return <Language />

      default:
        return null;
    }
  };

  return (
    <div className="max-w-4xl mx-auto bg-white rounded">
      <GeneralTitle title='Configuraciones' />

      {data.map((item) => (
        <div key={item.id}>
          <CollapseSetting
            id={item.id}
            icon={item.icon}
            title={item.title}
            description={item.description}
            isActive={activeSection === item.id}
            onToggle={() => handleSectionToggle(item.id)}
          />

          {activeSection === item.id && (
            <div className="mt-4">
              {changeSetting(item.id)}
            </div>
          )}
        </div>
      ))}

      <div className='flex flex-col justify-center items-center  w-full'>
        <div className="mt-16 w-[60%]">
          <RoundedButton
            text='Cerrar sesión'
            onClick={logout}
          />
        </div>
      </div>
    </div>
  );
};

export default Settings;

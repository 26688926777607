import { useState } from 'react';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';

const InputField = ({ id, type = 'text', label = '', name, value, onChange, width = '[400px]', height = '[50px]', placeholder, field }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleChange = (e) => {
    onChange(e);
  };

  return (
    <div className="relative mx-auto w-full mb-7">
      {type === 'password' ? (
        <>
          <input
            id={id}
            name={name}
            type={showPassword ? 'text' : 'password'}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            className={`w-full block h-${height} rounded-[36px] border-[2.5px] border-[#A1A1A1] pl-4`}
            {...field}
          />
          <label
            className="absolute top-[-18px] left-[38px] mt-2 bg-white px-2 text-gray-600 text-16px"
          >
            {label}
          </label>
        </>
      ) : (
        <>
          <input
            id={id}
            name={name}
            type={type}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            className={`w-full block h-${height} rounded-[36px] border-[2.5px] border-[#A1A1A1] pl-4`}
            {...field}
          />
          <label
            className="absolute top-[-18px] left-[38px] mt-2 bg-white px-2 text-gray-600 text-16px"
          >
            {label}
          </label>
        </>
      )}
      {type === 'password' && (
        <span className="absolute top-[15px] right-4 cursor-pointer" onClick={togglePasswordVisibility}>
          {showPassword ? <AiOutlineEye size={20} /> : <AiOutlineEyeInvisible size={20} />}
        </span>
      )}
    </div>
  );
};

export default InputField;
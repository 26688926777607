import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Grid, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import logo from '../../assets/svg/imagen-login.svg';
import Footer from '../../layouts/Footer';
import NavBuyer from '../../layouts/NavBuyer';
import { login as rules } from '../../constants/rules';
import HandleScroll from '../../util/functions/handleScroll';
import { useLogin } from '../../hooks/useLogin';

import InputField from '../../components/ui/InputField';
import GeneralTitle from '../../components/ui/Title';
import Container from '../../components/pages/common/Container';
import shopper from '../../assets/BirdybackDesign/Artes finales Birdyback/Website-Illustrations/png/birdybuyer-buscando-productos-compra-usa.png';
import ComponentMountainLogin from './ComponentMountainLogin';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import LoginBackgound from '../../components/pages/common/LoginBackgound';
import user from '../../assets/BirdybackDesign/Artes finales Birdyback/Website-Illustrations/svg/birdybuyer-buscando-productos-online-usa.svg';
import P from '../../components/typography/P';

const Login = () => {
  useEffect(() => {
    HandleScroll();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { loading, userType, setUserType, onSubmit } = useLogin();

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const Imagen = () => {
    return (
      <div className='hidden md:block lg:block'>
        <Link
          to="/"
          onClick={HandleScroll}
          className="flex justify-center"
        >
          <img className="absolute bottom-[-100px] z-[2] w-[12rem] md:w-[60rem]" src={logo} alt="Logo con fuente oficial de Birdyback en disposicion horizontal" />
        </Link>
      </div>

    );
  }

  return (
    <>
      <Helmet>
        <title>Login</title>
        <meta name='description' content='Iniciar sesión' />
      </Helmet>

      <NavBuyer />

      <ComponentMountainLogin
        title={
          <>
            <h2 className="font-[700] max-w-[30rem] text-primary leading-[2.5rem] md:leading-[3.5rem]">
              {t('¿Ya sabes qué vas a comprar?')}
            </h2>

            <div className="h-[1rem]" />

            <h5 className="mt-[1rem] md:mt-0 text-primary">
              {t('¡Un Birdybacker te lo trae!')}
            </h5>
          </>
        }
        text={
          <>
            <P className="w-[18rem] lg:w-[24rem]">
              {t('Sabemos que en Estados Unidos puedes encontrar')}{' '}
              <span className="font-[700]">
                {t(
                  'el producto que necesitas, muchísimo más económico y de excelente calidad',
                )}
              </span>
              {'.'}
            </P>

            <br />

            <P className="hidden md:block mb-3 md:mb-0 w-[18rem] lg:w-[24rem]">
              {t('Por eso, te ayudamos a traerlo hasta tus manos.')}
            </P>
          </>
        }
        img={user}
        alt="Birdybuyer (comprador) buscando productos en linea en Estados Unidos para comprarlos a traves de Birdyback"
      />
      <Footer text="¡Queremos conectar contigo!" />
    </>
  );
};

export default Login;

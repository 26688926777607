import React from 'react';
import { Outlet } from 'react-router-dom';
import NavBuyer from './NavBuyer';
import Footer from './Footer';

// Components
import AsideMenu from '../components/buyer/components/Aside/AsideMenu';
import AsideProfile from '../components/buyer/components/Aside/AsideProfile';
import AddressSelect from '../components/buyer/components/AddressSelect';
import Notification from '../components/buyer/components/Aside/Notification';

// Backgrounds
import ContainerCardsBackground from '../components/pages/security/ContainerCardsBackground';
import ContainerSecurityBackground from '../components/pages/security/ContainerSecurityBackground';

const LayoutBuyer = () => {
  return (
    <ContainerSecurityBackground>
      <ContainerCardsBackground>
        <NavBuyer />
        <div className="mx-4 p-0 md:p-10">
          <div className="flex flex-wrap justify-center">
            {/* aside izquierdo */}
            <aside className="text-left w-full order-1 md:w-1/5 lg:block">
              <div className='box'>
                <AsideProfile />
              </div>
              <div className='box mt-16'>
                <AsideMenu />
              </div>
            </aside>

            {/* main */}
            <main className="order-2 md:w-[45%] p-0 mx-6">
              <div className='flex justify-center'>
                <AddressSelect />
              </div>
              <div className='mt-16'>
                <Outlet />
              </div>
            </main>

            {/* aside derecho */}
            <aside className="order-3 w-full md:w-1/4 lg:block mt-16 flex justify-end items-end">
              <div className='w-[80%] ml-auto'>
                <Notification />
              </div>
            </aside>
          </div>
        </div>
        <Footer />
      </ContainerCardsBackground>
    </ContainerSecurityBackground>
  );
};

export default LayoutBuyer;

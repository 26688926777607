import { BiBell } from 'react-icons/bi';
import DescriptionSmall from '../../../ui/DescriptionSmall';
import Description from '../../../ui/Description';

const CardNotification = ({ color, orden, description }) => {
  return (
    <div className="border-[#A1A1A1] border-opacity-30 border-[0.2px] shadow mb-3 inline-flex w-full items-center bg-neutral-50 rounded-full px-2 py-3 text-base text-neutral-600" role="alert">
      <BiBell className="mr-8 text-4xl text-[#fff] rounded-full bg-[#F39595] p-2" size={40} />
      <div>
        <Description description={'Orden 1'} />
        <DescriptionSmall description={'cancelado por Juan Orozco ayer.'} />
      </div>
    </div>
  );
}

export default CardNotification
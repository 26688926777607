import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { Link } from 'react-router-dom';
import { IconButton, Typography } from '@material-tailwind/react';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';

import RenderBlogsBackground from './RenderBlogsBackground';
import BlogComponent from './BlogComponent';
import MediumPadding from '../../ui/MediumPadding';
import HandleScroll from '../../../util/functions/handleScroll';

const RenderBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [active, setActive] = useState(1);

  useEffect(() => {
    getBlogs();
  }, []);

  const getBlogs = async (page = 1) => {
    const response = await axios.get(
      `https://rickandmortyapi.com/api/character/?page=${page}`,
    );
    HandleScroll();
    setBlogs(response.data.results);
  };

  const next = () => {
    if (active === 10) return;
    getBlogs(active + 1);
    setActive(active + 1);
  };

  const prev = () => {
    if (active === 1) return;
    getBlogs(active - 1);
    setActive(active - 1);
  };

  return (
    <RenderBlogsBackground>
      <MediumPadding>
        <div className="flex justify-center">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[2rem] w-[90vw] max-w-[1200px]">
            {blogs &&
              blogs.map((blog) => (
                <Link to={`/blog/${blog.id}`}>
                  <BlogComponent blog={blog} />
                </Link>
              ))}
          </div>
        </div>
        <>
          <div className="h-[80px]" />
          <div className="flex justify-center items-center gap-8">
            <IconButton
              size="sm"
              variant="outlined"
              color="blue-gray"
              onClick={prev}
              disabled={active === 1}
            >
              <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
            <Typography color="gray" className="font-normal">
              Page <strong className="text-blue-gray-900">{active}</strong> of{' '}
              <strong className="text-blue-gray-900">10</strong>
            </Typography>
            <IconButton
              size="sm"
              variant="outlined"
              color="blue-gray"
              onClick={next}
              disabled={active === 10}
            >
              <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
          </div>
        </>
      </MediumPadding>
    </RenderBlogsBackground>
  );
};

export default RenderBlogs;

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import logo from '../assets/BirdybackDesign/Logo/03-SVG/logo-birdyback-sin-fuente.svg';
import Footer from '../layouts/Footer';
import RoundedButton from '../components/ui/RoundedButton';
import H1 from '../components/typography/H1';
import HandleScroll from '../util/functions/handleScroll';

const TermsConditions = () => {
  useEffect(() => {
    return () => {
      HandleScroll();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Terminos y Condiciones</title>
        <meta name='description' content='Terminos y condiciones de Birdyback para los Birdybuyers y Birdybackers' />
      </Helmet>

      <RoundedButton
        className="fixed bottom-[5vh] right-[5vw] z-[1]"
        text="Descargar PDF"
      />

      <div className="relative bg-primary/[.30] flex items-end h-[60vh] md:h-[90vh] p-[5vw]">
        <div className="absolute bg-transparent flex items-center justify-evenly top-0 right-0 px-[2rem] md:px-[7rem] gap-[1rem] md:gap-0  py-[1rem] w-full">
          <Link to="/">
            <img className="w-[55px] md:w-[65px]" src={logo} alt="Logo de Birdyback sin fuentes" />
          </Link>
          <div className="bg-primary h-[4px] w-[40vw] md:w-[62vw]" />
          <Link to="/">
            <p className="text-primary text-[28px] md:text-[36px] font-bold font-Arciform">
              birdyback
            </p>
          </Link>
        </div>
        <h1 className="text-primary text text-[60px] md:text-[144px] font-bold leading-[3rem] md:leading-[9rem]">
          Términos y Condiciones
        </h1>
      </div>

      <div className="relative flex justify-center h-full px-[10vw] z-[0]">
        <div className="flex flex-col justify-center max-w-[1600px]">
          <div className="h-[10vh] text-center" />
          <H1 className="text-primary font-bold leading-[3rem] md:leading-[4rem]">
            Términos y condiciones de servicio de Birdyback
          </H1>
          <div className="h-[5vh]" />
          <p className="text-primary">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
            imperdiet facilisis leo, et cursus velit pretium id. Curabitur
            sapien nibh, fringilla at sodales vitae, malesuada eget lorem. Etiam
            sagittis interdum facilisis. Mauris et neque at lorem mollis rutrum.
            Nunc viverra facilisis odio nec auctor. Aenean ac lorem non arcu
            pharetra mollis. Nunc euismod eget odio nec porta. Pellentesque
            feugiat blandit iaculis. Vestibulum metus urna, efficitur ac ornare
            porttitor, pretium porttitor eros. Fusce eget nisi pharetra dolor
            vehicula molestie a eget est. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Aliquam tincidunt vestibulum odio vitae
            lobortis. Cras tristique pellentesque velit, et gravida mauris
            tempor non. Morbi ut lobortis dolor. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Nulla facilisi. Proin vulputate luctus
            massa, nec fermentum nulla interdum quis. Sed malesuada lacinia
            tellus sit amet lobortis. Quisque a pulvinar lorem, sed pharetra
            nulla. Vivamus sagittis lectus at lorem fringilla ullamcorper. Nunc
            nec nulla maximus, cursus tortor quis, sollicitudin enim. Vestibulum
            hendrerit velit libero, a hendrerit massa egestas vehicula. Etiam
            auctor enim eu magna auctor, ac consectetur purus interdum. Donec
            nec scelerisque leo. Aliquam egestas maximus sem et fringilla.
            Quisque accumsan mauris mi, quis ultrices libero semper eu. Proin
            lectus velit, posuere eu imperdiet ac, consectetur nec velit.
            Suspendisse consectetur nec purus interdum bibendum. Nam in posuere
            arcu, id semper nisi. In rutrum orci eu nulla convallis placerat.
            Sed tempus eu nisi quis hendrerit. In erat metus, gravida ut
            pharetra id, imperdiet non ligula. Maecenas ac condimentum nisi.
            Integer scelerisque nisl eget lectus rutrum, eu pretium est
            fringilla. Class aptent taciti sociosqu ad litora torquent per
            conubia nostra, per inceptos himenaeos. Curabitur finibus tellus eu
            nunc sodales venenatis. Nullam rutrum turpis hendrerit justo
            iaculis, non auctor nisi varius. Vestibulum laoreet varius augue, eu
            aliquam enim finibus eget. Quisque nec elit at nunc mattis congue.
            Morbi id purus id lacus laoreet sollicitudin. Phasellus ut purus
            eget lectus tempor ultricies. Suspendisse potenti. Pellentesque
            habitant morbi tristique senectus et netus et malesuada fames ac
            turpis egestas. Phasellus fermentum varius orci interdum fringilla.
            Nulla ut elit eu enim ultricies auctor quis ac tellus. Donec nec
            efficitur orci, vel pharetra felis. Curabitur eget vestibulum nibh,
            eu luctus nulla. Nullam condimentum finibus pretium. Praesent eget
            magna rhoncus, pharetra mi et, ultricies urna. Sed et ipsum ut felis
            tristique mattis et vitae sapien. Morbi egestas, sem non cursus
            dictum, quam lacus gravida magna, non feugiat mi risus non est.
            Nulla sed augue at mauris consectetur suscipit et ut elit. Quisque
            malesuada sed mauris sit amet volutpat. Curabitur a vehicula neque,
            a volutpat nulla. Suspendisse vel hendrerit erat. In hac habitasse
            platea dictumst. Ut fermentum metus et nulla cursus, quis
            pellentesque neque tincidunt. Aliquam eget dolor sit amet metus
            dignissim congue. Fusce eu faucibus nibh, sit amet molestie orci.
            Sed maximus ante vel ante vestibulum, eget tristique quam elementum.
            In viverra blandit dolor, et semper dolor. Maecenas id rhoncus orci,
            id rutrum ex. Morbi in orci dapibus, aliquam nunc eget, consectetur
            est. Praesent sollicitudin, sem a tempus ullamcorper, metus magna
            pulvinar ex, mattis lobortis elit felis eget mauris. Sed pharetra
            dui id dictum luctus. Phasellus ultricies accumsan turpis in
            sollicitudin. Mauris mi turpis, pulvinar a ipsum id, venenatis
            dictum risus. Vivamus eu arcu consectetur, ultrices velit a, commodo
            odio. Quisque rutrum, purus ut tempus rutrum, risus lorem molestie
            leo, quis tincidunt neque ex at erat. Vivamus non vestibulum dolor.
            Morbi pretium accumsan diam nec commodo. Maecenas fringilla pretium
            orci, non tempor ipsum mollis maximus. Maecenas sed pharetra velit,
            quis auctor lacus. Aliquam consequat id nibh sit amet tincidunt.
            Etiam pretium accumsan convallis. Sed cursus laoreet massa eget
            eleifend. Pellentesque habitant morbi tristique senectus et netus et
            malesuada fames ac turpis egestas. Vivamus sit amet metus sed orci
            commodo laoreet. Aliquam aliquet libero ut augue euismod gravida.
            Proin cursus fringilla quam nec molestie. Morbi quis metus viverra,
            volutpat diam vel, semper arcu. Aliquam nisi quam, tempus ac neque
            non, iaculis tempor ante. Sed hendrerit nibh nec elit lobortis
            ornare. Phasellus lacinia pretium ante, sed dictum neque eleifend
            et. Phasellus tincidunt pellentesque diam, at ultricies tortor
            varius sed. Integer viverra sem vel justo maximus, eget semper
            turpis porta. Cras feugiat sem id est vulputate cursus. Mauris
            rutrum turpis nunc, a lacinia nunc vehicula ac. Curabitur
            condimentum nunc ac mi pulvinar, quis dapibus orci condimentum. Nam
            pharetra porta arcu, sit amet bibendum lorem scelerisque eget. Nulla
            facilisi. Phasellus feugiat id est blandit viverra. Morbi id
            imperdiet neque, quis feugiat justo. Nam quam massa, aliquet id
            neque eu, ullamcorper convallis felis. Quisque eu dapibus arcu.
            Aliquam sed orci metus. Duis dapibus odio et justo fermentum
            gravida. Suspendisse semper id velit sed porttitor. Sed nec accumsan
            velit. Cras ipsum elit, tempor a massa eget, egestas fermentum diam.
            Donec non dignissim nisi. Cras maximus ornare orci, et rutrum arcu
            tempor egestas. Vivamus eleifend facilisis lectus eu ornare.
            Praesent lobortis ante convallis erat porttitor dictum. Nulla quam
            odio, mattis in volutpat eget, ultricies et purus. Aliquam erat
            volutpat. Phasellus vitae mi justo. Donec magna ligula, ultricies
            vel erat eget, vulputate consequat libero. Quisque vitae semper
            nulla. In finibus, massa quis scelerisque rhoncus, dui risus congue
            nisl, ultricies pellentesque velit tellus quis magna. Cras lacus
            lorem, dictum sit amet neque ac, cursus lobortis enim. Duis eget
            hendrerit lectus. Nam vulputate vestibulum mi, non semper arcu
            tempus ut. Suspendisse eu justo cursus, pulvinar nibh blandit, porta
            magna. Nunc aliquet faucibus lacus, eu luctus velit volutpat
            euismod. Pellentesque habitant morbi tristique senectus et netus et
            malesuada fames ac turpis egestas. Quisque lacinia tellus ut leo
            iaculis aliquam. Nunc sagittis consequat neque, vel vulputate orci
            pharetra sed. Suspendisse potenti. Nunc malesuada rutrum nunc
            hendrerit scelerisque. Cras nec sagittis augue, quis porta massa. Ut
            ut libero arcu. Phasellus dictum mauris ut dolor auctor elementum.
            In hac habitasse platea dictumst. Etiam nulla tortor, auctor id
            felis eget, maximus efficitur est. Suspendisse ut sem sed augue
            cursus pulvinar. Aenean id est a enim fringilla auctor. Aliquam
            vitae massa venenatis, pretium mauris id, malesuada orci. Etiam et
            enim nec magna efficitur consectetur vel vitae magna. Praesent
            lacinia risus ligula, ac ullamcorper est porta at. Integer turpis
            massa, semper id tincidunt sed, pretium eget tellus. Curabitur porta
            leo nec lacus porta tempus. In ut viverra nunc. Nunc est ligula,
            venenatis vel molestie eu, pulvinar hendrerit ex. Aenean lobortis
            hendrerit semper. Cras rutrum iaculis euismod. Nunc lorem mauris,
            venenatis nec purus nec, commodo faucibus diam. Nunc nec libero
            iaculis, ullamcorper libero at, semper odio. Duis magna tortor,
            interdum ac arcu sit amet, sagittis semper arcu. Donec erat nunc,
            venenatis rhoncus ligula eu, iaculis tempus lacus. Proin tincidunt
            tellus tincidunt dolor commodo, ut aliquam quam vulputate. Aenean
            sed posuere ante. Quisque ut sem sit amet justo porttitor facilisis
            ut in ligula. potenti. Nunc malesuada rutrum nunc hendrerit
            scelerisque. Cras nec sagittis augue, quis porta massa. Ut ut libero
            arcu. Phasellus dictum mauris ut dolor auctor elementum. In hac
            habitasse platea dictumst. Etiam nulla tortor, auctor id felis eget,
            maximus efficitur est. Suspendisse ut sem sed augue cursus pulvinar.
            Aenean id est a enim fringilla auctor. Aliquam vitae massa
            venenatis, pretium mauris id, malesuada orci. Etiam et enim nec
            magna efficitur consectetur vel vitae magna. Praesent lacinia risus
            ligula, ac ullamcorper est porta at. Integer turpis massa, semper id
            tincidunt sed, pretium eget tellus. Curabitur porta leo nec lacus
            porta tempus. In ut viverra nunc. Nunc est ligula, venenatis vel
            molestie eu, pulvinar hendrerit ex. Aenean lobortis hendrerit
            semper. Cras rutrum iaculis euismod. Nunc lorem mauris, venenatis
            nec purus nec, commodo faucibus diam. Nunc nec libero iaculis,
            ullamcorper libero at, semper odio. Duis magna tortor, interdum ac
            arcu sit amet, sagittis semper arcu. Donec erat nunc, venenatis
            rhoncus ligula eu, iaculis tempus lacus. Proin tincidunt tellus
            tincidunt dolor commodo, ut aliquam quam vulputate. Aenean sed
            posuere ante. Quisque ut sem sit amet justo porttitor facilisis ut
            in ligula.
          </p>
          <div className="h-[10vh]" />
        </div>
      </div>

      <div className="relative">
        <Footer text="¡Amamos conversar!" />
      </div>
    </>
  );
};

export default TermsConditions;

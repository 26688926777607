import React, { useState } from "react";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import { AiOutlineAlert } from "react-icons/ai";
import { FaPlusCircle } from "react-icons/fa";

const ChooseIcon = (iconName, actionIcon) => {
  switch (iconName) {
    case "alert":
      return <AiOutlineAlert className="ml-1 cursor-pointer" size={24} color="#F39595" onClick={actionIcon} />;
    case "plus":
      return <FaPlusCircle className="ml-1 cursor-pointer" size={20} color="#0A7379" onClick={actionIcon} />;
    default:
      return null;
  }
};

const GeneralCard = ({ children, detailComponent, dropdownPosition, iconName, actionIcon }) => {

  const [showDetail, setShowDetail] = useState(false);

  const toggleDetail = () => {
    setShowDetail((prev) => !prev);
  };

  return (
    <div className="max-w-screen-lg mx-auto bg-white rounded-[20px] border border-solid border-[#ececec] shadow mb-4 py-3 px-2">
      <div className="grid gap-x-4 gap-y-0 grid-rows-4 whitespace-nowrap">
        {children}

        {iconName &&
          <div className="col-start-5 row-start-1 flex justify-end md:justify-center">
            {ChooseIcon(iconName, actionIcon)}
          </div>
        }

        {dropdownPosition === "end" && (
          <div className="col-start-5 row-start-4 flex justify-end md:justify-center">
            {showDetail ? (
              <BiChevronUp
                className="text-[#a1a1a1] cursor-pointer"
                size={20}
                onClick={toggleDetail}
              />
            ) : (
              <BiChevronDown
                className="text-[#a1a1a1] cursor-pointer"
                size={20}
                onClick={toggleDetail}
              />
            )}
          </div>
        )}
        {dropdownPosition === "center" && (
          <div className="col-start-5 row-start-2 row-end-4 flex items-center justify-end md:justify-center">
            {showDetail ? (
              <BiChevronUp
                className="text-[#a1a1a1] cursor-pointer"
                size={20}
                onClick={toggleDetail}
              />
            ) : (
              <BiChevronDown
                className="text-[#a1a1a1] cursor-pointer"
                size={20}
                onClick={toggleDetail}
              />
            )}
          </div>
        )}
      </div>
      {showDetail && (
        <div className="col-span-5 pt-8 pb-5">
          {detailComponent && <>{detailComponent}</>}
        </div>
      )}
    </div>
  );
};

export default GeneralCard;

import React, { useCallback, useState } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from '@material-tailwind/react';
import { Button } from 'flowbite-react';
import { useDropzone } from 'react-dropzone';
import { check, dash } from '../../../../../constants/icons';

const ProductVerification = ({
  cancelService,
  currentService,
  stepNumber,
  handleStep,
}) => {
  const [photo, setphoto] = useState(
    'https://testersdock.b-cdn.net/wp-content/uploads/2017/09/file-upload.png',
  );
  const [isupdatePhoto, setisupdatePhoto] = useState(false);
  const onDrop = useCallback((acceptedFiles) => {
    const url = URL.createObjectURL(acceptedFiles[0]);
    setisupdatePhoto(true);
    setphoto(url);
  }, []);

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png', '.jpeg', '.jpg'],
    },
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
  });
  return (
    <>
      <Accordion
        icon={stepNumber > 0 ? check : dash}
        open={stepNumber === 0}
        disabled={stepNumber !== 0}
      >
        <AccordionHeader>
          <h5>Verificación de compra y producto</h5>
        </AccordionHeader>
        <AccordionBody>
          <div className="p-[0.5rem]">
            <p className="text-[#FF0F00]">Subir foto del recibo*</p>
            <div className="flex justify-center">
              <img className="w-[15rem] h-[15rem]" src={photo} alt="receipt" />
            </div>
            <div
              className="my-[2rem] h-[4rem] flex justify-center gap-[1rem] items-center"
              style={{
                border: 'dashed 1px blue',
              }}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Arrastra la imagen aqui ...</p>
              ) : (
                <p className="flex items-center justify-center gap-[2rem]">
                  Arrastra o selecciona una imagen{' '}
                  <Button onClick={open}>Seleccionar</Button>
                </p>
              )}
            </div>
            <div className="flex justify-between">
              <Button color="failure" onClick={() => cancelService()}>
                Cancelar Servicio
              </Button>
              <Button
                type="submit"
                disabled={!isupdatePhoto}
                onClick={() => handleStep(currentService.id, 1)}
              >
                Continuar
              </Button>
            </div>
          </div>
        </AccordionBody>
      </Accordion>
    </>
  );
};

export default ProductVerification;

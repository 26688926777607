import { BiBell } from 'react-icons/bi';
import CardNotification from "../../components/Aside/CardNotification";

const Notification = () => {
  return (
    <>
      <div className='h-[100px]' />
      <div className='flex flex-row justify-start content-center w-full text-[#0A7379]'>
        <BiBell className="inline-flex mr-1 left-3 top-2" size={30} color="#0A7379" />
        <h6 className='inline-flex h-[50px] font-[600]'>Notificaciones</h6>
      </div>

      <CardNotification />
      <CardNotification />
      <CardNotification />
    </>

  );
}

export default Notification;

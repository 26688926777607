import React, { useState } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from '@material-tailwind/react';
import { Button, Label, Textarea } from 'flowbite-react';
import { check, dash } from '../../../../../constants/icons';
import Swal from 'sweetalert2';
import { changeSubstep } from '../../../../../features/user/servicesSlide';
import { useDispatch } from 'react-redux';

const ProductTracking = ({
  cancelService,
  currentService,
  stepNumber,
  handleStep,
}) => {
  const dispatch = useDispatch();

  // const [subStep, setsubStep] = useState(currentService.process.subStep);
  const [subStep, setsubStep] = useState(0);
  const [description, setdescription] = useState(false);
  const [description1, setdescription1] = useState(false);
  const [description2, setdescription2] = useState(false);

  const handleSubstep = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        // const now = new Date().toLocaleTimeString();
        // if (subStep === 1) {
        //   dispatch(changeSubstep({ id, subStep, start: now }));
        // } else if (subStep === 2) {
        //   dispatch(changeSubstep({ id, subStep, onBoard: now }));
        // }
        setsubStep(subStep + 1);
      }
    });
  };

  return (
    <>
      <Accordion
        icon={stepNumber > 2 ? check : dash}
        open={stepNumber === 2}
        disabled={stepNumber !== 2}
      >
        <AccordionHeader>
          <h5>Seguimiento del producto</h5>
        </AccordionHeader>
        <AccordionBody>
          <div className="flex flex-col gap-[1rem] px-[2rem]">
            <>
              <Accordion
                icon={subStep > 0 ? check : dash}
                open={subStep === 0}
                disabled={subStep !== 0}
              >
                <AccordionHeader>
                  <h5>Inicio</h5>
                </AccordionHeader>
                <AccordionBody>
                  <div className="flex flex-col gap-[1rem]">
                    <div className="flex justify-around">
                      <Button
                        color="failure"
                        onClick={() => setdescription(!description)}
                      >
                        Notificar problema
                      </Button>
                      <Button
                        onClick={() => handleSubstep(currentService.id, 1)}
                      >
                        Sin problema
                      </Button>
                    </div>
                    {description ? (
                      <div className="flex flex-col justify-around gap-[1rem]">
                        <Label htmlFor="problem">Cuentanos el problema</Label>
                        <Textarea
                          className="max-h-[200px] min-h-[45px]"
                          id="problem"
                        ></Textarea>
                        <Button>Notificar</Button>
                      </div>
                    ) : null}
                  </div>
                </AccordionBody>
              </Accordion>
              <Accordion
                icon={subStep > 1 ? check : dash}
                open={subStep === 1}
                disabled={subStep !== 1}
              >
                <AccordionHeader>
                  <h5>Producto Abordo</h5>
                </AccordionHeader>
                <AccordionBody>
                  <div className="flex flex-col gap-[1rem]">
                    <div className="flex justify-around">
                      <Button
                        color="failure"
                        onClick={() => setdescription1(!description1)}
                      >
                        Notificar problema
                      </Button>
                      <Button
                        onClick={() => handleSubstep(currentService.id, 2)}
                      >
                        Sin problema
                      </Button>
                    </div>
                    {description1 ? (
                      <div className="flex flex-col justify-around gap-[1rem]">
                        <Label htmlFor="problem">Cuentanos el problema</Label>
                        <Textarea
                          className="max-h-[200px] min-h-[45px]"
                          id="problem"
                        ></Textarea>
                        <Button>Notificar</Button>
                      </div>
                    ) : null}
                  </div>
                </AccordionBody>
              </Accordion>
              <Accordion
                icon={subStep > 2 ? check : dash}
                open={subStep === 2}
                disabled={subStep !== 2}
              >
                <AccordionHeader>
                  <h5>Llegada producto</h5>
                </AccordionHeader>
                <AccordionBody>
                  <div className="flex flex-col gap-[1rem]">
                    <div className="flex justify-around">
                      <Button
                        color="failure"
                        onClick={() => setdescription2(!description2)}
                      >
                        Notificar problema
                      </Button>
                      <Button onClick={() => handleStep(currentService.id, 3)}>
                        Sin problema
                      </Button>
                    </div>
                    {description2 ? (
                      <div className="flex flex-col justify-around gap-[1rem]">
                        <Label htmlFor="problem">Cuentanos el problema</Label>
                        <Textarea
                          className="max-h-[200px] min-h-[45px]"
                          id="problem"
                        ></Textarea>
                        <Button>Notificar</Button>
                      </div>
                    ) : null}
                  </div>
                </AccordionBody>
              </Accordion>
            </>
            <div className="flex justify-center">
              <Button color="failure" onClick={() => cancelService()}>
                Cancelar Servicio
              </Button>
            </div>
          </div>
        </AccordionBody>
      </Accordion>
    </>
  );
};

export default ProductTracking;

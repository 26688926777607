import React, { useState } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from '@material-tailwind/react';
import { Button, Label, Radio, Textarea } from 'flowbite-react';
import { check, dash } from '../../../../../constants/icons';
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';

const ProductsFisicCondition = ({
  cancelService,
  currentService,
  stepNumber,
  handleStep,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ values: {} });

  const rules = {
    product: {
      errorProduct: {
        required: { value: true, message: 'Choose a option, please' },
      },
      productBugs: {
        required: { value: true, message: 'Choose a option' },
      },
      description: {
        required: { value: true, message: 'Description is required' },
      },
    },
  };

  const [error, seterror] = useState('');
  const [isError, setisError] = useState(false);

  const conditionalComponent = () => {
    switch (error) {
      case 'productBugs':
        return (
          <form className="flex flex-col gap-[1rem]">
            <div className="flex gap-[1rem] justify-between">
              <div className="flex items-center gap-2">
                <Radio
                  id="badConditions"
                  name="suberrors"
                  value="Bad conditions"
                  onClick={() => setisError(true)}
                  defaultChecked={isError}
                  {...register('productBugs', rules['product']['productBugs'])}
                />
                <Label htmlFor="badConditions">
                  Producto en malas condiciones
                </Label>
              </div>
              <div className="flex items-center gap-2">
                <Radio
                  id="wrongProduct"
                  name="suberrors"
                  value="Wrong product"
                  onClick={() => setisError(true)}
                  defaultChecked={isError}
                  {...register('productBugs', rules['product']['productBugs'])}
                />
                <Label htmlFor="wrongProduct">Producto erroneo</Label>
              </div>
            </div>
            {errors['productBugs'] ? (
              <div className="flex justify-center">
                <span className="text-xs text-red-700">
                  {errors['productBugs'].message}
                </span>
              </div>
            ) : null}
            <Textarea
              className="max-h-[200px] min-h-[45px]"
              placeholder="Da una descripción del error"
              {...register('description', rules['product']['description'])}
            />
            {errors['description'] ? (
              <div className="flex justify-center">
                <span className="text-xs text-red-700">
                  {errors['description'].message}
                </span>
              </div>
            ) : null}
          </form>
        );
      case 'noFound':
        return (
          <>
            <div className="flex justify-center">
              <Button
                onClick={() =>
                  Swal.fire(
                    'Verificación',
                    'Te hemos enviado un correo electronico, verifica la información en él',
                    'success',
                  )
                }
              >
                Verificación
              </Button>
            </div>
          </>
        );
      case 'none':
        return (
          <div className="flex items-center">
            <p>Todo correcto</p>
            {check}
          </div>
        );
      default:
        return <></>;
    }
  };

  const onSubmit = (data) => {
    // alert(JSON.stringify(data));
    handleStep(currentService.id, 2);
  };

  return (
    <>
      <Accordion
        icon={stepNumber > 1 ? check : dash}
        open={stepNumber === 1}
        disabled={stepNumber !== 1}
      >
        <AccordionHeader>
          <h5>Condición fisica del producto</h5>
        </AccordionHeader>
        <AccordionBody>
          <form
            className="flex flex-col gap-[1rem] p-[0.5rem]"
            onSubmit={handleSubmit(onSubmit)}
          >
            <p>Existe alguna condición erronea en el producto?</p>
            <div className="flex justify-around gap-[2.5rem]">
              <div className="flex items-center gap-2">
                <Radio
                  id="productBugs"
                  name="errors"
                  value="productBugs"
                  onClick={() => seterror('productBugs')}
                  {...register('product', rules['product']['errorProduct'])}
                />
                <Label htmlFor="productBugs">Errores de producto</Label>
              </div>
              <div className="flex items-center gap-2">
                <Radio
                  id="noFound"
                  name="errors"
                  value="noFound"
                  onClick={() => {
                    seterror('noFound');
                    setisError(false);
                  }}
                  {...register('product', rules['product']['errorProduct'])}
                />
                <Label htmlFor="noFound">No recibió el producto</Label>
              </div>
              <div className="flex items-center gap-2">
                <Radio
                  id="none"
                  name="errors"
                  value="none"
                  onClick={() => {
                    seterror('none');
                    setisError(false);
                  }}
                  {...register('product', rules['product']['errorProduct'])}
                />
                <Label htmlFor="none">Ninguna</Label>
              </div>
            </div>
            {errors['product'] ? (
              <div className="flex justify-center">
                <span className="text-xs text-red-700">
                  {errors['product'].message}
                </span>
              </div>
            ) : null}
            <div className="flex flex-col w-full justify-center gap-2">
              {conditionalComponent()}
            </div>
            <div className="flex justify-between">
              <Button color="failure" onClick={() => cancelService()}>
                Cancelar Servicio
              </Button>
              <Button
                type="submit"
                color={isError ? 'failure' : 'success'}
                // onClick={() => handleStep(params.id, 2)}
              >
                {isError ? 'Devolución' : 'Continuar'}
              </Button>
            </div>
          </form>
        </AccordionBody>
      </Accordion>
    </>
  );
};

export default ProductsFisicCondition;

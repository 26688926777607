import { useTranslation } from 'react-i18next';
import { BiHome, BiShoppingBag, BiCog, BiTrophy } from 'react-icons/bi';
import { logoutUser } from '../../../../features/auth/authSlide';
import SubTitle from "../../../../components/ui/SubTitle";
import { useDispatch } from 'react-redux';

const AsideMenu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();


  const menuItems = [
    { label: t('Inicio'), link: '/', icon: <BiHome className='text-3xl' /> },
    { label: t('Guardadas'), link: '/saved', icon: <BiShoppingBag className='text-3xl' /> },
    { label: t('Ordenes'), link: '/orders', icon: <BiShoppingBag className='text-3xl' /> },
    { label: t('Configuración'), link: '/settings', icon: <BiCog className='text-3xl' /> },
  ];

  return (
    <div className="hidden ml-5 lg:flex items-center flex-col">
      <div className="w-full lg:pl-7">
        <ul>
          {menuItems.map((item, index) => (
            <li key={index} className="mb-4 flex items-center hover:text-primary">
              {item.icon &&
                <span className="mr-2 text-[#A1A1A1]">
                  {item.icon}
                </span>
              }
              <a
                className="ml-[16px] inline-flex font-Montserrat text-center items-center hover:text-primary"
                href={item.link}
              >
                <SubTitle title={item.label} />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AsideMenu;

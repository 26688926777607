import { Tooltip } from 'flowbite-react';
import { BiTrophy } from 'react-icons/bi';
import { logoutUser } from '../../../../features/auth/authSlide';
import { useDispatch } from 'react-redux';
import Title from "../../../ui/Title";

const AsideMenu = () => {
  const dispatch = useDispatch();

  const logout = () => {
    localStorage.removeItem('auth');
    dispatch(logoutUser());
    navigate('/');
  };

  return (
    <div className="hidden ml-5 lg:flex items-center flex-col h-[100px]">
      <div className="flex items-center mb-5">
        <Tooltip content="Cambiar foto" placement="bottom">
          <img className="w-[100px] h-[100px] rounded-md" src="https://i.pravatar.cc/100"
            alt="profile" />
        </Tooltip>
        <div className="ml-4">
          <Title title="Juan Orozco" />
          <StatusPill value="active" />
        </div>
      </div>
    </div>
  );
};

export function StatusPill ({ value }) {
  const status = value ? value.toLowerCase() : "unknown";

  return (
    <span
      className={
        classNames(
          "px-3 py-1 leading-wide font-medium text-md rounded-full shadow-sm",
          status.startsWith("active") ? "bg-[#FFD27A] text-[#D88E00]" : null,
          status.startsWith("inactive") ? "bg-yellow-100 text-yellow-800" : null,
          status.startsWith("offline") ? "bg-red-100 text-red-800" : null,
        )
      }
    >
      <BiTrophy className='inline-flex mr-1' size={20} color="#D88E00" />
      870 millas
    </span>
  );
};

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
}

export default AsideMenu;

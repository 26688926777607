const InputAreaField = ({ label = '', value, onChange, height = '[100px]' }) => {

  const handleChange = (e) => {
    onChange(e);
  };

  return (
    <div className="relative mx-auto w-full">
      <textarea
        value={value}
        onChange={handleChange}
        className={`w-full block h-${height} rounded-[36px] border-[2.5px] border-[#A1A1A1] pl-4 min-h-[100px] max-h-[300px]"
        minLength="10`}
      />
      <label
        className="absolute top-[-18px] left-[38px] mt-2 bg-white px-2 text-gray-600 text-[16px]"
      >
        {label}
      </label>
    </div>
  );
};

export default InputAreaField;
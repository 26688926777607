const GridCategoryIcon = ({ height, width, fill }) => {

  return (
    <svg width="131" height="135" viewBox="0 0 131 135" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_2052_91" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="126" height="125">
        <rect width="125.395" height="124.304" rx="13" fill="#A8EFD9" />
      </mask>
      <g mask="url(#mask0_2052_91)">
        <path d="M116.198 66.4004C113.804 66.4004 111.862 68.3347 111.862 70.7204C111.862 80.2484 104.081 88.0003 94.5168 88.0003C84.9528 88.0003 77.1716 80.2484 77.1716 70.7204C77.1716 68.3347 75.23 66.4004 72.8353 66.4004C70.4406 66.4004 68.499 68.3347 68.499 70.7204C68.499 85.0135 80.1698 96.6403 94.5168 96.6403C108.864 96.6403 120.535 85.0135 120.535 70.7204C120.535 68.3347 118.593 66.4004 116.198 66.4004Z" fill="#FFBB69" />
        <path d="M152 123.512L141.926 60.0684C140.923 53.7298 135.519 49.1292 129.076 49.1292H120.143C118.069 36.8902 107.384 27.5293 94.5169 27.5293C81.6502 27.5293 70.965 36.8902 68.8908 49.1292H59.9578C53.5148 49.1292 48.1113 53.7298 47.1077 60.0663L37.0335 123.512C36.0425 129.762 37.8402 136.107 41.9668 140.918C46.0914 145.73 52.1004 148.489 58.4524 148.489H130.581C136.933 148.489 142.942 145.73 147.067 140.918C151.194 136.107 152.991 129.762 152 123.512ZM94.5169 36.1693C102.575 36.1693 109.307 41.6977 111.246 49.1292H77.7876C79.7271 41.6977 86.4589 36.1693 94.5169 36.1693ZM140.474 135.305C137.998 138.193 134.393 139.849 130.581 139.849H58.4524C54.6412 139.849 51.0354 138.193 48.5602 135.305C46.0829 132.418 45.0052 128.612 45.6002 124.862L55.6744 61.4142C56.009 59.3027 57.8108 57.7692 59.9578 57.7692H129.076C131.223 57.7692 133.025 59.3027 133.359 61.4163L143.434 124.862C144.029 128.612 142.951 132.418 140.474 135.305Z" fill="#FFBB69" />
      </g>
    </svg>
  );
}

export default GridCategoryIcon;
import React, { useState } from "react";
import { Button, TextInput } from "flowbite-react";
import { useForm } from "react-hook-form";
import { t } from "i18next";

const VerifyEmail = () => {
  const [ok, setok] = useState(false);
  const [code, setcode] = useState("");
  const [accept, setaccept] = useState(true);

  const password = "123456";

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    setok(true);
  };

  const checkEmail = () => {
    return (
      <div className="bg-secondary m-auto rounded-lg flex flex-col justify-center items-center p-[4rem] gap-[2rem]">
        <h2 className="text-[20px] text-primary">
          {t("Ingresa el correo a verificar")}
        </h2>
        <div className="flex justify-center">
          <TextInput
            type="email"
            // eslint-disable-next-line
            {...register("email", {
              // eslint-disable-next-line
              pattern:
                /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/,
              required: true,
            })}
          />
        </div>
        {errors.email?.type === "pattern" ? (
          <span className="text-xs text-red-700">
            {t("El formato de email es incorrecto")}
          </span>
        ) : null}
        {errors.email?.type === "required" ? (
          <span className="text-xs text-red-700">{t("Campo requerido")}</span>
        ) : null}
        <Button onClick={handleSubmit(onSubmit)}>Verificar</Button>
      </div>
    );
  };

  const verifyEmail = () => {
    return (
      <div className="bg-secondary m-auto rounded-lg flex flex-col justify-center items-center p-[4rem] gap-[2rem]">
        <h2 className="text-[25px] text-primary">
          {t("Código de verificación")}
        </h2>
        <p className="text-primary">{t("Ingrese el código de verificación")}</p>
        <div className="flex justify-center">
          <TextInput
            defaultValue={code}
            maxLength={6}
            onChange={(e) => {
              setcode(e.target.value);
              if (e.target.value === password) {
                setaccept(false);
              } else {
                setaccept(true);
              }
            }}
          ></TextInput>
        </div>
        <div className="flex justify-center">
          <Button>{t("Reenviar código de verificación")}</Button>
        </div>
        <div className="flex justify-center">
          <Button onClick={() => setok(false)}>{t("Cambiar email")}</Button>
        </div>
        <div className="flex justify-center">
          <Button disabled={accept} onClick={() => setok(false)}>
            {t("Verificar email")}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-primary h-[100vh] pt-[81px] flex">
      {ok ? verifyEmail() : checkEmail()}
    </div>
  );
};

export default VerifyEmail;

import { Table } from "flowbite-react";
import { t } from "i18next";
import React from "react";

const GetModalService = ({ service, cm, kg }) => {
  return (
    <div className="flex flex-col gap-[2rem]">
      <Table striped={true}>
        <Table.Head>
          <Table.Cell>Información del servicio</Table.Cell>
          <Table.Cell />
          <Table.Cell />
          <Table.Cell />
        </Table.Head>
        <Table.Body className="divide-y">
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
              {t("Viaje")}
            </Table.Cell>
            <Table.Cell>
              {t("ESTADOS UNIDOS")} - {t("COLOMBIA")}
            </Table.Cell>
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
              {t("Fecha vuelo")}
            </Table.Cell>
            <Table.Cell>{service.date}</Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
              {t("Aerolinea")}
            </Table.Cell>
            <Table.Cell>{service.aeroline}</Table.Cell>
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
              {t("Número de vuelo")}
            </Table.Cell>
            <Table.Cell>{service.flight}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Table striped={true}>
        <Table.Head>
          <Table.Cell>Información de la maleta</Table.Cell>
          <Table.Cell />
          <Table.Cell />
          <Table.Cell />
        </Table.Head>
        <Table.Body className="divide-y">
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
              Sección
            </Table.Cell>
            <Table.Cell>{service.section}</Table.Cell>
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
              Dimensiones
            </Table.Cell>
            <Table.Cell>
              {service.section === "flexible"
                ? service.section
                : service.section === "floating"
                ? service.lengthCapacity + " " + service.length
                : service.range
                ? service.range * cm + " " + service.length
                : "No"}
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
              Secciones
            </Table.Cell>
            <Table.Cell>
              {service.section === "flexible"
                ? service.section
                : service.section === "floating"
                ? service.side
                : service.range}
            </Table.Cell>
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
              Peso
            </Table.Cell>
            <Table.Cell>
              {service.section === "flexible"
                ? service.section
                : service.section === "floating"
                ? service.weightCapacity + " " + service.weight
                : service.range
                ? service.range * kg + " " + service.weight
                : "No"}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
};

export default GetModalService;

import React, { useState, useEffect } from 'react';

import { t } from 'i18next';
import { ConfigProvider, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import InputField from './InputField';
import RoundedButton from './RoundedButton';

const RemoveAccount = ({ modalOpen, setModalOpen }) => {


  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            borderRadiusLG: 40,
            paddingContentHorizontal: 0,
            paddingContentVertical: 0,
            paddingLG: 0,
            marginLG: 0,
            paddingMD: 0,
            paddingXL: 0,
            paddingSM: 0,
            paddingContentHorizontalLG: 0,
          },
        },
      }}
    >
      <Modal
        centered
        closeIcon={false}
        open={modalOpen}
        onOk={null}
        onCancel={null}
        footer={null}
        width={800}
      >
        <div className="relative flex flex-col items-center gap-[1rem] pt-[3rem] pb-[3rem] overflow-hidden z-[1]">
          <FontAwesomeIcon
            className="absolute top-[12px] right-[18px] text-[24px] cursor-pointer"
            onClick={() => setModalOpen(false)}
            icon={faXmark}
          />

          <div className='w-[80%] flex flex-col items-center'>
            <div className="text-center">
              <h4 className="font-Montserrat text-primary font-[700]">
                {t('Nos gusta tenerte aquí')}
              </h4>
            </div>

            <div className="flex justify-center py-[2rem]">
              <div className="bg-[#C6D12D] h-[4px] w-[160px] rounded-lg" />
            </div>

            <div>
              <p>
                {t(
                  '¿Es seguro que quieres eliminar tu cuenta en Birdyback? Si así es, eliminaremos permanentemente todos tus datos y no podrás recuperar tu perfil ni historial. Si ya tomaste la decisión, no queremos retenerte, pero recuerda que esta acción no se puede revertir.',
                )}
              </p>
            </div>

            <div className='w-full pt-[3rem]'>
              <p className='text-left'>
                {t(
                  'Ingresa tu contraseña',
                )}
              </p>
            </div>

            <div className='w-full pt-8 max-w-[80%]'>
              <InputField
                type="password"
                placeholder={t('Contraseña')}
                label={t('Contraseña')}
                onChange={() => { }}
              />

              <button
                className="w-full text-white bg-[#F39595] rounded-[50px] py-[0.6rem] md:py-[0.8rem] p-[1.2rem] md:p-[1.6rem] font-Montserrat font-[700] text-[10px] md:text-[14px] shadow-lg"
              >
                <p className="font-[600]">{t('Quiero irme')}</p>
              </button>
            </div>
          </div>

        </div>
      </Modal>
    </ConfigProvider >
  );
};


export default RemoveAccount;

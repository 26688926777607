import React, { useState } from "react";
import { Button, Card } from "flowbite-react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import Swal from "sweetalert2";
import { changeStatus } from "../../../features/user/servicesSlide";
import withReactContent from "sweetalert2-react-content";

const ServiceInProgress = () => {
  const services = useSelector((store) => store.services);

  const params = useParams();

  const currentService = services.find((order) => order.id === params.id);

  const MySwal = withReactContent(Swal);

  // const [minimumOfferValue, setminimumOfferValue] = useState(240);
  const minimumOfferValue = 240;
  const [offerValue, setoffer] = useState(null);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const iphone =
    "https://www.losdistribuidores.com/wp-content/uploads/2022/09/iPhone-14-Pro-Max-Negro.webp";

  const minimumOffer = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, accept it!",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate(-1);
        setTimeout(() => {
          dispatch(changeStatus({ id: currentService.id, status: "active" }));
        }, 1000);
      }
    });
  };

  const auction = () => {
    Swal.fire({
      title: "Ingrese el valor a subastar",
      input: "number",
      inputValue: minimumOfferValue,
      inputAttributes: {
        max: minimumOfferValue,
        min: 1,
      },
      showCancelButton: true,
      confirmButtonText: "Look up",
      showLoaderOnConfirm: true,
      preConfirm: (data) => {
        try {
          if (data) {
            console.log(data);
          } else {
            Swal.showValidationMessage(`Request failed`);
          }
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          setoffer(result.value);
          Swal.fire("Saved!", "", "success");
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  const cancelConfirmed = (value) => {
    console.log(value);
    MySwal.close();
    navigate(-1);
    setTimeout(() => {
      dispatch(changeStatus({ id: currentService.id, status: "inactive" }));
    }, 1000);
  };

  const cancel = () => {
    MySwal.fire({
      title: "Por qué rechazas el producto?",
      html: (
        <div className="flex flex-col gap-[1rem]">
          <Button onClick={() => cancelConfirmed("A")}>A</Button>
          <Button color="failure" onClick={() => cancelConfirmed("B")}>
            B
          </Button>
          <Button color="warning" onClick={() => cancelConfirmed("C")}>
            C
          </Button>
        </div>
      ),
      icon: "warning",
      confirmButtonColor: "#d33",
      confirmButtonText: "Cancel",
    });
  };

  return (
    <div className="bg-secondary flex flex-col gap-[2rem] py-[2rem] h-[50rem] mt-[81px]">
      <div className="flex justify-center gap-[1rem] ">
        <Button onClick={() => navigate(-1)}>Back</Button>
        <div className="flex flex-col gap-[2rem] w-1/3">
          <Card>
            <h2 className="text-[20px] font-bold">Mi Servicio</h2>
            <p>
              {t("Aerolinea")}: {currentService.aeroline}
            </p>
            <p>
              {t("Numero de vuelo")}: {currentService.flight}
            </p>
            <p>
              {t("Fecha de vuelo")}: {currentService.date}
            </p>
            <hr />
            <p>
              {t("Tipo de servicio")}: {currentService.section}
            </p>
          </Card>
        </div>
        <div className="flex flex-col w-1/3">
          <Card>
            <h2 className="text-[20px] font-bold">Orden</h2>
            <div className="flex justify-center">
              <img className="w-[15rem] h-[15rem]" src={iphone} alt="iphone" />
            </div>
            <p>
              {t("Link")}:{" "}
              <a
                className="text-primary font-bold"
                href="https://www.losdistribuidores.com/productos/celulares-libres-medellin/apple/iphone-14-pro-max-256gb-precio-medellin"
                target='_blank="true"'
              >
                https://www.losdistribuidores.com/productos/celulares-libres-medellin/apple/iphone-14-pro-max-256gb-precio-medellin
              </a>
            </p>
            <p>
              {t("Valor Minimo")}:{" "}
              {minimumOfferValue
                ? `$ ${minimumOfferValue} USD`
                : "No hay oferta minima"}
            </p>
            <p>
              {t("Subasta")}:{" "}
              {offerValue ? `$ ${offerValue} USD` : "Aún no hay ofertas"}
            </p>
          </Card>
        </div>
      </div>
      <div className="flex justify-center gap-[4rem]">
        <Button onClick={() => minimumOffer()}>Aceptar Oferta Minima</Button>
        <Button onClick={() => auction()}>Subastar</Button>
        <Button onClick={() => cancel()} color="failure">
          Cancelar
        </Button>
      </div>
    </div>
  );
};

export default ServiceInProgress;

const StatusCardOrden = ({ status, color }) => {

  const stylesContainer = {
    backgroundColor: color,
  }

  return (
    <div style={stylesContainer} className='w-[90px] h-[19px] rounded-[62px]'>
      <div className="h-[11px] font-semibold text-[12px] text-neutral-50 text-center">
        {status}
      </div>
    </div>
  );
};

export default StatusCardOrden
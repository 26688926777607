import React, { useEffect, useState } from 'react';
import { Button, Form, Input, InputNumber, Radio, Select } from 'antd';
// import { Button, Label, Radio, Select, Table, TextInput } from 'flowbite-react';
import { Table } from 'flowbite-react';
import { pages } from '../../hooks/useGoTraveler';
import { luggageApi } from '../../api/luggage';

const AddLuggage = ({ setPage, sections, setsections, trip, setTrip }) => {
  const [form] = Form.useForm();

  const [luggages, setLuggages] = useState([]);

  useEffect(() => {
    document.querySelector('.header').classList.add('top-[-80px]');

    if (sections) {
      console.log(sections);
      form.setFieldsValue({ ...sections });
    }

    luggageApi
      .list_luggages()
      .then((response) => setLuggages(response.data.result.items))
      .catch((error) => console.error(error));

    return () => {
      if (document.querySelector('.header')) {
        document.querySelector('.header').classList.remove('top-[-80px]');
      }

      if (sections) {
        form.setFieldsValue();
      }
    };
  }, []);

  const onFinish = (values) => {
    console.log(values);
    setTrip({
      ...trip,
      spaces: [{ luggage_id: values.luggage_id, available: 100 }],
    });
    setsections(values);
    setPage(pages.tripRecap);
  };

  const [section, setsection] = useState(sections ? sections.section : null);
  const [cm, setcm] = useState(true);
  const [kg, setkg] = useState(true);
  const [value, setvalue] = useState(1);

  const conditionalComponent = () => {
    switch (section) {
      case 'flexible':
        return (
          <div className="flex justify-center items-center h-[300px]">
            <h2>Puedes continuar :D</h2>
          </div>
        );
      case 'section':
        return (
          <div className="flex justify-between items-center h-[300px]">
            <div className="w-1/2">
              <Form.Item name="type" label="type" rules={[{ required: true }]}>
                <Select onClick={(e) => setvalue(parseInt(e.target.innerText))}>
                  <Select.Option value="1">1</Select.Option>
                  <Select.Option value="2">2</Select.Option>
                  <Select.Option value="3">3</Select.Option>
                  <Select.Option value="4">4</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="flex justify-center gap-2">
              <Table striped={true}>
                <Table.Body className="divide-y">
                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      Dimensiones
                    </Table.Cell>
                    <Table.Cell>{cm ? value * 10 : value * 14}</Table.Cell>
                    <Table.Cell>
                      <div>
                        <Form.Item
                          name="cm"
                          label="unidad de medición"
                          rules={[{ required: true }]}
                        >
                          <Select
                            onClick={(e) => {
                              if (e.target.innerText === 'Cm') {
                                setcm(true);
                              } else {
                                setcm(false);
                              }
                            }}
                          >
                            <Select.Option value="Cm">Cm</Select.Option>
                            <Select.Option value="In">In</Select.Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      Peso
                    </Table.Cell>
                    <Table.Cell>{kg ? value * 100 : value * 200}</Table.Cell>
                    <Table.Cell>
                      <div>
                        <Form.Item
                          name="kg"
                          label="unidad de medición"
                          rules={[{ required: true }]}
                        >
                          <Select
                            onClick={(e) => {
                              if (e.target.innerText === 'Kg') {
                                setkg(true);
                              } else {
                                setkg(false);
                              }
                            }}
                          >
                            <Select.Option value="Kg">Kg</Select.Option>
                            <Select.Option value="Lb">Lb</Select.Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
          </div>
        );
      case 'floating':
        return (
          <div className="flex w-full items-center h-[300px]">
            <div className="w-1/2">
              <Form.Item
                name="tipo"
                label="tipo de sección"
                rules={[{ required: true }]}
              >
                <Select>
                  <Select.Option value="interna">interna</Select.Option>
                  <Select.Option value="externa">externa</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="flex justify-center gap-2">
              <Table striped={true}>
                <Table.Body className="divide-y">
                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      Dimensiones
                    </Table.Cell>
                    <Table.Cell>
                      <Form.Item name="volumen" rules={[{ required: true }]}>
                        <InputNumber min={1} />
                      </Form.Item>
                    </Table.Cell>
                    <Table.Cell>
                      <div>
                        <Form.Item
                          name="cm"
                          label="unidad de medición"
                          rules={[{ required: true }]}
                        >
                          <Select
                            onClick={(e) => {
                              if (e.target.title === 'Cm') {
                                setcm(true);
                              } else {
                                setcm(false);
                              }
                            }}
                          >
                            <Select.Option value="Cm">Cm</Select.Option>
                            <Select.Option value="In">In</Select.Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      Peso
                    </Table.Cell>
                    <Table.Cell>
                      <Form.Item name="weight" rules={[{ required: true }]}>
                        <InputNumber min={1} />
                      </Form.Item>
                    </Table.Cell>
                    <Table.Cell>
                      <div>
                        <Form.Item
                          name="kg"
                          label="unidad de medición"
                          rules={[{ required: true }]}
                        >
                          <Select
                            onClick={(e) => {
                              if (e.target.title === 'Kg') {
                                setkg(true);
                              } else {
                                setkg(false);
                              }
                            }}
                          >
                            <Select.Option value="Kg">Kg</Select.Option>
                            <Select.Option value="Lb">Lb</Select.Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
          </div>
        );

      default:
        return <div className="h-[300px]"></div>;
    }
  };

  return (
    <Form
      layout="vertical"
      style={{
        margin: 10,
      }}
      onFinish={onFinish}
      requiredMark={false}
      form={form}
    >
      {/* <Form.Item
        name="section"
        label="Selecciona el espacio de maleta"
        rules={[{ required: true, message: 'Selecciona uno' }]}
      >
        <div className="flex justify-around">
          <Radio.Group defaultValue={sections ? sections.section : null}>
            <Radio onClick={() => setsection('flexible')} value="flexible">
              flexible
            </Radio>
            <Radio onClick={() => setsection('section')} value="section">
              section
            </Radio>
            <Radio onClick={() => setsection('floating')} value="floating">
              floating
            </Radio>
          </Radio.Group>
        </div>
      </Form.Item> */}

      <Form.Item
        label="Equipaje"
        name="luggage_id"
        rules={[{ required: true }]}
        style={{ width: 200 }}
      >
        <Select disabled={luggages === null}>
          {luggages &&
            luggages.map((luggage) => (
              <Select.Option key={luggage.id} value={luggage.id}>
                {luggage.type}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      {/* {conditionalComponent()} */}

      <div className="flex justify-evenly">
        <Button onClick={() => setPage(pages.addFlightDate)}>Anterior</Button>
        <Button htmlType="submit">Siguiente</Button>
      </div>
    </Form>
  );
};

export default AddLuggage;

import React, { useState, useEffect } from 'react';

import { t } from 'i18next';
import { ConfigProvider, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import InputField from './InputField';
import RoundedButton from './RoundedButton';
import CardOrden from '../buyer/components/Product/CardOrden';
import OrderInvoice from './OrderInvoice';

const OrderResume = ({ modalOpen, setModalOpen}) => {


  const CenteredRow = ({ text1, text2, fontWeight1, fontWeight2 }) => {
    return (
      <div className="w-[80%] mx-auto">
        <div className="flex flex-row justify-between items-center">
          <div className={`flex items-start font-${fontWeight1}`}>
            <p>{text1}</p>
          </div>
          <div className={`flex items-end font-${fontWeight2}`}>
            <p>{text2}</p>
          </div>
        </div>
      </div>
    );
  };


  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            borderRadiusLG: 40,
            paddingContentHorizontal: 0,
            paddingContentVertical: 0,
            paddingLG: 0,
            marginLG: 0,
            paddingMD: 0,
            paddingXL: 0,
            paddingSM: 0,
            paddingContentHorizontalLG: 0,
          },
        },
      }}
    >
      <Modal
        centered
        closeIcon={false}
        open={modalOpen}
        onOk={null}
        onCancel={null}
        footer={null}
        width={800}
      >
        <div className="relative flex flex-col gap-[1rem] pt-[3rem] pb-[3rem] overflow-hidden z-[1]">
          <FontAwesomeIcon
            className="absolute top-[12px] right-[18px] text-[24px] cursor-pointer"
            onClick={() => setModalOpen(false)}
            icon={faXmark}
          />

          <div className="w-[90%] mx-auto text-center">
            <h4 className="font-Montserrat text-primary font-[700]">
              {t('Mi orden')}
            </h4>
          </div>

          <div className="flex justify-center py-[1rem]">
          </div>

          <div className="w-[80%] mx-auto text-start">
            <h4 className="font-Montserrat text-primary font-[700]">
              {t('Información del viajero')}
            </h4>
          </div>

          <div className="w-[80%] mx-auto text-start">
            <h4 className="font-Montserrat text-primary font-[700]">
              {t('Caja de viaje')}
            </h4>
          </div>

          <div className="w-[80%] mx-auto text-start">
            <h4 className="font-Montserrat text-primary font-[700]">
              {t('Pago')}
            </h4>
          </div>


          <CenteredRow text1="Estado 1" text2="Estado 2" />
          <CenteredRow text1="Estado 1" text2="Estado 2" />
          <CenteredRow text1="Estado 1" text2="Estado 2" />
          <CenteredRow text1="Estado 1" text2="Estado 2" />
        
        </div>
      </Modal>
    </ConfigProvider >
  );
};


export default OrderResume;

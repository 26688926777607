import { useEffect, useState } from 'react';

import ShoppingCart from '../components/buyer/ShoppingCart';
import FormGo from '../components/buyer/FormGo';
import PaymentMethod from '../components/buyer/PaymentMethod';
import AddOrder from '../components/buyer/AddOrder';
import SelectAddress from '../components/buyer/SelectAddress';
import ModalAddress from '../components/traveler/settings/AddressModal';
import Result from '../components/buyer/Result';

export const useGoBuyer = (Order) => {
  // console.log(Order);
  // const [page, setPage] = useState('Result');
  const [page, setPage] = useState('AddOrder');
  const [product, setProduct] = useState(0);

  useEffect(() => {
    if (Order) {
      setPage('Result');
    }
  }, []);

  const conditionalComponent = () => {
    switch (page) {
      case 'AddOrder':
        return (
          <AddOrder setPage={setPage} setProduct={setProduct} Order={Order} />
        );
      case 'ShoppingCart':
        return (
          <ShoppingCart
            setPage={setPage}
            setProduct={setProduct}
            product={product}
            Order={Order}
          />
        );
      case 'FormGo':
        return <FormGo setPage={setPage} product={product} Order={Order} />;
      case 'SelectAddress':
        return <SelectAddress setPage={setPage} Order={Order} />;
      case 'Result':
        return <Result setPage={setPage} />;
      case 'PaymentMethod':
        return <PaymentMethod setPage={setPage} />;
      default:
        return <AddOrder setPage={setPage} setProduct={setProduct} />;
    }
  };

  return { page, setPage, conditionalComponent };
};

import { BiMap } from 'react-icons/bi';

const AddressSelect = () => {
  const addresses = [
    'Carrera 20 # 10 - 11, Manizales, Caldas'
  ];

  return (
    <div className="w-[90%] h-[100px]">
      <div className="relative w-full">
        <BiMap className="absolute left-4 top-1/2 transform -translate-y-1/2" size={20} color="#333" />
        <select className="h-[55px] w-full pl-10 pr-4 bg-white rounded-[30px] border-[#A1A1A1] border-opacity-30 shadow">
          {addresses.map((address, index) => (
            <option
              className='text-[16px] font-[700] text-[#A1A1A1]'
              key={index} value={address}>
              {address}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default AddressSelect;

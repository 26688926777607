import React from 'react';

const CardSecurity = ({ imagen, text, name, position, company }) => {
  return (
    <div className="flex flex-col justify-between h-[400px] w-[320px] lg:w-[360px] p-[4rem] wow fadeInUp">
      <p className="text-[18px] font-[400] leading-[25px] text-white w-[240px]">
        {text}
      </p>
      <div className="flex gap-[1rem]">
        <img src={imagen} alt="" className="w-[60px] h-[60px] rounded-full" />

        <div className="flex flex-col justify-center">
          <p className="text-[15px] font-[700] text-white">{name}</p>
          <p className="text-[12px] font-[500] text-white">{position}</p>
          <p className="text-[12px] font-[500] text-white">{company}</p>
        </div>
      </div>
    </div>
  );
};

export default CardSecurity;

import React, { useState } from 'react';
import { t } from 'i18next';

import HeaderBuyerOrTraveler from '../../components/ui/HeaderBuyerOrTraveler';
import CardSaved from '../../components/buyer/components/Product/CardSaved';
import CardHistory from '../../components/buyer/components/Product/CardHistory';
import RoundedButton from '../../components/ui/RoundedButton';

const sectionSaved = () => {
  return (
    <>
      <div className='flex flex-col justify-center items-center lg:w-[80%] w-full'>
        <div className='my-10 w-[80%]'>
          <RoundedButton text='Nueva orden' />
        </div>
        <div className='w-full'>
          <CardSaved />
        </div>
      </div>
    </>
  );
}

const sectionHistory = () => {
  return (
    <>

      <div className='lg:w-[80%] w-full'>
        <div className='mt-10' />
        <div className='w-full'>
          <CardHistory />
        </div>
      </div>
    </>
  );

}

const Saved = () => {

  const nameElementsHeader = {
    firstNameElement: 'Guardadas',
    SecondNameElement: 'Historial',
  }
  const [selectedOption, setSelectedOption] = useState(nameElementsHeader.firstNameElement);
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };


  return (
    <HeaderBuyerOrTraveler
      selectedOption={selectedOption}
      handleOptionClick={handleOptionClick}
      firstNameElement={nameElementsHeader.firstNameElement}
      SecondNameElement={nameElementsHeader.SecondNameElement}
      content={
        selectedOption === nameElementsHeader.firstNameElement
          ?
          sectionSaved()
          :
          sectionHistory()
      }
    />
  );
}

export default Saved;
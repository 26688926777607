import React, { useEffect, useState } from 'react';

import { t } from 'i18next';
import { Helmet } from 'react-helmet-async';
import DownloadApp from '../components/pages/common/DownloadApp';
import SecurityContainer from '../components/pages/common/SecurityContainer';
import Footer from '../layouts/Footer';
import RoundedButton from '../components/ui/RoundedButton';
import FirstSection from '../components/pages/home/FirstSection';
import SecondSection from '../components/pages/home/SecondSection';
import HandleScroll from '../util/functions/handleScroll';
import TextBanner from '../components/ui/TextBanner';
import H1 from '../components/typography/H1';
import P from '../components/typography/P';
import ModalSuscription from '../components/ui/ModalSuscription';
import ContactUsFAB from '../components/ui/ContactUsFAB';

import hello from '../assets/BirdybackDesign/Artes finales Birdyback/Website-Illustrations/svg/mapa-birdybacker-envio-compra-birdybacker-usa-colombia.svg';

const Home = () => {
  useEffect(() => {
    return () => {
      HandleScroll();
    };
  }, []);

  const [modalSuscriptionIsOpen, setModalSuscriptionIsOpen] = useState(false);

  return (
    <>
      <Helmet>
        <title>Inicio</title>
        <meta name="description" content="Birdyback pagina de inicio" />
      </Helmet>

      {modalSuscriptionIsOpen ? (
        <ModalSuscription
          modalOpen={modalSuscriptionIsOpen}
          setModalOpen={setModalSuscriptionIsOpen}
        />
      ) : null}

      <ContactUsFAB />

      <section className="relative h-[90vh] w-full flex items-end">
        <div className="flex flex-col px-[2rem] md:px-[4rem] lg:px-[8rem] pb-[4rem] md:pb-[1rem] lg:pb-[6rem] gap-[2rem] lg:gap-[3rem]">
          <div className="flex flex-col gap-[1rem] md:gap-[3rem]">
            <H1 className="text-primary font-[400] w-[90vw] md:w-[64vw] max-w-[300px] md:max-w-[680px] leading-[2.5rem] md:leading-[3.5rem] lg:leading-[4.5rem]">
              {t(
                'Somos una comunidad en movimiento de compradores y viajeros.',
              )}
            </H1>

            <h3 className="hidden md:block font-[300] text-primary w-[90vw] md:w-[64vw] lg:w-[42vw] max-w-[270px] md:max-w-[700px] leading-[2.5rem] md:leading-[3rem]">
              {t('¡Facilitamos la conexión entre personas y productos!')}
            </h3>
          </div>
          <div className='w-[90vw] md:w-[64vw] max-w-[300px] md:max-w-[680px]'>
            <RoundedButton
              width='[70%]'
              onClick={() => setModalSuscriptionIsOpen(true)}
              text="¡QUIERO SER PARTE!"
            />
          </div>
        </div>

        <div className="absolute flex justify-end items-start md:items-center lg:items-start w-full h-auto md:h-full lg:h-full z-[-1]">
          <img src={hello} className="h-[124vh] md:h-[120vh] w-auto" alt="Mapa de Estados Unidos y Colombia de Birdybacker (viajero) enviando hacia Colombia un producto comprador por un Birdybuyer (comprador) desde Colombia en Estados Unidos" />
        </div>
      </section>

      <FirstSection />

      <TextBanner
        redirectButton="/nosotros"
        title="Nacimos para conectar"
        text={
          <P className="w-[300px] md:w-auto">
            {t('¿Necesitas')}{' '}
            <span className="font-[700]">
              {t('traer algo a Colombia desde Estados Unidos')}
            </span>
            {t('? O más bien, ¿eres tú quien está')}{' '}
            <span className="font-[700]">
              {t('viajando a Colombia y tienes espacio libre en tu  maleta?')}
            </span>{' '}
            {t('En cualquiera de los dos casos, ¡llegaste donde era!')}
          </P>
        }
        textButton="BIRDYCOMMUNITY"
      />

      <SecondSection />

      <SecurityContainer />

      {/* <TextBanner
        // redirectButton="/signup"
        onClick={() => setModalOpen(true)}
        title="¿Tienes una empresa en crecimiento?"
        text={
          <P className="w-[300px] md:w-auto">
            {t('Sabemos que')}{' '}
            <span className="font-[700]">
              {t(
                'muchas personas fuera del país quieren tener los productos de tu marca',
              )}
            </span>
            {', '}
            {t(
              'pero entendemos que hacer los envíos puede ser difícil y los precios muy altos. Por eso tranqui,',
            )}{' '}
            <span className="font-[700]">{t('Birdyseller')}</span>
            {', '}
            {t('nosotros te ofrecemos el')}{' '}
            <span className="font-[700]">
              {t(
                'servicio de logística a un bajo costo para que hagas tus envíos internacionales sin problemas',
              )}
            </span>
            {', '}
            {t('y además, ¡te encimamos unos')}{' '}
            <span className="font-[700]">{t('beneficios')}</span>{' '}
            {t('para ayudarte a que tu empresa crezca más rápido!')}
          </P>
        }
        textButton="¡QUIERO VENDER!"
      /> */}

      <div className="mb-[4rem]" />

      {/* <DownloadApp /> */}

      <Footer text="¡Dejemos de ser extraños!" />
    </>
  );
};

export default Home;

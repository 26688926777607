import React from "react";
import { t } from "i18next";

export const Discount = ({ bgColor }) => {
  const valueDiscount = 25;

  const mainContainerStyle = {
    width: "260px",
    height: "102px",
    position: "relative",
  };

  const bgStyle = {
    width: "100%",
    height: "100%",
    backgroundColor: `#${bgColor}`,
    borderRadius: "20px",
    position: "absolute",
    top: "0",
    left: "0",
  };

  const textContainer = {
    width: "237px",
    height: "65px",
    top: "14px",
    left: "23px",
    position: "absolute",
  };

  const textLarge = {
    width: "113px",
    top: "0",
    left: "0",
    position: "absolute",
  };

  const textSmall = {
    width: "127px",
    top: "30px",
    left: "106px",
    position: "absolute",
  };

  return (
    <div style={mainContainerStyle}>
      <div className="fixed" style={mainContainerStyle}>
        <div style={bgStyle}>
          <div style={textContainer}>
            <p style={textSmall} className="font-bold text-white text-15 tracking-0 leading-normal">
              {t("De descuento en este artículo")}
            </p>
            <p style={textLarge} className="font-bold text-white text-64 tracking-0 leading-normal whitespace-nowrap">
              <span className="font-bold text-white text-64 tracking-0">
                {valueDiscount}
              </span>
              <span className="text-24">%</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Discount;

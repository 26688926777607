import { useState } from 'react';
import AddFlightDate from '../components/traveler/AddFlightDate';
import AddLuggage from '../components/traveler/AddLuggage';
import TripRecap from '../components/traveler/TripRecap';

export const pages = {
  addFlightDate: 'AddFlightDate',
  addLuggage: 'AddLuggage',
  tripRecap: 'TripRecap',
};

export const useGoTraveler = (setvisible) => {
  const [page, setPage] = useState(pages.addFlightDate);

  const [trip, setTrip] = useState({
    airline_id: null,
    flight_number: null,
    date_flight: null,
    spaces: [
      {
        luggage_id: null,
        available: 100,
      },
    ],
  });

  console.log(trip);

  const [sections, setsections] = useState();

  const conditionalComponent = () => {
    switch (page) {
      case pages.addFlightDate:
        return (
          <AddFlightDate setPage={setPage} trip={trip} setTrip={setTrip} />
        );
      case pages.addLuggage:
        return (
          <AddLuggage
            setPage={setPage}
            trip={trip}
            setTrip={setTrip}
            sections={sections}
            setsections={setsections}
          />
        );
      case pages.tripRecap:
        return (
          <TripRecap
            trip={trip}
            sections={sections}
            setPage={setPage}
            setvisible={setvisible}
          />
        );
      default:
        return <AddFlightDate />;
    }
  };

  return {
    page,
    setPage,
    trip,
    setTrip,
    sections,
    setsections,
    conditionalComponent,
  };
};

import React, { useCallback, useState } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from '@material-tailwind/react';
import { check, dash } from '../../../../../constants/icons';
import { Button, Label, TextInput } from 'flowbite-react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';

const ProductShipping = ({
  cancelService,
  currentService,
  stepNumber,
  handleStep,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ values: {} });

  const rules = {
    product: {
      tracking: {
        required: { value: true, message: 'Tracking ID is required' },
      },
    },
  };

  const [photo1, setphoto1] = useState(
    'https://testersdock.b-cdn.net/wp-content/uploads/2017/09/file-upload.png',
  );
  const [isupdatePhoto, setisupdatePhoto] = useState(false);
  const onDrop = useCallback((acceptedFiles) => {
    const url = URL.createObjectURL(acceptedFiles[0]);
    setisupdatePhoto(true);
    setphoto1(url);
  }, []);

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png', '.jpeg', '.jpg'],
    },
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
  });

  const onSubmit = (data) => {
    // alert(JSON.stringify(data));
    handleStep(currentService.id, 4);
  };

  return (
    <>
      <Accordion
        icon={stepNumber > 3 ? check : dash}
        open={stepNumber === 3}
        disabled={stepNumber !== 3}
      >
        <AccordionHeader>
          <h5>Envio de producto</h5>
        </AccordionHeader>
        <AccordionBody>
          <form className="p-[0.5rem]" onSubmit={handleSubmit(onSubmit)}>
            <p className="text-[#FF0F00]">Subir foto del recibo de envio*</p>
            <div className="flex justify-center">
              <img
                className="w-[15rem] h-[15rem]"
                src={photo1}
                alt="receipt"
              ></img>
            </div>
            <div
              className="my-[2rem] h-[4rem] flex justify-center gap-[1rem] items-center"
              style={{
                border: 'dashed 1px blue',
              }}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Arrastra la imagen aqui ...</p>
              ) : (
                <p className="flex items-center justify-center gap-[2rem]">
                  Arrastra o selecciona una imagen{' '}
                  <Button onClick={open}>Seleccionar</Button>
                </p>
              )}
            </div>
            <Label htmlFor="tracking">Tracking ID*</Label>
            <TextInput
              id="tracking"
              {...register('tracking', rules['product']['tracking'])}
            ></TextInput>
            {errors['tracking'] ? (
              <div className="flex justify-center">
                <span className="text-xs text-red-700">
                  {errors['tracking'].message}
                </span>
              </div>
            ) : null}
            <br />
            <div className="flex justify-between">
              <Button color="failure" onClick={() => cancelService()}>
                Cancelar Servicio
              </Button>
              <Button type="submit" disabled={!isupdatePhoto}>
                Continuar
              </Button>
            </div>
          </form>
        </AccordionBody>
      </Accordion>
    </>
  );
};

export default ProductShipping;

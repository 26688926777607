import React, { useState } from "react";
import { t } from 'i18next';
import CollapseSetting from "../../../pages/common/settings/CollapseSetting";
import { useNavigate } from "react-router-dom";

//icons
import { BsFileEarmarkMedical } from "react-icons/bs";
import { MdSecurity } from "react-icons/md";
import { LiaUserSlashSolid } from "react-icons/lia";

// My collapse options
import Addresses from "../../../components/buyer/settings/Addresses";
import Password from "../../../components/buyer/settings/Password";
import GeneralTitle from "../../ui/Title";
import RemoveAccount from "../../ui/RemoveAccount";

const General = () => {
  const [activeSection, setActiveSection] = useState(null);

  const handleSectionToggle = (sectionId) => {
    setActiveSection(activeSection === sectionId ? null : sectionId);
  };

  const [RemoveAccountIsOpen, setRemoveAccountIsOpen] = useState(false);

  const data = [
    {
      icon: <BsFileEarmarkMedical className="text-3xl text-[#A1A1A1]" size={50} />,
      title: t('Terminos y condiciones'),
      description: t('Lee nuestros términos legales y condiciones de uso.'),
      id: 'terms-and-conditions'
    },
    {
      icon: <MdSecurity className="text-[#A1A1A1]" size={50} />,
      title: t('Politicas y privacidad'),
      description: t('Consulta nuestras políticas de privacidad y seguridad de datos.'),
      id: 'privacy-and-security'
    },
    {
      icon: <LiaUserSlashSolid className="text-3xl text-[#A1A1A1]" size={50} />,
      title: t('Desactivar cuenta'),
      description: t('Encuentra como desactivar tu cuenta de Birdyback'),
      id: 'deactivate-account'
    }
  ];

  const navigate = useNavigate();

  const actionButton = (id) => {
    switch (id) {
      case 'deactivate-account':
        setRemoveAccountIsOpen(true);
        break;
      case 'terms-and-conditions':
        navigate("/terminos-y-condiciones");
        break;
      case 'privacy-and-security':
        navigate("/politicas-de-privacidad");
        break;
      default:
        // Vacio
        break;
    }
  };

  const changeSetting = (option) => {
    switch (option) {
      case 'addresses':
        return <Addresses />
      case 'password':
        return <Password />
      default:
        return null;
    }
  };

  return (
    <div className="max-w-4xl mx-auto bg-white p-16 rounded">
      <GeneralTitle title='Información General' />

      {RemoveAccountIsOpen ? (
        <RemoveAccount
          modalOpen={RemoveAccountIsOpen}
          setModalOpen={setRemoveAccountIsOpen}
        />
      ) : null}

      {data.map((item) => (
        <div key={item.id}>
          <CollapseSetting
            id={item.id}
            icon={item.icon}
            title={item.title}
            description={item.description}
            isActive={activeSection === item.id}
            onToggle={() => handleSectionToggle(item.id)}
            isAnotherCollapse={false}
            onClick={() => actionButton(item.id)}
          />

          {activeSection === item.id && (
            <div className="mt-4">
              {changeSetting(item.id)}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default General;

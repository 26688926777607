import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

import Footer from '../layouts/Footer';
import HandleScroll from '../util/functions/handleScroll';
import RoundedButton from '../components/ui/RoundedButton';

import largeCenteredCloud from '../assets/background/nube-grande-centrada-azul-birdybacker.svg';

const BlogDetails = () => {
  const params = useParams();

  const navigate = useNavigate();

  const [blog, setBlog] = useState();

  useEffect(() => {
    getBlogById();

    return () => {
      HandleScroll();
      setBlog();
    };
  }, []);

  const getBlogById = async () => {
    const response = await axios.get(
      `https://rickandmortyapi.com/api/character/${params.id}`,
    );
    setBlog(response.data);
  };

  return (
    <>
      <div className="relative bg-[#FFD2B040] rounded-b-[50px] md:rounded-b-[100px] flex flex-col justify-evenly px-[2rem] md:px-[7rem] h-[30vh] md:h-[20vh] lg:h-[35vh]">
        <RoundedButton onClick={() => navigate(-1)} text="Regresar" />

        {blog?.name && (
          <h1 className="text-[#3FC2A0] font-bold">{blog.name}</h1>
        )}

        <img
          className="rounded-full absolute hidden md:block bottom-[-100px] right-[10rem] w-[280px] h-[280px]"
          src={blog?.image && blog.image}
        />
      </div>

      <div className="flex justify-center">
        <div className="relative px-[2rem] md:px-[7rem] py-[2rem]">
          <div className="2xl:max-w-[1200px]">
            {blog?.species && <h3 className="font-bold">{blog.species}</h3>}

            <br />

            {blog?.gender && <p>{blog.gender}</p>}

            <br />

            <p>
              Somos el puente entre tu tienda internacional favorita y tú. Eso
              que necesitas, quieres o te antoja te lo hacemos llegar a tu casa
              mientras te tomas el cafecito. Proporcionamos una gran red
              inteligente de viajeros de confianza haciendo llegar tu pedido a
              tus manos y, ¿a quién no le gustaría ganar dinero extra mientras
              viaja? ¡Nosotros nos encargamos de eso! Bienvenidos.
            </p>

            <p>
              Somos el puente entre tu tienda internacional favorita y tú. Eso
              que necesitas, quieres o te antoja te lo hacemos llegar a tu casa
              mientras te tomas el cafecito. Proporcionamos una gran red
              inteligente de viajeros de confianza haciendo llegar tu pedido a
              tus manos y, ¿a quién no le gustaría ganar dinero extra mientras
              viaja? ¡Nosotros nos encargamos de eso! Bienvenidos.
            </p>

            <p>
              Somos el puente entre tu tienda internacional favorita y tú. Eso
              que necesitas, quieres o te antoja te lo hacemos llegar a tu casa
              mientras te tomas el cafecito. Proporcionamos una gran red
              inteligente de viajeros de confianza haciendo llegar tu pedido a
              tus manos y, ¿a quién no le gustaría ganar dinero extra mientras
              viaja? ¡Nosotros nos encargamos de eso! Bienvenidos.
            </p>

            <p>
              Somos el puente entre tu tienda internacional favorita y tú. Eso
              que necesitas, quieres o te antoja te lo hacemos llegar a tu casa
              mientras te tomas el cafecito. Proporcionamos una gran red
              inteligente de viajeros de confianza haciendo llegar tu pedido a
              tus manos y, ¿a quién no le gustaría ganar dinero extra mientras
              viaja? ¡Nosotros nos encargamos de eso! Bienvenidos.
            </p>

            <p>
              Somos el puente entre tu tienda internacional favorita y tú. Eso
              que necesitas, quieres o te antoja te lo hacemos llegar a tu casa
              mientras te tomas el cafecito. Proporcionamos una gran red
              inteligente de viajeros de confianza haciendo llegar tu pedido a
              tus manos y, ¿a quién no le gustaría ganar dinero extra mientras
              viaja? ¡Nosotros nos encargamos de eso! Bienvenidos.
            </p>

            <p>
              Somos el puente entre tu tienda internacional favorita y tú. Eso
              que necesitas, quieres o te antoja te lo hacemos llegar a tu casa
              mientras te tomas el cafecito. Proporcionamos una gran red
              inteligente de viajeros de confianza haciendo llegar tu pedido a
              tus manos y, ¿a quién no le gustaría ganar dinero extra mientras
              viaja? ¡Nosotros nos encargamos de eso! Bienvenidos.
            </p>

            <p>
              Somos el puente entre tu tienda internacional favorita y tú. Eso
              que necesitas, quieres o te antoja te lo hacemos llegar a tu casa
              mientras te tomas el cafecito. Proporcionamos una gran red
              inteligente de viajeros de confianza haciendo llegar tu pedido a
              tus manos y, ¿a quién no le gustaría ganar dinero extra mientras
              viaja? ¡Nosotros nos encargamos de eso! Bienvenidos.
            </p>

            <p>
              Somos el puente entre tu tienda internacional favorita y tú. Eso
              que necesitas, quieres o te antoja te lo hacemos llegar a tu casa
              mientras te tomas el cafecito. Proporcionamos una gran red
              inteligente de viajeros de confianza haciendo llegar tu pedido a
              tus manos y, ¿a quién no le gustaría ganar dinero extra mientras
              viaja? ¡Nosotros nos encargamos de eso! Bienvenidos.
            </p>
          </div>

          <img
            className="absolute top-[80px] left-[0.1rem] md:left-[10rem] z-[-1]"
            src={largeCenteredCloud}
            alt="Nube centrada grande azul ilustrada representando al Birdybacker (viajero) de Birdyback"
          />

          <img
            className="absolute bottom-[100px] right-[0.1rem] md:right-[10rem] z-[-1]"
            src={largeCenteredCloud}
            alt="Nube centrada grande azul ilustrada representando al Birdybacker (viajero) de Birdyback"
          />
        </div>
      </div>

      <Footer text="¡Amamos conversar!" />
    </>
  );
};

export default BlogDetails;

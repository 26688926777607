import React, { useEffect, useState } from 'react';
import { Badge, Button, Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ModalService from '../../components/traveler/services/ModalService';
import Swal from 'sweetalert2';
import { t } from 'i18next';
import { deleteService, getTravels } from '../../features/user/servicesSlide';
import { useNavigate } from 'react-router-dom';
import GoTraveler from '../../components/traveler/GoTraveler';
import { panel, pencil, trash } from '../../constants/icons';
import { travelsApi } from '../../api/travels';

const pages = {
  ACTIVE: 'ACTIVE',
  IN_PROGRESS: 'IN_PROGRESS',
  INACTIVE: 'accepted',
  HISTORY: 'HISTORY',
};

const Services = () => {
  const services = useSelector((store) => store.services);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTravels(JSON.parse(localStorage.getItem('birdyuser'))));
  }, []);

  const navigate = useNavigate();

  const [viewservices, setviewservices] = useState(services);
  const [page, setpage] = useState(pages.ACTIVE);
  const [active, setactive] = useState(false);
  const [option, setoption] = useState('get');
  const [service, setservice] = useState(null);
  const [visible, setvisible] = useState(false);

  const deleteCurrentService = (id) => {
    Swal.fire({
      title: 'Deseas eliminar este servicio?',
      text: 'Verifica que sea correcto el servicio que deseas eliminar',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Continuar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        travelsApi
          .delete_travel({
            id,
            Authorization: JSON.parse(localStorage.getItem('birdyuser')),
          })
          .then((response) => {
            if (response.status === 200) {
              Swal.fire(
                'Enhorabuena',
                'Se ha eliminado correctamente tu viaje',
                'success',
              );
              dispatch(
                getTravels(JSON.parse(localStorage.getItem('birdyuser'))),
              );
            }
          })
          .catch((error) => Swal.fire('Ups', 'something went wrong', 'error'));
      }
    });
  };

  return (
    <div className="pt-[81px] bg-secondary h-full p-[3rem]">
      <h2 className="text-center font-bold text-primary">Services</h2>
      <div className="flex justify-center text-center h-[3rem]">
        <div className="flex bg-primary text-white rounded-lg shadow-xl w-1/2 cursor-pointer overflow-hidden">
          <p
            className={`flex justify-center items-center w-1/3 h-full ${
              page === pages.ACTIVE ? 'bg-[#00c800]' : null
            }`}
            onClick={() => setpage(pages.ACTIVE)}
          >
            {t('Activo')}
          </p>
          <p
            className={`flex justify-center items-center w-1/3 h-full ${
              page === pages.IN_PROGRESS ? 'bg-[#007fff]' : null
            }`}
            onClick={() => setpage(pages.IN_PROGRESS)}
          >
            {t('En proceso')}
          </p>
          <p
            className={`flex justify-center items-center w-1/3 h-full ${
              page === pages.INACTIVE ? 'bg-[#ffa500]' : null
            }`}
            onClick={() => setpage(pages.INACTIVE)}
          >
            {t('Inactivo')}
          </p>
          <p
            className={`flex justify-center items-center w-1/3 h-full ${
              page === pages.HISTORY ? 'bg-[#fd000f]' : null
            }`}
            onClick={() => setpage(pages.HISTORY)}
          >
            {t('Historial')}
          </p>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-[1rem] mt-[2rem]">
        {services.items?.map((service) =>
          service.status === page ? (
            <Card className="cursor-pointer" key={service.id}>
              <p>Aerolinea: {service.airline}</p>
              <p>Número de vuelo: {service.flight_number}</p>
              <p>Fecha de vuelo: {service.date_flight}</p>
              {/* <p>
                {service.country_origin}-{service.country_destination}
              </p> */}
              <div className="flex items-center gap-[1rem] top-[2rem] right-[2rem]">
                <Button
                  // icon={panel}
                  size="large"
                  onClick={() => {
                    navigate(`/service/panel/${service.id}`);
                  }}
                >
                  <p>{t('Panel de estados')}</p>
                </Button>

                <Button
                  className="text-[#FF0000]"
                  onClick={() => deleteCurrentService(service.id)}
                >
                  {trash}
                </Button>
              </div>
            </Card>
          ) : null,
        )}
      </div>
      {services.items?.length === 0 && (
        <h2 className="text-center">Aún no hay servicios</h2>
      )}
      {page !== pages.HISTORY && (
        <div className="flex justify-center mt-[1rem]">
          <Button onClick={() => setvisible(true)}>
            {services.items?.length === 0 ? (
              <p className="text-center">Agregar mi primer servicio</p>
            ) : (
              <p className="text-center">Agregar nuevo servicio</p>
            )}
          </Button>
        </div>
      )}
      {active && (
        <ModalService
          active={active}
          setactive={setactive}
          service={service}
          option={option}
        />
      )}
      {visible && <GoTraveler visible={visible} setvisible={setvisible} />}
    </div>
  );
};

export default Services;

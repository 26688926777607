import React from "react";
import { Modal } from "flowbite-react";
import { t } from "i18next";
import EditModalService from "./EditModalService";
import GetModalService from "./GetModalService";

const ModalService = ({ active, setactive, service, option }) => {
  let cm = service ? (service.length === "Cm" ? 10 : 14) : null;
  let kg = service ? (service.weight === "Kg" ? 100 : 200) : null;
  return (
    <React.Fragment>
      <Modal
        show={active}
        popup={true}
        size="4xl"
        onClose={() => setactive(false)}
      >
        <Modal.Header>
          {option
            ? option === "get"
              ? t("Descripción")
              : option === "edit"
              ? t("Edición")
              : null
            : null}
        </Modal.Header>
        <Modal.Body>
          {service ? (
            option === "get" ? (
              <GetModalService service={service} cm={cm} kg={kg} />
            ) : option === "edit" ? (
              <EditModalService service={service} cm={cm} kg={kg} />
            ) : null
          ) : null}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ModalService;

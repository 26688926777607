
const CardProduct = ({ product }) => {
  //const { name, price, image, description } = product;

  return (
    <div class="relative m-10 flex w-full max-w-xs flex-col overflow-hidden rounded-lg border border-gray-100 bg-white shadow-md">
      <a class="relative mx-3 mt-3 flex h-60 overflow-hidden rounded-xl" href="#">
        <img class="object-cover" src="https://images.unsplash.com/photo-1600185365483-26d7a4cc7519?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8c25lYWtlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60" alt="product image" />
        <span class="absolute top-0 left-0 m-2 rounded-full bg-black px-2 text-center text-sm font-medium text-white">39% OFF</span>
      </a>
      <div class="mt-4 px-5 pb-5">
        <a href="#">
          <h5 class="w-[129.634px] h-[14.014px] shrink-0 text-[#A1A1A1] text-[15px] not-italic font-bold leading-[107.5%] tracking-[-0.285px] uppercase">Producto 1</h5>
        </a>
      </div>
      {/* ver tienda */}
      <div class="mt-1 items-end px-5 pb-5">
        <a href="#">
          ver tienda
        </a>
      </div>
    </div>
  );
}

export default CardProduct;
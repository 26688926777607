import React, { useEffect, useState } from 'react';
import { Button, Card, Modal } from 'antd';
import {
  PlusOutlined,
  ShoppingCartOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  addOrder,
  deleteProductOfOrder,
  updateOrder,
} from '../../features/user/ordersSlide';
import { deleteProduct, reset } from '../../features/user/productsSlide';
import { useNavigate } from 'react-router-dom';
import ProductDetails from './ProductDetails';
import Swal from 'sweetalert2';

const ShoppingCart = ({ setPage, setProduct, product, Order }) => {
  useEffect(() => {
    document.querySelector('.header').classList.add('top-[-80px]');
    return () => {
      if (document.querySelector('.header')) {
        document.querySelector('.header').classList.remove('top-[-80px]');
      }
    };
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const products = useSelector((state) => state.products);
  const orders = useSelector((state) => state.orders);

  const currentOrder = orders.find((order) => order.id === Order.id);

  // useEffect(() => {}, [orders]);

  const [showModal, setshowModal] = useState(false);

  return (
    <div className="flex flex-col gap-[1rem]">
      <div className="grid grid-cols-3 gap-[1rem] p-[1rem] rounded-[10px] h-[500px]">
        {currentOrder
          ? currentOrder.products.map((product) =>
              product ? (
                <Card
                  key={product.id}
                  cover={
                    <img
                      className="w-[150px]"
                      src={product.product_link}
                      alt={product.product_link}
                    />
                  }
                  hoverable={true}
                  onClick={(e) => {
                    if (
                      !e.target.classList.contains('ignore') &&
                      e.target.classList.length !== 0
                    ) {
                      setProduct(product);
                      setshowModal(true);
                    }
                  }}
                >
                  <p>{product.name}</p>

                  <div className="ignore flex justify-around gap-[1rem]">
                    <Button
                      onClick={() => {
                        setProduct(product);
                        setPage('FormGo');
                      }}
                      className="ignore flex items-center"
                      icon={<EditOutlined />}
                    >
                      Editar
                    </Button>

                    <Button
                      onClick={() => {
                        if (currentOrder) {
                          console.log({
                            id: currentOrder.id,
                            id_product: product.id,
                          });
                          dispatch(
                            deleteProductOfOrder({
                              id: currentOrder.id,
                              id_product: product.id,
                            }),
                          );
                        } else {
                          dispatch(deleteProduct(product.id));
                        }
                      }}
                      className="ignore flex items-center"
                      icon={<DeleteOutlined />}
                      danger
                    >
                      Eliminar
                    </Button>
                  </div>
                </Card>
              ) : null,
            )
          : products.map((product) =>
              product ? (
                <Card
                  key={product.id}
                  cover={
                    <img
                      className="w-[150px]"
                      src={product.product_link}
                      alt={product.product_link}
                    />
                  }
                  hoverable={true}
                  onClick={(e) => {
                    if (
                      !e.target.classList.contains('ignore') &&
                      e.target.classList.length !== 0
                    ) {
                      setProduct(product);
                      setshowModal(true);
                    }
                  }}
                >
                  <p>{product.name}</p>

                  <div className="ignore flex justify-around gap-[1rem]">
                    <Button
                      onClick={() => {
                        setProduct(product);
                        setPage('FormGo');
                      }}
                      className="ignore flex items-center"
                      icon={<EditOutlined />}
                    >
                      Editar
                    </Button>

                    <Button
                      onClick={() => {
                        dispatch(deleteProduct(product.id));
                      }}
                      className="ignore flex items-center"
                      icon={<DeleteOutlined />}
                      danger
                    >
                      Eliminar
                    </Button>
                  </div>
                </Card>
              ) : null,
            )}

        <div className="flex flex-col justify-around">
          <Button
            onClick={() => {
              setProduct(null);
              setPage('FormGo');
            }}
            className="flex items-center"
            size="large"
            icon={<PlusOutlined />}
            type="dashed"
          >
            Añadir producto
          </Button>
          <Button
            className="flex items-center"
            size="large"
            icon={<ShoppingCartOutlined />}
            type="dashed"
          >
            Ver productos interesantes
          </Button>
        </div>
      </div>

      <div className="flex justify-between">
        <Button onClick={() => setPage('AddOrder')}>Anterior</Button>

        <Button
          onClick={() => {
            if (products.length > 0) {
              if (Order) {
                // dispatch(updateOrder({ id: Order.id, products: products }));
              } else {
                dispatch(
                  addOrder({
                    id: products.length,
                    products: [...products],
                    section: JSON.parse(localStorage.getItem('order')).section,
                  }),
                );
              }

              // setTimeout(() => {
              //   dispatch(reset());
              // }, 2000);
              localStorage.setItem('products', JSON.stringify(products));
              localStorage.setItem('id', JSON.stringify(products.length));
              setPage('SelectAddress');
            } else {
              Swal.fire(
                'Ups!',
                'Aún no hay productos en tu carrito, agrega uno',
                'question',
              );
            }
          }}
        >
          Siguiente
        </Button>
      </div>

      <ProductDetails
        showModal={showModal}
        setshowModal={setshowModal}
        product={product}
      />
    </div>
  );
};

export default ShoppingCart;

import React, { useState, useEffect, useRef } from 'react';
import HandleScroll from '../../util/functions/handleScroll';
import { t } from 'i18next';
import { useForm } from 'react-hook-form';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { auth } from '../../api/auth';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import LoginBackground from '../../components/pages/common/LoginBackgound';
import NavBuyer from '../../layouts/NavBuyer';
import Footer from '../../layouts/Footer';
import GeneralTitle from '../../components/ui/Title';

const ConfirmSignup = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [email, setEmail] = useState();
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resend, setResend] = useState(false);

  const navigate = useNavigate();

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    setEmail(JSON.parse(localStorage.getItem('email')));
    HandleScroll();
  }, []);

  const inputRefs = Array(6)
    .fill(0)
    .map(() => useRef(null));

  const handleCodeChange = (index, e) => {
    const value = e.target.value;
    if (value > 9) {
      if (value !== '' && index < 5) {
        inputRefs[index + 1].current.focus();
      }
      return;
    }

    if (
      value !== '' &&
      verificationCode.length <= 6 &&
      verificationCode[index] !== value
    ) {
      setVerificationCode((prevCode) => {
        const codeArr = prevCode.split('');
        codeArr.splice(index, 1, value);
        return codeArr.join('');
      });
      if (index < 5) {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleCodePaste = (e) => {
    const pastedCode = e.clipboardData.getData('text');
    if (/^\d{6}$/.test(pastedCode)) {
      setVerificationCode(pastedCode);
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.code === 'Backspace' || event.code === 'Delete') {
      console.log('Se presionó la tecla de borrar');

      if (event.target?.value === '' && index > 0) {
        setVerificationCode((prevCode) => {
          const codeArr = prevCode.split('');

          codeArr.splice(index, 1, '');

          return codeArr.join('');
        });
        inputRefs[index - 1].current.focus();
      } else {
        event.target.value = '';
      }
    }
  };

  const onSubmit = () => {
    if (verificationCode.length === 6) {
      setError(false);
      setLoading(true);
      auth
        .confirm_signup({ email, code: verificationCode })
        .then((response) => {
          if (response.status === 200) {
            navigate('/login');
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    } else {
      setError(true);
      setLoading(false);
    }
  };

  const resend_confirmation_code = () => {
    if (!resend) {
      setResend(true);
      auth
        .resend_confirmation_code({ email })
        .then((response) => {
          if (response.status === 200) {
            Swal.fire(
              'Enhorabuena',
              'Hemos enviado un nuevo codigo de verificación a tu correo',
              'success',
            );
          }
        })
        .catch((err) => console.error(err))
        .finally(() =>
          setTimeout(() => {
            setResend(false);
          }, 60000),
        );
    }
  };

  return (
    <>
      <NavBuyer />
      <LoginBackground>
        <div className="h-screen w-screen flex justify-center items-start">
          <form
            className="flex flex-col w-[95%] md:w-[70%] lg:w-1/3 my-[10vh] px-[2rem] md:px-[4rem]"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-col justify-center items-center h-full">
              <div className="text-center my-4">
                <GeneralTitle title={t('Codigo de verificación')} />
                <p className='mb-14 mt-6 text-primary'>
                  Revisa tu correo, hemos enviado un codigo de verificación
                </p>
              </div>

              <div className='mb-5 w-full'>
                <div className="flex justify-center gap-[1rem] my-[1rem]">
                  {inputRefs.map((inputRef, index) => (
                    <input
                      key={index}
                      ref={inputRef}
                      type="number"
                      maxLength={1}
                      className="custom-input w-12 h-12 text-center border border-gray-300 rounded"
                      value={verificationCode[index] || ''}
                      onChange={(e) => handleCodeChange(index, e)}
                      onPaste={(e) => handleCodePaste(e)}
                      onKeyDown={(e) => handleKeyDown(index, e)}
                    />
                  ))}
                </div>
                {error && <p className="text-[#FF0000]">Llena todos los campos</p>}
              </div>

              <div className='my-4'>
                <button
                  type="submit"
                  className="flex items-center text-primary bg-secondary rounded-[50px] py-[0.6rem] md:py-[0.8rem] p-[1.2rem] md:p-[1.6rem] font-Montserrat font-[700] text-[10px] md:text-[14px] shadow-lg hover:text-secondary hover:bg-primary"
                >
                  {loading && <Spin indicator={antIcon} />}
                  <p className="font-[600]">{t('CONFIRMAR CORREO')}</p>
                </button>
              </div>

              <div className="text-center mt-[1rem]">
                <p>
                  Aún no tienes codigo de verificación?{' '}
                  <span
                    disabled={resend}
                    className={`${resend ? 'line-through' : 'cursor-pointer hover:text-primary'
                      } font-[700]`}
                    onClick={() => resend_confirmation_code()}
                  >
                    toca aquí para enviar uno nuevo
                  </span>
                </p>
              </div>
            </div>
          </form>
        </div>
      </LoginBackground >
      <Footer text="¡Queremos conectar contigo!" />
    </>
  );
};

export default ConfirmSignup;

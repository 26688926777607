import React from 'react';
import { t } from 'i18next';

const HeaderBuyerOrTraveler = ({
  selectedOption,
  handleOptionClick,
  content,
  firstNameElement,
  SecondNameElement,
  position
}) => {
  return (
    <div className="flex flex-col items-center gap-[1rem]">
      <div className="grid grid-cols-2 gap-0">
        <div
          onClick={() => handleOptionClick(firstNameElement)}
          className={`w-[150px] text-center border-b-2 border-solid cursor-pointer font-Montserrat font-bold text-[20px] hover:text-[#0a7379] ${selectedOption === firstNameElement
            ? 'text-[#0a7379]'
            : 'text-[#a1a1a1]'
            }
            ${selectedOption === firstNameElement
              ? 'border-[#0a7379]'
              : 'border-[#a1a1a1]'
            }
          `}
        >
          {t(firstNameElement)}
        </div>
        <div
          onClick={() => handleOptionClick('Birdybuyer')}
          className={`w-[150px] text-center border-b-2 border-solid cursor-pointer font-Montserrat font-bold text-[20px] hover:text-[#0a7379] ${selectedOption === 'Birdybuyer'
            ? 'text-[#0a7379]'
            : 'text-[#a1a1a1]'
            }
            ${selectedOption === 'Birdybuyer' ? 'border-[#0a7379]' : 'border-[#a1a1a1]'}
          `}
        >
          {t(SecondNameElement)}
        </div>
      </div>
      {content}
    </div>
  );
};

export default HeaderBuyerOrTraveler;

import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { Button, Form, Input, DatePicker, Select } from 'antd';
import { Table } from 'flowbite-react';
import Swal from 'sweetalert2';

import { pages } from '../../hooks/useGoTraveler';
import { airlinesApi } from '../../api/airlines';

const AddFlightDate = ({ setPage, trip, setTrip }) => {
  const [form] = Form.useForm();
  const [airlines, setAirlines] = useState([]);

  useEffect(() => {
    document.querySelector('.header').classList.add('top-[-80px]');

    if (trip) {
      form.setFieldsValue({ ...trip });
    }

    airlinesApi
      .list_airlines()
      .then((response) => {
        setAirlines(response.data.result.items);
      })
      .catch((error) => {
        console.error(error);
        Swal.fire('JuanPEPE', 'No funciona las aerolineas', 'error');
      })
      .finally(() => {});

    return () => {
      if (document.querySelector('.header')) {
        document.querySelector('.header').classList.remove('top-[-80px]');
      }

      if (trip) {
        form.setFieldsValue();
      }
    };
  }, []);

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf('day');
  };

  const onFinish = (values) => {
    let date = values.date_flight.$d;

    let fecha = new Date(date);
    let ano = fecha.getFullYear();
    let mes = ('0' + (fecha.getMonth() + 1)).slice(-2); // Agrega ceros a la izquierda si es necesario
    let dia = ('0' + fecha.getDate()).slice(-2); // Agrega ceros a la izquierda si es necesario

    let fechaFormateada = ano + '-' + mes + '-' + dia;

    setTrip({
      ...trip,
      airline_id: values.airline,
      flight_number: values.flight_number,
      date_flight: fechaFormateada,
    });
    setPage(pages.addLuggage);
  };

  return (
    <Form
      layout="vertical"
      style={{
        margin: 10,
      }}
      onFinish={onFinish}
      requiredMark={false}
      form={form}
    >
      <h3 className="text-xl font-medium text-gray-900 dark:text-white">
        Registra tu viaje
      </h3>
      <div className="flex justify-between">
        <Form.Item
          label="Fecha de vuelo"
          name="date_flight"
          rules={[{ required: true }]}
        >
          <DatePicker disabledDate={disabledDate} format={'YYYY/MM/DD'} />
        </Form.Item>

        <Form.Item
          label="Aerolinea"
          name="airline"
          rules={[{ required: true }]}
          style={{ width: 200 }}
        >
          <Select disabled={airlines === null}>
            {airlines &&
              airlines.map((airline) => (
                <Select.Option key={airline.id} value={airline.id}>
                  {airline.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Número de vuelo"
          name="flight_number"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </div>
      <div className="flex justify-center my-[2rem] gap-2">
        <Table striped={true}>
          <Table.Head>
            <Table.Cell>Información del viaje</Table.Cell>
            <Table.Cell />
          </Table.Head>
          <Table.Body className="divide-y">
            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                Origen
              </Table.Cell>
              <Table.Cell>ESTADOS UNIDOS</Table.Cell>
            </Table.Row>
            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                Destino
              </Table.Cell>
              <Table.Cell>COLOMBIA</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
      <div className="flex justify-end">
        <Button htmlType="submit">Siguiente</Button>
      </div>
    </Form>
  );
};

export default AddFlightDate;

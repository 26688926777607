import React from 'react';

import largeCenteredCloud from '../../../assets/background/nube-grande-centrada-azul-birdybacker.svg';
import smallCenteredOrangeCloud from '../../../assets/background/nube-pequeña-centrada-naranja-birdybuyer.svg';
import largeCenteredOrangeCloud from '../../../assets/background/nube-grande-centrada-naranja-birdybuyer.svg';
import smallRightCloud from '../../../assets/background/nube-pequeña-derecha-azul-birdybacker.svg';
import smallLeftCloud from '../../../assets/background/nube-pequeña-izquierda-azul-birdybacker.svg';

const RenderBlogsBackground = ({ children }) => {
  return (
    <div className="relative">
      <img
        className="absolute top-[80px] lg:top-[0.5rem] right-[0.1rem] md:right-[10rem] z-[-1]"
        src={largeCenteredCloud}
        alt="Nube centrada grande azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      <img
        className="absolute w-[240px] md:w-[320px] lg:w-auto top-[500px] lg:top-[400px] right-0 z-[-1]"
        src={smallRightCloud}
        alt="Nube derecha pequeña azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      <img
        className="absolute top-[840px] lg:top-[640px] left-[1rem] md:left-[10rem] z-[-1]"
        src={largeCenteredOrangeCloud}
        alt="Nube centrada grande naranja ilustrada representando al Birdybuyer (comprador) de Birdyback"
      />

      <img
        className="absolute top-[1300px] lg:top-[900px] right-[1rem] md:right-[7rem] z-[-1]"
        src={smallCenteredOrangeCloud}
        alt="Nube centrada pequeña naranja ilustrada representando al Birdybuyer (comprador) de Birdyback"
      />

      <img
        className="absolute w-[240px] md:w-[320px] lg:w-auto top-[1500px] lg:top-[1000px] left-0 z-[-1]"
        src={smallLeftCloud}
        alt="Nube izquierda pequeña azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      <img
        className="absolute top-[1850px] lg:top-[1500px] right-[1rem] md:right-[340px] z-[-1]"
        src={smallCenteredOrangeCloud}
        alt="Nube centrada pequeña naranja ilustrada representando al Birdybuyer (comprador) de Birdyback"
      />

      {/* hidden in tablet and laptop - top */}
      <img
        className="absolute block sm:hidden top-[2200px] right-[0.1rem] z-[-1]"
        src={largeCenteredCloud}
        alt="Nube centrada grande azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      {/* hidden in tablet and laptop - top */}
      <img
        className="absolute block sm:hidden w-[240px] top-[2500px] right-0 z-[-1]"
        src={smallRightCloud}
        alt="Nube derecha pequeña azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      {/* hidden in tablet and laptop - top */}
      <img
        className="absolute block sm:hidden top-[2850px] left-[1rem] z-[-1]"
        src={largeCenteredOrangeCloud}
        alt="Nube centrada grande naranja ilustrada representando al Birdybuyer (comprador) de Birdyback"
      />

      {/* hidden in tablet and laptop - top */}
      <img
        className="absolute block sm:hidden top-[3100px] left-0 z-[-1]"
        src={smallLeftCloud}
        alt="Nube izquierda pequeña azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      {/* hidden in tablet and laptop - bottom */}
      <img
        className="absolute block sm:hidden bottom-[3150px] right-[1rem] z-[-1]"
        src={largeCenteredOrangeCloud}
        alt="Nube centrada grande naranja ilustrada representando al Birdybuyer (comprador) de Birdyback"
      />

      {/* hidden in tablet and laptop - bottom */}
      <img
        className="absolute block sm:hidden bottom-[2900px] right-[0.1rem] z-[-1]"
        src={largeCenteredCloud}
        alt="Nube centrada grande azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      {/* hidden in tablet and laptop - bottom */}
      <img
        className="absolute block sm:hidden w-[240px] bottom-[2600px] right-0 z-[-1]"
        src={smallRightCloud}
        alt="Nube derecha pequeña azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      {/* hidden in tablet and laptop - bottom */}
      <img
        className="absolute block sm:hidden bottom-[2200px] left-[1rem] z-[-1]"
        src={largeCenteredOrangeCloud}
        alt="Nube centrada grande naranja ilustrada representando al Birdybuyer (comprador) de Birdyback"
      />

      {/* hidden in laptop - bottom */}
      <img
        className="absolute block lg:hidden bottom-[1800px] left-[0.1rem] md:left-[5rem] z-[-1]"
        src={largeCenteredCloud}
        alt="Nube centrada grande azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      <img
        className="absolute w-[240px] md:w-[320px] lg:w-auto bottom-[1300px] lg:bottom-[1000px] left-0 z-[-1]"
        src={smallLeftCloud}
        alt="Nube izquierda pequeña azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      <img
        className="absolute bottom-[940px] lg:bottom-[640px] left-[1rem] md:left-[10rem] z-[-1]"
        src={largeCenteredOrangeCloud}
        alt="Nube centrada grande naranja ilustrada representando al Birdybuyer (comprador) de Birdyback"
      />

      <img
        className="absolute w-[240px] md:w-[320px] lg:w-auto bottom-[550px] lg:bottom-[400px] right-0 z-[-1]"
        src={smallRightCloud}
        alt="Nube derecha pequeña azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      <img
        className="absolute bottom-[200px] lg:bottom-[100px] right-[0.1rem] md:right-[13rem] z-[-1]"
        src={largeCenteredCloud}
        alt="Nube centrada grande azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      {children}
    </div>
  );
};

export default RenderBlogsBackground;

import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { Table } from 'flowbite-react';
import { pages } from '../../hooks/useGoTraveler';
import { travelsApi } from '../../api/travels';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { getTravels } from '../../features/user/servicesSlide';

const TripRecap = ({ setPage, trip, sections, setvisible }) => {
  useEffect(() => {
    document.querySelector('.header').classList.add('top-[-80px]');

    return () => {
      if (document.querySelector('.header')) {
        document.querySelector('.header').classList.remove('top-[-80px]');
      }
    };
  }, []);

  const dispatch = useDispatch();

  let cm = sections.cm === 'Cm' ? 10 : 14;
  let kg = sections.kg === 'Kg' ? 100 : 200;

  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  console.log(trip);
  console.log(sections);

  const addTravel = async () => {
    setIsLoading(true);

    await travelsApi
      .create_travel(trip)
      .then((response) => {
        if (response.status === 200) {
          Swal.fire('Saved', 'El viaje se guardo correctamente', 'success');
          setTimeout(() => {
            dispatch(getTravels(JSON.parse(localStorage.getItem('birdyuser'))));
          }, 2500);
          setvisible(false);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.status === 400) {
          Swal.fire('Ups!', 'Something went wrong', 'question');
        }
      })
      .finally(setIsLoading(false));
  };

  return (
    <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8">
      <h3 className="text-xl font-medium text-gray-900 dark:text-white">
        Confirmación
      </h3>

      <Table striped={true}>
        <Table.Head>
          <Table.Cell>Información del vuelo</Table.Cell>
          <Table.Cell />
          <Table.Cell />
          <Table.Cell />
          <Table.Cell />
          <Table.Cell>
            <Button onClick={() => setPage(pages.addDate)}>Editar</Button>
          </Table.Cell>
        </Table.Head>
        <Table.Body className="divide-y">
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
              Origen
            </Table.Cell>
            <Table.Cell>ESTADOS UNIDOS</Table.Cell>
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
              Aerolinea
            </Table.Cell>
            <Table.Cell>{trip.airline}</Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
              Destino
            </Table.Cell>
            <Table.Cell>COLOMBIA</Table.Cell>
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
              Numero de vuelo
            </Table.Cell>
            <Table.Cell>{trip.flight_number}</Table.Cell>
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
              Fecha
            </Table.Cell>
            <Table.Cell>{trip.date_flight}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <div className="flex justify-center gap-2">
        <Table striped={true}>
          <Table.Head>
            <Table.Cell>Espacio disponible</Table.Cell>
            <Table.Cell />
            <Table.Cell />
            <Table.Cell>
              <Button onClick={() => setPage(pages.addSection)}>Editar</Button>
            </Table.Cell>
          </Table.Head>
          <Table.Body className="divide-y">
            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                Sección
              </Table.Cell>
              <Table.Cell>{sections.section}</Table.Cell>
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                Dimensiones
              </Table.Cell>
              <Table.Cell>
                {sections.section === 'flexible'
                  ? sections.section
                  : sections.section === 'floating'
                  ? sections.lengthCapacity + ' ' + sections.length
                  : sections.type
                  ? sections.type * cm + ' ' + sections.cm
                  : 'No'}
              </Table.Cell>
            </Table.Row>
            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                Secciones
              </Table.Cell>
              <Table.Cell>
                {sections.section === 'flexible'
                  ? sections.section
                  : sections.section === 'floating'
                  ? sections.side
                  : sections.type}
              </Table.Cell>
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                Peso
              </Table.Cell>
              <Table.Cell>
                {sections.section === 'flexible'
                  ? sections.section
                  : sections.section === 'floating'
                  ? sections.weightCapacity + ' ' + sections.weight
                  : sections.type
                  ? sections.type * kg + ' ' + sections.kg
                  : 'No'}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
      <div className="flex justify-between w-full">
        <Button onClick={() => setPage(pages.addSection)}>Anterior</Button>

        <Button
          onClick={() =>
            Swal.fire({
              title: 'Estás seguro de que la anterior información es correcta?',
              showDenyButton: true,
              showCancelButton: true,
              confirmButtonText: 'Save',
              denyButtonText: `Don't save`,
            }).then((result) => {
              if (result.isConfirmed) {
                addTravel();
              } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info');
              }
            })
          }
          loading={isLoading}
        >
          Finalizar
        </Button>
      </div>
    </div>
  );
};

export default TripRecap;

import React from 'react';

const BlogComponent = ({ blog }) => {
  console.log(blog);
  return (
    <div className="flex justify-center w-full">
      <div className="bg-[#FFD2B040] flex flex-col justify-between h-[300px] md:h-[360px] w-[300px] md:w-[360px] py-[1rem] px-[2rem] rounded-[20px]">
        {blog.title && (
          <h4 className="text-[#3FC2A0] font-bold">{blog.title}</h4>
        )}
        {blog.name && <h4 className="text-[#3FC2A0] font-bold">{blog.name}</h4>}

        {blog.species && <p>{blog.species}</p>}
        {blog.gender && <p>{blog.gender}</p>}

        <div className="flex justify-end">
          <img
            className="rounded-full w-[120px] h-[120px]"
            src={blog.image && blog.image}
          />
        </div>
      </div>
    </div>
  );
};

export default BlogComponent;

import { useState } from 'react';

import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

// import PLAYSTORE from '../assets/icons/app/PLAYSTORE.svg';
// import APPSTORE from '../assets/icons/app/APPSTORE.svg';
import tiktokicon from '../assets/svg/tiktok-icon.svg';
import twittericon from '../assets/svg/twitter-icon.svg';
import instagramicon from '../assets/svg/instagram-icon.svg';
import logo from '../assets/BirdybackDesign/Logo/03-SVG/logo-birdyback-sin-fuente.svg';
import P from '../components/typography/P';
import ModalSuscription from '../components/ui/ModalSuscription';
import RoundedButton from '../components/ui/RoundedButton';
import HandleScroll from '../util/functions/handleScroll';

import { contactUs } from '../api/contactUs';

const Footer = ({ text }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [email, setEmail] = useState('');

  const handleContactButtonClick = async () => {
    const data = {
      email,
      name: 'Conectemonos',
      subject: 'info',
      message: 'Mensaje enviado por el footer',
    };

    try {
      const response = await contactUs.contact(data);
      setEmail('');
      console.log('Respuesta de la API:', response);
    } catch (error) {
      console.error('Error al enviar datos:', error);
    }
  };

  return (
    <>
      {modalOpen && (
        <ModalSuscription modalOpen={modalOpen} setModalOpen={setModalOpen} />
      )}

      <div className="bg-gradient-to-b from-[#FFF7C0] to-[#D8EA94] flex flex-col w-full h-full z-[3]">
        {/* <div className="flex flex-col justify-center items-center text-center h-[60vh] md:h-[40vh] lg:h-[60vh] max-h-[300px] w-full">
          <h2 className="font-[500] text-primary w-2/3 md:w-full">{text}</h2>
          <div className="flex justify-center flex-col md:flex-row mt-[2rem]">
            <Link onClick={() => setModal2Open(true)}>
              <RoundedButton text="CONTÁCTATE CON NOSOTROS" />
            </Link>
          </div>
        </div> */}

        <div className="flex flex-col items-center gap-[1rem] mt-[2rem] mb-[1rem] h-full">
          <div className="flex flex-col lg:flex-row items-center justify-between px-[1rem] md:px-[2rem] lg:px-[8rem] w-full max-w-[1600px] h-full">
            <div className="flex flex-col justify-between items-center w-[70vw] max-w-[30rem] text-center gap-[3rem] h-auto md:h-[340px]">
              <div className="flex flex-col items-center w-full gap-[1rem]">
                <P className="font-[700]">{t(text)}</P>

                <P>
                  {t(
                    'A nosotros tampoco nos gusta el spam, prometemos enviar solo info que te sume, nada que reste.',
                  )}
                </P>
              </div>

              <div className="flex flex-col items-center w-full mb-[1rem]">
                <img
                  alt="Logo de Birdyback sin fuentes"
                  className="w-[120px]"
                  src={logo}
                // style={{ userSelect: 'none' }}
                />
                <div className="h-[1rem]" />
                <P
                  style={{
                    fontFamily: 'Arciform',
                    fontWeight: 300,
                    userSelect: 'none',
                  }}
                >
                  find your target,
                </P>
                <P style={{ fontFamily: 'Arciform', userSelect: 'none' }}>
                  we got your <span className="font-[900]">back</span>!
                </P>
              </div>
            </div>

            <div className="flex flex-col py-[2rem] items-end lg:py-0 gap-[2rem] lg:gap-0 justify-between w-auto h-auto lg:h-[340px]">
              <div className="bg-white shadow-lg flex items-center rounded-[50px] overflow-hidden">
                <input
                  className="font-Montserrat placeholder:text-primary text-[14px] px-[1.5rem] py-[0.5rem] w-full focus:outline-none"
                  placeholder="Déjanos tu correo electrónico"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button
                  className="bg-secondary font-Montserrat text-[14px] md:text-[16px] text-primary font-[600] px-[0.8rem] md:px-[1.5rem] py-[0.5rem]"
                  onClick={handleContactButtonClick}
                >
                  {t('¡CONECTEMONOS!')}
                </button>
              </div>

              <div className="grid grid-cols-2 md:grid-cols-3 gap-[10px] md:gap-[2rem] mb-[1rem]">
                <div>
                  <P className="font-[700]">{t('Birdyback')}</P>
                  <ul>
                    <li>
                      <Link to="/">
                        <P>{t('Inicio')}</P>
                      </Link>
                    </li>
                    <li>
                      <Link to="/nosotros">
                        <P>{t('Birdycommunity')}</P>
                      </Link>
                    </li>
                    <li>
                      <Link to="/birdybuyer">
                        <P>{t('Birdybuyers')}</P>
                      </Link>
                    </li>
                    <li>
                      <Link to="/birdybacker">
                        <P>{t('Birdybackers')}</P>
                      </Link>
                    </li>
                    {/*                <li>
                      <P>{t('Blog')}</P>
                    </li> */}

                    {/* <li >
                      <P>{t('Birdysellers')}</P>
                    </li> */}
                    <li>
                      <Link to="/faqs">
                        <P>{t('FAQ')}</P>
                      </Link>
                    </li>
                  </ul>
                </div>

                <div>
                  <P className="font-[700]">{t('Contacto')}</P>
                  <ul>
                    <li className="flex items-center gap-[4px]">
                      <FontAwesomeIcon
                        className="text-primary"
                        icon={faEnvelope}
                      />
                      <P>{t('Correo')}</P>
                    </li>
                    <li className="flex items-center gap-[4px]">
                      <FontAwesomeIcon
                        className="text-primary"
                        icon={faPhone}
                      />
                      <P>{t('+1 1234567890')}</P>
                    </li>
                    <li>
                      <Link
                        to="https://twitter.com/thebirdyback"
                        target="_blank"
                        className="flex items-center gap-[4px]"
                      >
                        <img className="text-primary" src={twittericon} />
                        <P>{t('Twitter')}</P>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.instagram.com/thebirdyback"
                        target="_blank"
                        className="flex items-center gap-[4px]"
                      >
                        <img className="text-primary" src={instagramicon} />
                        <P>{t('Instagram')}</P>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.tiktok.com/@thebirdyback"
                        target="_blank"
                        className="flex items-center gap-[4px]"
                      >
                        <img className="text-primary" src={tiktokicon} />
                        <P>{t('TikTok')}</P>
                      </Link>
                    </li>
                  </ul>
                </div>

                <div>
                  <P className="font-[700]">{t('Legal y seguridad')}</P>
                  <ul>
                    {/* <li className="cursor-pointer underline">
                      <P>{t('Socios Birdyback')}</P>
                    </li> */}
                    {/* <li className="cursor-pointer underline">
                      <P>{t('Verificación')}</P>
                    </li> */}
                    <li>
                      <Link to="/seguridad">
                        <P>{t('Seguridad')}</P>
                      </Link>
                    </li>
                    <li>
                      <Link to="/terminos-y-condiciones">
                        <P>{t('Términos y condiciones')}</P>
                      </Link>
                    </li>                    
                    <li>
                      <Link to="/politicas-de-privacidad">
                        <P>{t('Políticas de privacidad')}</P>
                      </Link>
                    </li> 
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="flex flex-col md:flex-row justify-center items-center gap-[2rem]">
            <P className="font-[700]">{t('Descarga nuestra app acá')}</P>

            <div className="flex items-center gap-[0.5rem] md:gap-[1rem] cursor-pointer hover:opacity-[0.5]">
              <img src={PLAYSTORE} />

              <p className="text-[#9FBF28] text-[16px] font-[700]">
                {t('PLAY STORE')}
              </p>
            </div>

            <div className="flex items-center gap-[0.5rem] md:gap-[1rem] cursor-pointer hover:opacity-[0.5]">
              <img src={APPSTORE} />

              <p className="text-[#9FBF28] text-[16px] font-[700]">
                {t('APP STORE')}
              </p>
            </div>
          </div> */}

          <div className="bg-primary w-[96vw] h-[2px] rounded-full" />

          <div className="flex justify-between items-center w-full px-[2rem]">
            <div>
              <p className="text-[14px]">&copy; Birdyback </p>
            </div>

            {/* <div className="flex gap-[1rem]">
              <img className="w-[20px]" src={APPSTORE} />
              <img className="w-[20px]" src={PLAYSTORE} />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;

import React, { useState, useEffect } from 'react';
import { t } from 'i18next';
import { useForm } from 'react-hook-form';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { auth } from '../../api/auth';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { signup as rules } from '../../constants/rules';
import NavBuyer from '../../layouts/NavBuyer';
import Footer from '../../layouts/Footer';
import LoginBackground from '../../components/pages/common/LoginBackgound';
import GeneralTitle from '../../components/ui/Title';
import InputField from '../../components/ui/InputField';

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const onSubmit = ({ email }) => {
    setLoading(true);
    auth
      .forgot_password({ email })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem('email', JSON.stringify(email));
          navigate(t('/confirm-forgot-password'));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <NavBuyer />
      <LoginBackground>
        <div className="h-screen w-screen flex justify-center items-start">
          <form
            className="flex flex-col w-[95%] md:w-[70%] lg:w-1/3 my-[10vh] px-[2rem] md:px-[4rem]"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-col justify-center items-center h-full">
              <div className="text-center my-4">
                <GeneralTitle title={t('¿Olvidaste tu contraseña?')} />
                <p className='mb-14 mt-6 text-primary'>
                  Ingresala dirección de correo electrónico y enviarémos un correo con el código de confirmación para reestablecer tu contraseña
                </p>
              </div>
              {/* 
              <input
                type="email"
                className={`border-b-[0.2rem] border-primary p-[0.8rem] placeholder-primary text-primary bg-[#0F7C79]/[.15] rounded focus:outline-none text-sm w-full`}
                placeholder="Email"
                style={{ transition: 'all .15s ease' }}
                {...register('email', rules.signup.email)}
              /> */}
              <div className='mb-5 w-full'>
                <InputField
                  label='Correo electrónico'
                  type='email'
                  field={register('email', rules.signup.email)}
                />
                {errors['email'] ? (
                  <div className="h-[2rem] flex items-center">
                    <p className="text-xs text-red-700">{errors['email'].message}</p>
                  </div>
                ) : (
                  <div className="h-[2rem]" />
                )}
              </div>

              <div className='my-4'>
                <button
                  type="submit"
                  className="flex items-center text-primary bg-secondary rounded-[50px] py-[0.6rem] md:py-[0.8rem] p-[1.2rem] md:p-[1.6rem] font-Montserrat font-[700] text-[10px] md:text-[14px] shadow-lg hover:text-secondary hover:bg-primary"
                >
                  {loading && <Spin indicator={antIcon} />}
                  <p className="font-[600]">{t('CONFIRMAR CORREO')}</p>
                </button>
              </div>
            </div>
          </form>
        </div>
      </LoginBackground>
      <Footer text="¡Queremos conectar contigo!" />
    </>
  );
};

export default ForgotPassword;

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import RenderBlogs from '../components/pages/blog/RenderBlogs';
import Footer from '../layouts/Footer';
import H1 from '../components/typography/H1';
import HandleScroll from '../util/functions/handleScroll';

const Blog = () => {
  useEffect(() => {
    return () => {
      HandleScroll();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Blog</title>
        <meta name='description' content='Blog de Birdyback' />
      </Helmet>

      <div className="flex justify-center pt-[2rem]">
        <H1 className="text-primary font-bold">BirdyBlog</H1>
      </div>

      <RenderBlogs />

      <Footer />
    </>
  );
};

export default Blog;

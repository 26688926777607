import React from 'react';

import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import legal from '../../../assets/BirdybackDesign/Artes finales Birdyback/Website-Illustrations/svg/birdyback-completamente-legal.svg';
import CardSecurity from '../../ui/CardSecurity';
import HandleScroll from '../../../util/functions/handleScroll';
import footerMountain from '../../../assets/background/birdyback-montaña-footer.svg';
import MediumPadding from '../../ui/MediumPadding';
import P from '../../typography/P';
import BirdyTooltip from '../../ui/BirdyTooltip';

const SecurityContainer = () => {
  return (
    <MediumPadding>
      <section className="relative bg-gradient-to-t from-[#0A7379] via-[#0A7379]/[.8] to-[#0A7379]/[.01] px-0 md:px-[2rem] lg:px-[8rem] w-full wow fadeInUp">
        <div className="flex flex-col md:flex-row items-center">
          <div className="flex justify-center w-auto md:w-1/2">
            <img src={legal} alt="Birdyback satisface todas las regulaciones legales para operar" className="w-[300px] md:w-[500px]" />
          </div>
          <div className="flex flex-col items-center md:items-start text-center mt-[1rem] md:mt-[2rem] md:text-left w-auto md:w-1/2 gap-[2rem]">
            <h2 className="w-[14rem] lg:w-[20rem] leading-tight md:leading-[3rem] lg:leading-[3.5rem] font-[600] text-primary">
              {t('Nos importa tu seguridad')}
            </h2>

            <P className="w-[78vw] md:w-[20rem] lg:w-[30rem] font-[400]">
              <span className="font-[700]">
                {t('Ayudamos a conectar Birdybuyers')}
                <BirdyTooltip text="Compradores" />
                {t(' con Birdybackers')}
                <BirdyTooltip text="Viajeros" />
              </span>
              {', '}
              {t(
                'y lo hacemos llevando nuestra humanidad al mercado de suministro logístico. Sabemos que con fiar no siempre es fácil, pero tranqui, nosotros estamos comprometidos con tu seguridad.',
              )}
            </P>

            <Link onClick={HandleScroll} to={t('/seguridad')}>
              <P className="font-bold underline cursor-pointer">
                {t('Quiero saber cómo me cuidan')} <span>→</span>
              </P>
            </Link>
          </div>
        </div>

        <div className="text-center mb-[4rem] mt-[4rem] md:mt-[10rem]">
          <h2 className="font-[600] text-white">
            {t('Mira cómo se siente nuestra Birdycommunity:')}
          </h2>
        </div>

        <div className="px-[1rem]">
          <Swiper
            style={{
              '--swiper-navigation-color': '#fff',
            }}
            modules={[Navigation]}
            spaceBetween={0}
            slidesPerView={3}
            slidesPerGroup={3}
            loop
            navigation
            // pagination={{ clickable: true }}
            // onSwiper={(swiper) => console.log(swiper)}
            // onSlideChange={() => console.log('slide change')}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 0,
                slidesPerGroup: 1,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 0,
                slidesPerGroup: 2,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 0,
                slidesPerGroup: 3,
              },
            }}
            className="max-w-[1600px] z-0"
          >
            <SwiperSlide className="flex justify-center items-center h-[300px]">
              <CardSecurity
                imagen={legal}
                text='"Es un emprendimiento joven con gran potencial, para ayudarnos a traer esos detalles que en nuestra Colombia no se consigue o salen muy costosos, gracias por pensar en ello."'
                name="Luis Tellez"
                position="Customer success manager"
                company="Oracle"
              />
            </SwiperSlide>

            <SwiperSlide className="flex justify-center lg:border-l-[2px] lg:border-r-[2px] h-[300px] items-center">
              <CardSecurity
                imagen={legal}
                text='"Me parece una iniciativa buenísima, enserio lo necesitamos. Con la facilidad que nos ofrece internet de comprar mercancía a donde sea Birdyback será un éxito."'
                name="Marco Cardona"
                position="Director General"
                company="Humans Films"
              />
            </SwiperSlide>

            <SwiperSlide className="flex justify-center items-center h-[300px]">
              <CardSecurity
                imagen={legal}
                text='"Más que un sueño Birdyback ha sido una camino que hemos decido empezar por convicción creyendo que podemos democratizar aún más el mercado. Haz parte de este camino."'
                name="Harrinson Arrubla"
                position="Director General"
                company="Birdyback"
              />
            </SwiperSlide>
          </Swiper>
        </div>

        <div className="absolute bg-[#0A7379] h-[10px] bottom-[-5px] right-0 w-full" />
      </section>

      <img className="w-full" src={footerMountain} alt="Montaña inversa verde en el footer de la pagina web de Birdyback" />
    </MediumPadding>
  );
};

export default SecurityContainer;

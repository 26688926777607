import { Button, Card } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import {
  PlusOutlined,
  ShoppingCartOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { deleteAddress } from '../../features/user/userAddressesSlide';
import ModalAddress from '../../components/traveler/settings/AddressModal';
import { addAddress } from '../../features/user/ordersSlide';
import Swal from 'sweetalert2';

const CheckoutAddress = () => {
  const addresses = useSelector((store) => store.addresses);

  const orders = useSelector((store) => store.orders);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();

  const currentOrder = orders.find(
    (order) => parseInt(order.id) === parseInt(params.id),
  );

  // console.log('current order',currentOrder);

  //   useEffect(() => {
  //     if (!currentOrder.products) {
  //       return <Navigate to="/" replace />;
  //     }
  //   }, [orders]);

  const initialState = { id: -1 };

  const [address, setaddress] = useState(initialState);
  const [showModal, setShowModal] = useState(false);
  //   const [showModal, setShowModal] = useState({ id: -1 });

  return (
    <div className="bg-secondary h-full p-[7rem]">
      <Button onClick={() => navigate(-1)}>Back</Button>
      <div>
        <p className="text-white">Productos a pagar:</p>
        <div className="bg-[#502000] grid grid-cols-3 gap-[1rem] p-[1rem] rounded-[10px]">
          {currentOrder.products &&
            currentOrder.products.map(
              (product) =>
                product && <Card key={product.id}>{product.category}</Card>,
            )}
          <div className="flex justify-center items-center">
            <Button>Añadir producto</Button>
          </div>
        </div>
      </div>
      <h3>Seleccina una direccion </h3>
      {addresses && (
        <div className="grid grid-cols-4 gap-[2rem]">
          {addresses.map(
            (addresss) =>
              addresss && (
                <Card
                  key={addresss.id}
                  onClick={() => setaddress(addresss)}
                  className={addresss.id === address.id ? 'bg-primary' : null}
                >
                  <h5 className="text-[24px]">{addresss.department}</h5>
                  <p className="text-[16px]">{addresss.description}</p>

                  <div className="ignore flex justify-around gap-[1rem]">
                    <Button
                      onClick={() => {
                        setaddress(addresss);
                        // setmodal(true);
                      }}
                      className="ignore flex items-center"
                      icon={<EditOutlined />}
                    >
                      Editar
                    </Button>

                    <Button
                      onClick={() => {
                        dispatch(deleteAddress(addresss.id));
                      }}
                      className="ignore flex items-center"
                      icon={<DeleteOutlined />}
                      danger
                    >
                      Eliminar
                    </Button>
                  </div>
                </Card>
              ),
          )}
          <div className="flex items-center">
            <Button
              onClick={() => setShowModal(true)}
              className="flex items-center"
              size="large"
              icon={<PlusOutlined />}
            >
              Nueva direccion
            </Button>
          </div>
        </div>
      )}
      <div className="flex justify-end mt-[2rem]">
        <Button
          onClick={() => {
            if (address !== initialState) {
              // if (Order) {
              // } else {
              //   if (localStorage.getItem('id')) {
              //     const id = JSON.parse(localStorage.getItem('id'));
              //     dispatch(addAddress({ id: id, content: address }));
              //   }
              // }
              // setPage('Result');
              // console.log(address);
              dispatch(addAddress({ id: currentOrder.id, address: address }));
              localStorage.setItem('address', JSON.stringify(address));
              navigate(`/checkout/${currentOrder.id}`);
            } else {
              Swal.fire(
                'Ups',
                'Selecciona una direccion para continuar',
                'question',
              );
            }
          }}
        >
          Añadir direccion
        </Button>
      </div>
      <ModalAddress
        modal={showModal}
        setmodal={setShowModal}
        address={address}
      />
    </div>
  );
};

export default CheckoutAddress;

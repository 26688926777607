import React, { useState } from 'react';

import { Button, Card } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import GoBuyer from '../../components/buyer/GoBuyer';
import H1 from '../../components/typography/H1';

const Checkout = () => {
  const orders = useSelector((store) => store.orders);
  const params = useParams();

  const navigate = useNavigate();

  const currentOrder = orders.find(
    (order) => parseInt(order.id) === parseInt(params.id),
  );

  const [method, setmethod] = useState(null);
  const [open, setopen] = useState(null);

  const conditionalComponent = () => {
    switch (method) {
      case 'Pasarela':
        return (
          <div>
            <p>Ingresar datos</p>
          </div>
        );
      case 'Efectivo':
        return (
          <div>
            <p className="text-white">
              Seleccina uno de los siguientes metodos de pago en la categoria{' '}
              {method}
            </p>
            <div className="bg-[#10ff50] grid grid-cols-2 gap-[1rem] p-[1rem] rounded-[10px]">
              <Card>Baloto</Card>
              <Card>Efecty</Card>
            </div>
          </div>
        );
      case 'Tranferencia':
        return (
          <div>
            <p className="text-white">
              Seleccina uno de los siguientes metodos de pago en la categoria{' '}
              {method}
            </p>
            <div className="bg-[#f05020] grid grid-cols-2 gap-[1rem] p-[1rem] rounded-[10px]">
              <Card>QR</Card>
              <Card>Billetera Movil</Card>
            </div>
          </div>
        );
      default:
        return <div className="h-[4rem]" />;
    }
  };

  return (
    <div className="bg-primary pt-[81px] p-[1rem] h-full">
      <Button onClick={() => navigate('/orders')}>Back</Button>
      <H1 className="text-white">Checkout</H1>
      <div className="flex flex-col gap-[2rem]">
        <div className="grid grid-cols-2 items-center gap-[4rem]">
          <div>
            <p className="text-white">Productos a pagar:</p>
            <div className="bg-[#502000] grid grid-cols-3 gap-[1rem] p-[1rem] rounded-[10px]">
              {currentOrder.products.map(
                (product) =>
                  product && <Card key={product.id}>{product.category}</Card>,
              )}
            </div>
          </div>

          <div>
            <div className="bg-secondary p-[1rem] rounded-[10px]">
              <p>Direccion:</p>
              <p>
                {currentOrder.address.kindStreet} {currentOrder.address.street}{' '}
                #{currentOrder.address.numberStreet}-
                {currentOrder.address.numberStreet2}
              </p>
              <p>
                {currentOrder.address.neighborhood} -{' '}
                {currentOrder.address.location},{' '}
                {currentOrder.address.department}
              </p>
            </div>
          </div>
        </div>
        <Button onClick={() => setopen(true)}>Editar</Button>
        <div>
          <p className="text-white">Seleccina un metodo de pago</p>
          <div className="bg-secondary grid grid-cols-3 gap-[1rem] p-[1rem] rounded-[10px]">
            <Card onClick={() => setmethod('Pasarela')}>Pasarela de pago</Card>
            <Card onClick={() => setmethod('Efectivo')}>Efectivo</Card>
            <Card onClick={() => setmethod('Tranferencia')}>
              Tranferencia bancaria
            </Card>
          </div>
        </div>

        <div>{conditionalComponent()}</div>
      </div>
      {open && <GoBuyer open={open} setOpen={setopen} Order={currentOrder} />}
    </div>
  );
};

export default Checkout;

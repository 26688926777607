import React, { useState } from "react";

import StatusCardOrden from "../Orden/StatusCardOrden";
import { AiOutlineStar } from "react-icons/ai";
import { TbPlane } from "react-icons/tb";
import { CgBox } from "react-icons/cg";
import { BsBoxSeam } from "react-icons/bs";
import { RiTruckLine } from "react-icons/ri";
import { GoPerson } from "react-icons/go";
import GeneralCard from "../../../ui/GeneralCard";

const CardContentRight = () => {
  return (
    <>
      <div class="col-start-4 row-start-  flex justify-end">
        <p className="cardDescription inline font-[500] text-[#0A7379]">
          XL
        </p>
        <CgBox className="inline text-[#0A7379] ml-1" size={17} />
      </div>


      <div class="flex justify-end col-start-4 row-start-3">
        <p className="cardDescription font-[700] text-[#0A7379]">
          $ 0.00
        </p>
      </div>
      <div class="self-end col-start-4 text-right row-start-4">
        <p className="inline cardDescription font-[500] text-[#a1a1a1]">
          Junio 30, 2023
        </p>
      </div>
    </>
  );
};

const CardContentLeft = ({ name, color }) => {

  return (
    <>
      <div class="row-span-4">
        <div class="h-full w-[110%] col-start-1 row-start-1 bg-black">
          Foto
        </div>
      </div>

      <div className="ml-1">
        <StatusCardOrden status={name} color={color} />
      </div>


      <div class="col-start-2 row-start-2 ml-1">
        <p className="phoneTitle text-[#A1A1A1] font-[700]">Orden 1</p>
      </div>

      <div class="col-start-2 row-start-3 ml-1">
        <p className="cardDescription text-[#a1a1a1]">
          Isabel Lalinde
        </p>
      </div>
      <div class="col-start-2 row-start-4 self-end ml-1">
        <div>
          <AiOutlineStar className="inline font-[500] text-[#0A7379]" />
          <small className="inline ml-1 font-[500] text-[#0A7379] mr-3">4.5</small>

          <TbPlane className="inline font-[500] text-[#0A7379]" />
          <small className="inline ml-1 font-[500] text-[#0A7379]">4.5</small>
        </div>
      </div>
    </>
  );
};

const CardDetail = ({ MainIcon, CategoryIcon, description, disabled, onInputChange }) => {
  return (
    /*     <div className="bg-[#FFD27A] bg-opacity-25 h-full">
          <div className="flex flex-row mr-8 h-10">
            <div className="w-[2rem] bg-[#FFD27A]">
            </div>
            <div className="w-[3rem] flex justify-end items-center">
              <MainIcon size={25} color="#a1a1a1" />
            </div>
            <div className="basis-12 flex justify-end items-center">
              <CategoryIcon className="ml-5 mr-3" size={25} color="#a1a1a1" />
            </div>
            <div className="basis-12 flex justify-center items-center">
            </div>
            <p className="basis-full flex items-center font-[500] text-[#a1a1a1]">{description}</p>
            <div className="flex items-center">
              <input type="radio" className="text-[#0A7379] bg-gray-100 b-[#0A7379] focus:ring-[#0A7379]"
                disabled={disabled}
                onClick={onInputChange}
              />
            </div>
          </div>
        </div> */

    <div className="h-full">
      <div className="flex flex-row mr-8 h-10">
        <div className="w-[2rem] bg-[#FFD27A]">
        </div>
        <div className="w-[3rem] flex justify-end items-center">
          <MainIcon size={25} color="#a1a1a1" />
        </div>
        <div className="basis-12 flex justify-end items-center">
          <CategoryIcon className="ml-5 mr-3" size={25} color="#a1a1a1" />
        </div>
        <div className="basis-12 flex justify-center items-center">
        </div>
        <p className="basis-full flex items-center font-[500] text-[#a1a1a1]">{description}</p>
        <div className="flex items-center">
          <input type="radio" className="text-[#0A7379] bg-gray-100 b-[#0A7379] focus:ring-[#0A7379]"
            disabled={disabled}
            onClick={onInputChange}
          />
        </div>
      </div>
    </div>
  );
};

const CardOrden = ({ use, status }) => {

  const [inputsDisabled, setInputsDisabled] = useState({
    compra: false,
    condicionFisica: true,
    envio: true,
    recepcion: true,
  });

  const showAlert = () => {
    alert("Alerta");
  };

  const statusTest = [
    {
      status: 'En espera',
      color: '#FFD27A'
    },
    {
      status: 'Activa',
      color: '#63D3A6'
    },
    {
      status: 'Cancelada',
      color: '#F39595'
    },
    {
      status: 'Finalizada',
      color: '#868484'
    },
    {
      status: 'Por pagar',
      color: '#FF9C43'
    },
  ];

  const [enabledIndex, setEnabledIndex] = useState(0);


  return (
    <>
      {use === "active" && status === 'en espera' && (
        <GeneralCard
          detailComponent={<TestRadio />}
          dropdownPosition="end"
          actionIcon={showAlert}
        >
          <CardContentLeft name={statusTest[0].status} color={statusTest[0].color} />
          <CardContentRight />
        </GeneralCard>
      )}

      {use === "active" && status === 'activo' && (
        <GeneralCard
          detailComponent={<CustomDetailComponent enabledIndex={enabledIndex} setEnabledIndex={setEnabledIndex} />}
          dropdownPosition="end"
          iconName={"alert"}
        >
          <CardContentLeft name={statusTest[1].status} color={statusTest[1].color} />
          <CardContentRight />
        </GeneralCard>
      )}

      {use === "active" && status === 'cancelado' && (
        <GeneralCard
          detailComponent={<CustomDetailComponent />}
          dropdownPosition="end"
          iconName={"plus"}
        >
          <CardContentLeft name={statusTest[2].status} color={statusTest[2].color} />
          <CardContentRight />
        </GeneralCard>
      )}

      {use === "active" && status === 'finalizado' && (
        <GeneralCard
          detailComponent={<CustomDetailComponent />}
          dropdownPosition="end"
          iconName={"plus"}
        >
          <CardContentLeft name={statusTest[3].status} color={statusTest[3].color} />
          <CardContentRight />
        </GeneralCard>
      )}

      {use === "active" && status === 'por pagar' && (
        <GeneralCard
          detailComponent={<CustomDetailComponent />}
          dropdownPosition="center"
        >
          <CardContentLeft name={statusTest[4].status} color={statusTest[4].color} />
          <CardContentRight />
        </GeneralCard>
      )}
    </>
  );
};

const CustomDetailComponent = () => {

  const [enabledIndex, setEnabledIndex] = useState(0);

  return (
    <div className="w-full">
      <CardDetail
        key={0}
        MainIcon={BsBoxSeam}
        CategoryIcon={RiTruckLine}
        description="Compra del pedido"
        disabled={0 > enabledIndex}
        onInputChange={() => setEnabledIndex(0 + 1)}
      />
      <CardDetail
        key={1}
        MainIcon={TbPlane}
        CategoryIcon={GoPerson}
        description="Condicion física del pedido"
        disabled={1 > enabledIndex}
        onInputChange={() => setEnabledIndex(1 + 1)}
      />
      <CardDetail
        key={2}
        MainIcon={TbPlane}
        CategoryIcon={GoPerson}
        description="Envío del producto"
        disabled={2 > enabledIndex}
        onInputChange={() => setEnabledIndex(2 + 1)}
      />
      <CardDetail
        key={3}
        MainIcon={TbPlane}
        CategoryIcon={GoPerson}
        description="Recepción del pedido"
        disabled={3 > enabledIndex}
        onInputChange={() => setEnabledIndex(3 + 1)}
      />
    </div>
  );
};

const TestRadio = () => {
  const [enabledIndex, setEnabledIndex] = useState(0);

  console.log(enabledIndex);

  return (
    <>
      {[0, 1, 2].map((index) => (
        <label key={index} >
          <div>
            {index}
          </div>
          <input
            type="radio"
            disabled={index > enabledIndex}
            onClick={() => setEnabledIndex(index + 1)}
          />
          {`Option ${index + 1}`}
        </label >
      ))}

      {/*       {
        [0, 1, 2].map((index) => (
          <CardDetail
            key={index}
            MainIcon={BsBoxSeam}
            CategoryIcon={RiTruckLine}
            description="Compra del pedido"
            disabled={index > enabledIndex}
            onInputChange={() => setEnabledIndex(index + 1)}
          />
        ))
      } */}
    </>
  );
};

export default CardOrden;
import React from 'react';

const InputLocation = ({ label = '', value, onChange, options }) => {
  const handleChange = (e) => {
    onChange(e);
  };

  return (
    <div className="relative mx-auto w-full rounded-[24px] border-[2.5px] border-[#A1A1A1] mb-6 text-[#A1A1A1]">
      <div>
        <select
          value={value}
          onChange={handleChange}
          className="w-full h-1   2 pl-4 border-none rounded-md bg-transparent"
        >
          <option value="" disabled hidden>
            {label}
          </option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        <select
          value={value}
          onChange={handleChange}
          className="w-full h-12 pl-4 border-y-2 border-x-0 border-[#A1A1A1]"
        >
          <option value="" disabled hidden>
            {label}
          </option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        <select
          value={value}
          onChange={handleChange}
          className="w-full h-12 pl-4 border-none rounded-md bg-transparent"
        >
          <option value="" disabled hidden>
            {label}
          </option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <label className="absolute top-[-18px] left-[38px] mt-2 bg-white px-2 text-gray-600 text-[16px]">
        {label}
      </label>
    </div>
  );
};

export default InputLocation;

import React, { useState, useEffect } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { Spinner } from 'flowbite-react';
import { useSignup } from '../../hooks/useSignup';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { auth } from '../../api/auth';
import LoginBackground from '../../components/pages/common/LoginBackgound';
import NavBuyer from '../../layouts/NavBuyer';
import Footer from '../../layouts/Footer';
import MediumPadding from '../../components/ui/MediumPadding';
import img from '../../assets/BirdybackDesign/Artes finales Birdyback/Website-Illustrations/svg/birdybuyer-buscando-productos-online-usa.svg';
import InputField from '../../components/ui/InputField';
import HeaderBuyerOrTraveler from '../../components/ui/HeaderBuyerOrTraveler';
import GeneralTitle from '../../components/ui/Title';

const FormSignup = ({ typeUser = 'Birdybacker' }) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Ingresa un correo electrónico válido')
      .required('El correo electrónico es requerido'),
    password: Yup.string()
      .min(8, 'La contraseña debe tener al menos 8 caracteres')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
        'La contraseña debe contener al menos una letra, un número, una letra en mayúscula y un carácter especial',
      )
      .required('La contraseña es requerida'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
      .required('Se debe confirmar la contraseña'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
  });

  const { loading, onSubmit, setloading, user_type, setUser_type } =
    useSignup();

  const [passwordType, setPasswordType] = useState('password');
  const [passwordType1, setPasswordType1] = useState('password');

  const nameElementsHeader = {
    firstNameElement: 'Birdybacker',
    SecondNameElement: 'Birdybuyer',
  }


  const [selectedOption, setSelectedOption] = useState(nameElementsHeader.firstNameElement);

  useEffect(() => {
    setSelectedOption(typeUser);
  }, [typeUser]);


  const handleOptionClick = (option) => {
    console.log("option:", option);
    setSelectedOption(option);
  };

  const togglePassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
    } else {
      setPasswordType('password');
    }
  };

  const togglePassword1 = () => {
    if (passwordType1 === 'password') {
      setPasswordType1('text');
    } else {
      setPasswordType1('password');
    }
  };

  const signupgoogle = async (credentialResponse) => {
    setloading(true);
    await auth
      .signupgoogle(credentialResponse)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => console.error(error))
      .finally(() => setloading(false));
  };

  return (
    <>
      <NavBuyer />
      <LoginBackground>
        <MediumPadding>
          <div className="flex flex-col md:flex-row justify-center px-[2rem] gap-0 lg:gap-[4rem] items-center w-full h-full">
            <div className="w-[90vw] lg:w-auto hidden md:block lg:block">
              <img
                alt='Imagen de login'
                className="w-[85vw] max-w-[300px] md:max-w-[460px] lg:max-w-[550px] wow fadeInUp"
                src={img}
              />
            </div>

            <div className="flex justify-center w-[90vw] lg:w-auto">
              <HeaderBuyerOrTraveler
                selectedOption={selectedOption}
                handleOptionClick={handleOptionClick}
                firstNameElement={nameElementsHeader.firstNameElement}
                SecondNameElement={nameElementsHeader.SecondNameElement}
                content={
                  <div className="flex gap-[1rem] px-[2rem]">
                    {selectedOption === nameElementsHeader.firstNameElement
                      ?
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div className="flex flex-col px-[2rem] md:px-[4rem]">
                          <div className='text-center my-4'>
                            <GeneralTitle title={t('¡Hola, Birdybacker!')} />
                          </div>
                          <Controller
                            name="email"
                            control={control}
                            render={({ field }) => (
                              /*                       <input
                                                      id="email"
                                                      type="email"
                                                      className={`border-b-[0.2rem] border-primary p-[0.8rem] placeholder-primary text-primary ${user_type === 'buyer'
                                                        ? 'bg-[#0F7C79]/[.15]'
                                                        : 'bg-[#FFBA6E]/[.15]'
                                                        } rounded focus:outline-none text-sm w-full`}
                                                      placeholder="Email"
                                                      style={{ transition: 'all .15s ease' }}
                                                      {...field}
                                                    /> */
                              <InputField
                                id="email"
                                name="email"
                                type='email'
                                label='Email'
                                field={field}
                              />
                            )}
                          />
                          {errors.email ? (
                            <div className="h-[3rem] flex items-center">
                              <p className="text-xs text-red-700">{errors.email.message}</p>
                            </div>
                          ) : (
                            <div className="h-[3rem]" />
                          )}

                          <div className="flex gap-[4px]">
                            <Controller
                              name="password"
                              control={control}
                              render={({ field }) => (
                                <>
                                  {/*                           <input
                            id="password"
                            name="password"
                            type={passwordType}
                            className={`border-b-[0.2rem] border-primary p-[0.8rem] placeholder-primary text-primary ${user_type === 'buyer'
                              ? 'bg-[#0F7C79]/[.15]'
                              : 'bg-[#FFBA6E]/[.15]'
                              } rounded focus:outline-none text-sm w-full`}
                            placeholder="Password"
                            style={{ transition: 'all .15s ease' }}
                            {...field}
                          /> */}
                                  <InputField
                                    id="password"
                                    name="password"
                                    type='password'
                                    label='Password'
                                    field={field}
                                  />

                                  {/*                           <div
                            className="flex items-center px-[1rem] border-b-[0.2rem] border-[0.1rem] border-primary rounded cursor-pointer"
                            onClick={togglePassword}
                          >
                            {passwordType === 'password' ? eyeclosed : eyeopen}
                          </div> */}
                                </>
                              )}
                            />
                          </div>

                          <div className='mt-10'>
                            {errors.password ? (
                              <div className="h-[2rem] flex items-center">
                                <p className="text-xs text-red-700">{errors.password.message}</p>
                              </div>
                            ) : (
                              <div className="h-[2rem]" />
                            )}

                            <div className="flex gap-[4px]">
                              <Controller
                                name="confirmPassword"
                                control={control}
                                render={({ field }) => (
                                  <>
                                    {/*                           <input
                            id="confirmPassword"
                            name="confirmPassword"
                            type={passwordType1}
                            className={`border-b-[0.2rem] border-primary p-[0.8rem] placeholder-primary text-primary ${user_type === 'buyer'
                              ? 'bg-[#0F7C79]/[.15]'
                              : 'bg-[#FFBA6E]/[.15]'
                              } rounded focus:outline-none text-sm w-full`}
                            placeholder="Confirm password"
                            style={{ transition: 'all .15s ease' }}
                            {...field}
                          /> */}
                                    <InputField
                                      id="password"
                                      name="password"
                                      type='password'
                                      label='Confirm password'
                                      field={field}
                                    />

                                    {/*                           <div
                            className="flex items-center px-[1rem] border-b-[0.2rem] border-[0.1rem] border-primary rounded cursor-pointer"
                            onClick={togglePassword1}
                          >
                            {passwordType1 === 'password' ? eyeclosed : eyeopen}
                          </div> */}
                                  </>
                                )}
                              />
                            </div>

                            {errors.confirmPassword ? (
                              <div className="h-[2rem] flex items-center">
                                <p className="text-xs text-red-700">
                                  {errors.confirmPassword.message}
                                </p>
                              </div>
                            ) : (
                              <div className="h-[2rem]" />
                            )}

                          </div>

                          <div className="w-full my-5">
                            <button
                              type="submit"
                              className="flex items-center text-primary bg-secondary rounded-[50px] py-[0.6rem] md:py-[0.8rem] p-[1.2rem] md:p-[1.6rem] font-Montserrat font-[700] text-[10px] md:text-[14px] shadow-lg hover:text-secondary hover:bg-primary wow fadeInUp"
                            >
                              {loading ? (
                                <Spinner aria-label="Center-aligned spinner example" size="xl" />
                              ) : (
                                <p className="font-[600]">{t('REGÍSTRATE')}</p>
                              )}
                            </button>

                            <p className="text-[12px]">
                              Al hacer clic en Regístrarte, aceptas los{' '}
                              <Link
                                to={t('/terminos-y-condiciones')}
                                target="_blank"
                                className="text-primary hover:underline"
                              >
                                Términos de servicio y la Política de privacidad.
                              </Link>
                            </p>
                          </div>

                          <div className="mb-5 mt-16">
                            <GoogleOAuthProvider clientId="532212257021-6bd4ruin4t4trlfd36ihagc4teagtmu1.apps.googleusercontent.com">
                              <GoogleLogin
                                onSuccess={(credentialResponse) => {
                                  signupgoogle(credentialResponse);
                                }}
                                onError={() => {
                                  console.log('Login Failed');
                                }}
                              />
                            </GoogleOAuthProvider>
                          </div>
                        </div>
                      </form>
                      :
                      <div className="flex justify-center w-[90vw] lg:w-auto">
                        <form
                          onSubmit={handleSubmit(onSubmit)}
                        >
                          <div className="flex flex-col px-[2rem] md:px-[4rem]">
                            <div className='text-center my-4'>
                              <GeneralTitle title={t('¡Hola, Birdybuyer!')} />
                            </div>
                            <Controller
                              name="email"
                              control={control}
                              render={({ field }) => (
                                /*                       <input
                                                        id="email"
                                                        type="email"
                                                        className={`border-b-[0.2rem] border-primary p-[0.8rem] placeholder-primary text-primary ${user_type === 'buyer'
                                                          ? 'bg-[#0F7C79]/[.15]'
                                                          : 'bg-[#FFBA6E]/[.15]'
                                                          } rounded focus:outline-none text-sm w-full`}
                                                        placeholder="Email"
                                                        style={{ transition: 'all .15s ease' }}
                                                        {...field}
                                                      /> */
                                <InputField
                                  id="email"
                                  name="email"
                                  type='email'
                                  label='Email'
                                  field={field}
                                />
                              )}
                            />
                            {errors.email ? (
                              <div className="h-[3rem] flex items-center">
                                <p className="text-xs text-red-700">{errors.email.message}</p>
                              </div>
                            ) : (
                              <div className="h-[3rem]" />
                            )}

                            <div className="flex gap-[4px]">
                              <Controller
                                name="password"
                                control={control}
                                render={({ field }) => (
                                  <>
                                    {/*                           <input
                            id="password"
                            name="password"
                            type={passwordType}
                            className={`border-b-[0.2rem] border-primary p-[0.8rem] placeholder-primary text-primary ${user_type === 'buyer'
                              ? 'bg-[#0F7C79]/[.15]'
                              : 'bg-[#FFBA6E]/[.15]'
                              } rounded focus:outline-none text-sm w-full`}
                            placeholder="Password"
                            style={{ transition: 'all .15s ease' }}
                            {...field}
                          /> */}
                                    <InputField
                                      id="password"
                                      name="password"
                                      type='password'
                                      label='Password'
                                      field={field}
                                    />

                                    {/*                           <div
                            className="flex items-center px-[1rem] border-b-[0.2rem] border-[0.1rem] border-primary rounded cursor-pointer"
                            onClick={togglePassword}
                          >
                            {passwordType === 'password' ? eyeclosed : eyeopen}
                          </div> */}
                                  </>
                                )}
                              />
                            </div>

                            <div className='mt-10'>
                              {errors.password ? (
                                <div className="h-[2rem] flex items-center">
                                  <p className="text-xs text-red-700">{errors.password.message}</p>
                                </div>
                              ) : (
                                <div className="h-[2rem]" />
                              )}

                              <div className="flex gap-[4px]">
                                <Controller
                                  name="confirmPassword"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      {/*                           <input
                            id="confirmPassword"
                            name="confirmPassword"
                            type={passwordType1}
                            className={`border-b-[0.2rem] border-primary p-[0.8rem] placeholder-primary text-primary ${user_type === 'buyer'
                              ? 'bg-[#0F7C79]/[.15]'
                              : 'bg-[#FFBA6E]/[.15]'
                              } rounded focus:outline-none text-sm w-full`}
                            placeholder="Confirm password"
                            style={{ transition: 'all .15s ease' }}
                            {...field}
                          /> */}
                                      <InputField
                                        id="password"
                                        name="password"
                                        type='password'
                                        label='Confirm password'
                                        field={field}
                                      />

                                      {/*                           <div
                            className="flex items-center px-[1rem] border-b-[0.2rem] border-[0.1rem] border-primary rounded cursor-pointer"
                            onClick={togglePassword1}
                          >
                            {passwordType1 === 'password' ? eyeclosed : eyeopen}
                          </div> */}
                                    </>
                                  )}
                                />
                              </div>

                              {errors.confirmPassword ? (
                                <div className="h-[2rem] flex items-center">
                                  <p className="text-xs text-red-700">
                                    {errors.confirmPassword.message}
                                  </p>
                                </div>
                              ) : (
                                <div className="h-[2rem]" />
                              )}

                            </div>

                            <div className="w-full my-5">
                              <button
                                type="submit"
                                className="flex items-center text-primary bg-secondary rounded-[50px] py-[0.6rem] md:py-[0.8rem] p-[1.2rem] md:p-[1.6rem] font-Montserrat font-[700] text-[10px] md:text-[14px] shadow-lg hover:text-secondary hover:bg-primary wow fadeInUp"
                              >
                                {loading ? (
                                  <Spinner aria-label="Center-aligned spinner example" size="xl" />
                                ) : (
                                  <p className="font-[600]">{t('REGÍSTRATE')}</p>
                                )}
                              </button>

                              <p className="text-[12px]">
                                Al hacer clic en Regístrarte, aceptas los{' '}
                                <Link
                                  to={t('/terminos-y-condiciones')}
                                  target="_blank"
                                  className="text-primary hover:underline"
                                >
                                  Términos de servicio y la Política de privacidad.
                                </Link>
                              </p>
                            </div>

                            <div className="mb-5 mt-16">
                              <GoogleOAuthProvider clientId="532212257021-6bd4ruin4t4trlfd36ihagc4teagtmu1.apps.googleusercontent.com">
                                <GoogleLogin
                                  onSuccess={(credentialResponse) => {
                                    signupgoogle(credentialResponse);
                                  }}
                                  onError={() => {
                                    console.log('Login Failed');
                                  }}
                                />
                              </GoogleOAuthProvider>
                            </div>
                          </div>
                        </form>
                      </div>
                    }
                  </div>
                }
              />
            </div>
          </div>
        </MediumPadding>
      </LoginBackground>
      <Footer text="¡Queremos conectar contigo!" />
    </>
  );
};

export default FormSignup;

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth, setUser } from '../features/auth/authSlide';
import { auth } from '../api/auth';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

export const useSignup = () => {
  const dispatch = useDispatch();

  const authUser = useSelector((state) => state.auth);

  const [user_type, setUser_type] = useState(
    authUser.userType === 'owner' ? 'owner' : 'buyer',
  );

  const [loading, setloading] = useState(false);

  const navigate = useNavigate();

  const onSubmit = async ({ email, password, confirmPassword }) => {
    setloading(true);

    if (password === confirmPassword) {
      await auth
        .signup({
          email,
          password,
          user_type,
        })
        .then((response) => {
          localStorage.setItem('email', JSON.stringify(email));
          navigate(t('/confirm-signup'));
        })
        .catch((error) => {
          if (error.response.data.error) {
            Swal.fire('Ups', error.response.data.error.message, 'error');
          }
        })
        .finally(() => setloading(false));
    } else {
      setloading(false);
      Swal.fire('Ups', 'Las contraseñas deben coincidir', 'error');
    }

    //   .then(async (response) => {
    //     const birdyuser =
    //       response.data.result.AuthenticationResult.TokenType +
    //       ' ' +
    //       response.data.result.AuthenticationResult.IdToken;
    //     localStorage.setItem('birdyuser', JSON.stringify(birdyuser));
    //     await auth
    //       .getUser(birdyuser)
    //       .then((responseUser) => {
    //         if (responseUser.data.success) {
    //           dispatch(setAuth(response.data.result.AuthenticationResult));
    //           dispatch(setUser(responseUser.data.result));
    //         }
    //       })
    //       .catch((error) => console.error(error));
    //   })
    //   .catch((error) =>
    //     Swal.fire('Ups', error.response.data.error.message, 'error'),
    //   )
    //   .finally(() => setloading(false));
  };

  return { loading, setloading, user_type, setUser_type, onSubmit };
};

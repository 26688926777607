import React from "react";
import { BiChevronUp, BiChevronDown, BiChevronRight } from "react-icons/bi";
import ronDown from '../../../assets/svg/ronDown.svg'
import ronUp from '../../../assets/svg/ronUp.svg'
import SubTitle from "../../../components/ui/SubTitle";
import DescriptionSmall from "../../../components/ui/DescriptionSmall";

const CollapseSetting = ({ id, icon, title, description, isActive, onToggle, isAnotherCollapse = true, onClick }) => {
  return (
    <div className="flex justify-between mb-10"
      style={{ borderTop: id === 'language' ? '2px solid #A1A1A140' : 'none' }}
    >
      <div className="flex flex-row justify-start items-start"
        style={{ marginTop: id === 'language' ? '20px' : '0px' }}
      >
        {icon}
        <div className="ml-8 w-[90%]">
          <SubTitle title={title} />
          <DescriptionSmall description={description} />
        </div>
      </div>
      <button className="text-[#A1A1A1] font-bold text-[25px]" onClick={onToggle}>
        {isAnotherCollapse ? (
          isActive ? (
            <img
              src={ronUp}
              className="w-[40px]"
            />
          ) : (
            <img
              src={ronDown}
              className="w-[40px]"
            />
          )
        ) : (
          <BiChevronRight className="cursor-pointer" onClick={onClick} />
        )}
      </button>
    </div>
  );
};

export default CollapseSetting;

import React, { useState, useEffect } from 'react';

import { t } from 'i18next';
import { ConfigProvider, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import InputField from './InputField';
import RoundedButton from './RoundedButton';
import CardOrden from '../buyer/components/Product/CardOrden';

const OrderCancellation = ({ modalOpen, setModalOpen}) => {


  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            borderRadiusLG: 40,
            paddingContentHorizontal: 0,
            paddingContentVertical: 0,
            paddingLG: 0,
            marginLG: 0,
            paddingMD: 0,
            paddingXL: 0,
            paddingSM: 0,
            paddingContentHorizontalLG: 0,
          },
        },
      }}
    >
      <Modal
        centered
        closeIcon={false}
        open={modalOpen}
        onOk={null}
        onCancel={null}
        footer={null}
        width={800}
      >
        <div className="relative flex flex-col items-center gap-[1rem] pt-[3rem] pb-[3rem] overflow-hidden z-[1]">
          <FontAwesomeIcon
            className="absolute top-[12px] right-[18px] text-[24px] cursor-pointer"
            onClick={() => setModalOpen(false)}
            icon={faXmark}
          />

          <div className='w-[80%] flex flex-col items-center'>
            <div className="text-center">
              <h4 className="font-Montserrat text-primary font-[700]">
                {t('Cancelación de la orden')}
              </h4>
            </div>

            <div className="flex justify-center py-[2rem]">
            </div>

            <div className='w-full'>
              <p>Orden</p>
            </div>

            <div className='w-full pt-[3rem]'>
              <p className='text-left font-Montserrat text-primary font-[700]'>
                {t(
                  'Descripción de la cancelación',
                )}
              </p>
            </div>

            <div className="text-center w-full pt-[2rem]">
              <p>
                {t('Descripción de la cancelacion')}
              </p>
            </div>

            <div className="text-center w-full pt-[2rem]">
              <RoundedButton
                text="Remover contraseña"
              />
            </div>
          </div>

        </div>
      </Modal>
    </ConfigProvider >
  );
};


export default OrderCancellation;

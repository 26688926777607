import GridLayout from "react-grid-layout";
import GridCategoryIcon from "../../../components/buyer/components/Product/GridCategoryIcon";
import ProductIcon from "../../../assets/png/product/Mask group.png";
import IntagramIcon from "../../../assets/svg/instagram-icon.svg";
// Styles
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

const ProductGridLayout = () => {

  const layout = [
    { i: "a", x: 0, y: 0, w: 2, h: 5, static: true },
    { i: "b", x: 2, y: 0, w: 4, h: 4, static: true },
    { i: "c", x: 0, y: 5, w: 2, h: 4, static: true },
    { i: "d", x: 0, y: 9, w: 4, h: 4, static: true },
    { i: "e", x: 2, y: 4, w: 2, h: 5, static: true },
    { i: "f", x: 4, y: 4, w: 2, h: 9, static: true },
  ];

  return (
    <GridLayout
      className="layout mt-10"
      layout={layout}
      cols={12}
      rowHeight={30}
      width={1200}
    >
      <div key="a" className='p-3 border-[#A8EFD9] border-[2.5px] rounded-[15px] flex'
        style={{
          backgroundImage: `url(${ProductIcon})`,
          backgroundSize: 'cover',
        }}
      >
        <p className="text-[#0A7379] font-Montserrat font-bold text-[24px]">
          Tecnología
        </p>
      </div>
      <div key="b" className='border-[#FFBB69] border-[2.5px] rounded-[15px]'>
        {/*       <GridCategoryIcon
        /> */}
      </div>
      <div key="c" className='border-[#E9ED4A] border-[2.5px] rounded-[15px]'>c</div>
      <div key="d" className='border-[#A8EFD9] border-[2.5px] rounded-[15px]'>d</div>
      <div key="e" className='border-[#FFBB69] border-[2.5px] rounded-[15px]'>e</div>
      <div key="f" className='border-[#E9ED4A] border-[2.5px] rounded-[15px]'>f</div>
    </GridLayout>
  );
}

export default ProductGridLayout;
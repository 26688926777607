import { useState } from 'react';

const Language = () => {
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
    // Aquí podrías agregar lógica adicional si es necesaria al cambiar de idioma
  };

  return (
    <div className="max-w-md mx-auto p-4">
      <h2 className="text-2xl font-semibold mb-4">Selecciona un idioma</h2>
      <div>
        <label className="flex items-center mb-2">
          <input
            type="radio"
            value="en"
            checked={selectedLanguage === 'en'}
            onChange={handleLanguageChange}
            className="mr-2"
          />
          Inglés
        </label>
        <label className="flex items-center">
          <input
            type="radio"
            value="es"
            checked={selectedLanguage === 'es'}
            onChange={handleLanguageChange}
            className="mr-2"
          />
          Español
        </label>
      </div>
    </div>
  );
};

export default Language;

import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Label,
  Modal,
  Radio,
  Select,
  TextInput,
  Textarea,
} from 'flowbite-react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  addAddress,
  updateAddress,
} from '../../../features/user/userAddressesSlide';
import { v4 as uuid } from 'uuid';
import { t } from 'i18next';
import citys from '../../../constants/citys';

const ModalAddress = ({ modal, setmodal, address }) => {
  const dispatch = useDispatch();

  const [department, setdepartment] = useState(null);
  const [city, setcity] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ values: address });

  const rules = {
    address: {
      department: {
        required: { value: true, message: 'Campo requerido' },
      },
      location: {
        required: { value: true, message: 'Field required' },
      },
      neighborhood: {
        required: { value: true, message: 'Field required' },
      },
      kindStreet: {
        required: { value: true, message: 'Field required' },
      },
      street: {
        required: { value: true, message: 'Field required' },
      },
      numberStreet: {
        required: { value: true, message: 'Field required' },
      },
      numberStreet2: {
        required: { value: true, message: 'Field required' },
      },
      place: {
        required: { value: true, message: 'Choose one' },
      },
    },
  };

  const onSubmit = async (data) => {
    if (address.department) {
      dispatch(updateAddress(data));
    } else {
      dispatch(addAddress({ ...data, id: uuid() }));
    }
    setmodal(false);
  };

  return (
    <div>
      <Modal
        show={modal}
        popup={true}
        size="4xl"
        onClose={() => setmodal(false)}
      >
        <Modal.Header />
        <Modal.Body>
          <form className="flex flex-col gap-[1rem]">
            <div className="flex justify-around">
              <div className="w-1/3">
                <Label htmlFor="department">{t('Departamento')}</Label>
                <Select
                  id="department"
                  onClick={(e) => {
                    setdepartment(e.target.value);
                    if (e.target.value) {
                      const city = citys.find(
                        (city) => city.departamento === e.target.value,
                      );
                      setcity(city.ciudades);
                    }
                  }}
                  {...register('department', rules['address']['department'])}
                >
                  <option value="" disabled>
                    -- Seleccione un departamento --
                  </option>
                  {citys.map((city) => (
                    <option key={city.id}>{city.departamento}</option>
                  ))}
                </Select>
                {errors['department'] ? (
                  <span className="text-xs text-red-700">
                    {errors['department'].message}
                  </span>
                ) : null}
              </div>
              <div className="w-1/3">
                <Label htmlFor="location">
                  {t('Municipio, capital o localidad')}
                </Label>
                <Select
                  id="location"
                  disabled={department ? false : true}
                  {...register('location', rules['address']['location'])}
                >
                  {department ? (
                    <option value="" disabled>
                      -- Seleccione una ciudad --
                    </option>
                  ) : (
                    <option value="" disabled>
                      -- Seleccione un departamento --
                    </option>
                  )}
                  {city
                    ? city.map((city, index) => (
                        <option key={index}>{city}</option>
                      ))
                    : null}
                </Select>
                {errors['location'] ? (
                  <span className="text-xs text-red-700">
                    {errors['location'].message}
                  </span>
                ) : null}
              </div>
            </div>
            <div className="flex justify-center items-center">
              <div className="w-1/3">
                <Label htmlFor="neighborhood">{t('Barrio')}</Label>
                <TextInput
                  id="neighborhood"
                  {...register(
                    'neighborhood',
                    rules['address']['neighborhood'],
                  )}
                />
                {errors['neighborhood'] ? (
                  <span className="text-xs text-red-700">
                    {errors['neighborhood'].message}
                  </span>
                ) : null}
              </div>
            </div>
            <div className="flex justify-around">
              <div className="w-1/5">
                <Label htmlFor="kindStreet">{t('Tipo de calle')}</Label>
                <Select
                  id="kindStreet"
                  {...register('kindStreet', rules['address']['kindStreet'])}
                >
                  <option value="" disabled>
                    -- Tipo de Calle --
                  </option>
                  <option>Avenida</option>
                  <option>Calle</option>
                </Select>
                {errors['kindStreet'] ? (
                  <span className="text-xs text-red-700">
                    {errors['kindStreet'].message}
                  </span>
                ) : null}
              </div>
              <div className="w-1/5">
                <Label htmlFor="street">{t('Calle')}</Label>
                <TextInput
                  id="street"
                  {...register('street', rules['address']['street'])}
                />
                {errors['street'] ? (
                  <span className="text-xs text-red-700">
                    {errors['street'].message}
                  </span>
                ) : null}
              </div>
              <div className="w-1/5">
                <Label htmlFor="numberStreet">Número</Label>
                <TextInput
                  id="numberStreet"
                  {...register(
                    'numberStreet',
                    rules['address']['numberStreet'],
                  )}
                />
                {errors['numberStreet'] ? (
                  <span className="text-xs text-red-700">
                    {errors['numberStreet'].message}
                  </span>
                ) : null}
              </div>
              <div className="w-1/5">
                <Label htmlFor="numberStreet2">Número 2</Label>
                <TextInput
                  id="numberStreet2"
                  {...register(
                    'numberStreet2',
                    rules['address']['numberStreet2'],
                  )}
                />
                {errors['numberStreet2'] ? (
                  <span className="text-xs text-red-700">
                    {errors['numberStreet2'].message}
                  </span>
                ) : null}
              </div>
            </div>
            <div className="flex justify-center items-center">
              <div>
                <Checkbox id="number" {...register('number')} />
                <Label htmlFor="number">No tengo número</Label>
              </div>
            </div>
            <div className="flex justify-center items-center text-center">
              <div>
                <Label>¿Es tu trabajo o tu casa?</Label>
                <div className="flex justify-between items-center">
                  <Radio
                    id="work"
                    name="place"
                    value="work"
                    {...register('place', rules['address']['place'])}
                  />
                  <Label htmlFor="work">Trabajo</Label>
                  <Radio
                    id="home"
                    name="place"
                    value="home"
                    {...register('place', rules['address']['place'])}
                  />
                  <Label htmlFor="home">Casa</Label>
                </div>
                {errors['place'] ? (
                  <span className="text-xs text-red-700">
                    {errors['place'].message}
                  </span>
                ) : null}
              </div>
            </div>
            <div>
              <Label htmlFor="number">
                Referencias adicionales de esta dirección
              </Label>
              <Textarea
                id="comment"
                placeholder="Descripción de la fachada, puntos de referencia para encontrarla, indicaciones de seguridad, etc."
                required={true}
                rows={4}
                {...register('description')}
              />
            </div>
            <Button type="submit" onClick={handleSubmit(onSubmit)}>
              Agregar
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ModalAddress;

import React, { useState } from 'react';
import { Button } from 'flowbite-react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  changeStep,
  deleteService,
} from '../../../features/user/servicesSlide';
import Swal from 'sweetalert2';
import StatusPanelCard from '../../../components/traveler/services/StatusPanelCard';
import ProductVerification from '../../../components/traveler/components/StatusPanelForm/Product/ProductVerification';
import ProductsFisicCondition from '../../../components/traveler/components/StatusPanelForm/Product/ProductsFisicCondition';
import ProductTracking from '../../../components/traveler/components/StatusPanelForm/Product/ProductTracking';
import ProductShipping from '../../../components/traveler/components/StatusPanelForm/Product/ProductShipping';
import ProductReceipt from '../../../components/traveler/components/StatusPanelForm/Product/ProductReceipt';

const StatusPanel = () => {
  const services = useSelector((store) => store.services);
  const dispatch = useDispatch();

  const params = useParams();

  const currentService = services.items.find(
    (service) => service.id === params.id,
  );

  // const [stepNumber, setstepNumber] = useState(currentService.process.step);
  const [stepNumber, setstepNumber] = useState(0);

  const navigate = useNavigate();

  const handleStep = (id, step) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, update it!',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Ok', 'Aun no hay endpoint', 'success');
        // const now = new Date().toLocaleTimeString();
        // if (step === 1) {
        //   dispatch(changeStep({ id, step, verification: now }));
        // } else if (step === 2) {
        //   dispatch(changeStep({ id, step, condition: now }));
        // } else if (step === 3) {
        //   dispatch(changeStep({ id, step, arrival: now }));
        // } else if (step === 4) {
        //   dispatch(changeStep({ id, step, shipping: now }));
        // }
        setstepNumber(stepNumber + 1);
      }
    });
  };

  const cancelService = () => {
    Swal.fire({
      title: 'Are you really sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        navigate(-1);
        setTimeout(() => {
          dispatch(deleteService(currentService.id));
        }, 1000);
      }
    });
  };

  return (
    <div className="flex justify-center gap-[4rem] mt-[81px]">
      <Button onClick={() => navigate(-1)}>Back</Button>
      <StatusPanelCard
        cancelService={cancelService}
        currentService={currentService}
      />
      <div className="flex flex-col justify-center">
        <ProductVerification
          cancelService={cancelService}
          currentService={currentService}
          stepNumber={stepNumber}
          handleStep={handleStep}
        />
        <ProductsFisicCondition
          cancelService={cancelService}
          currentService={currentService}
          stepNumber={stepNumber}
          handleStep={handleStep}
        />
        <ProductTracking
          cancelService={cancelService}
          currentService={currentService}
          handleStep={handleStep}
          stepNumber={stepNumber}
        />
        <ProductShipping
          cancelService={cancelService}
          currentService={currentService}
          handleStep={handleStep}
          stepNumber={stepNumber}
        />
        <ProductReceipt
          currentService={currentService}
          stepNumber={stepNumber}
        />
      </div>
    </div>
  );
};

export default StatusPanel;

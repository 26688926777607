import React, { useState, useEffect } from 'react';

import RoundedButton from '../../components/ui/RoundedButton';
import LoginBackground from '../../components/pages/common/LoginBackgound';
import MediumPadding from '../../components/ui/MediumPadding';
import GeneralTitle from '../../components/ui/Title';
import { t } from 'i18next';
import InputField from '../../components/ui/InputField';
import { Link } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import HeaderBuyerOrTraveler from '../../components/ui/HeaderBuyerOrTraveler';
import { useLogin } from '../../hooks/useLogin';

const ComponentMountainLogin = ({
  img,
  typeUser = 'Birdybacker'
}) => {
  const nameElementsHeader = {
    firstNameElement: 'Birdybacker',
    SecondNameElement: 'Birdybuyer',
  }


  const [selectedOption, setSelectedOption] = useState(nameElementsHeader.firstNameElement);

  useEffect(() => {
    setSelectedOption(typeUser);
  }, [typeUser]);


  const handleOptionClick = (option) => {
    console.log("option:", option);
    setSelectedOption(option);
  };

  return (
    <LoginBackground>
      <MediumPadding>
        <div className="flex flex-col md:flex-row justify-center px-[2rem] gap-0 lg:gap-[4rem] items-center w-full h-full">
          <div className="w-[90vw] lg:w-auto hidden md:block lg:block">
            <img
              alt='Imagen de login'
              className="w-[85vw] max-w-[300px] md:max-w-[460px] lg:max-w-[550px] wow fadeInUp"
              src={img}
            />
          </div>

          <div className="flex justify-center w-[90vw] lg:w-auto">
            <HeaderBuyerOrTraveler
              selectedOption={selectedOption}
              handleOptionClick={handleOptionClick}
              firstNameElement={nameElementsHeader.firstNameElement}
              SecondNameElement={nameElementsHeader.SecondNameElement}
              content={
                <div className="flex gap-[1rem] px-[2rem]">
                  {selectedOption === nameElementsHeader.firstNameElement
                    ? <FormLogin title={t('¡Hola, ' + nameElementsHeader.firstNameElement + '!')} userTypeForm={('owner')} />
                    : <FormLogin title={t('¡Hola, ' + nameElementsHeader.SecondNameElement + '!')} userTypeForm={('buyer')} />
                  }
                </div>
              }
            />
          </div>
        </div>
      </MediumPadding>
    </LoginBackground>
  );
};

const FormLogin = ({ title, userTypeForm }) => {

  const { loading, userType, setUserType, onSubmit } = useLogin();

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const submitForm = () => {
    console.log('formData:', formData);
    onSubmit(formData);
  }

  const [errors, setErrors] = useState({
    email: '',
    password: ''
  });

  const handleChange = (e, field) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  console.log('userTypeForm:', userTypeForm);

  useEffect(() => {
    setUserType(userTypeForm);
  }, [userTypeForm]);

  return (
    <form className='w-full'>
      <div className='text-center my-4'>
        <GeneralTitle title={t(title)} />
      </div>
      <div className='mt-10'>
        <InputField label='Correo electrónico' type='email'
          onChange={(e) => handleChange(e, 'email')}
        />
        {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}

        <InputField label='password' type='password'
          onChange={(e) => handleChange(e, 'password')}
        />
        {errors.password && <p style={{ color: 'red' }}>{errors.password}</p>}
      </div>
      <div class="flex justify-between">
        <div>
          <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
          <small className="ml-2 inline text-[#A1A1A1] font-[700]">Recordarme</small>
        </div>
        <div>
          <Link
            to={t('/forgot-password')}
            className="text-primary font-[700] text-center"
          >
            <small className="inline text-[#A1A1A1]">¿Olvidaste la contraseña?</small>
          </Link>
        </div>
      </div>

      <div className="w-full my-5">
        <RoundedButton text='INGRESAR'
          onClick={submitForm}
        />
      </div>

      <div className='mb-5 mt-16'>
        <GoogleOAuthProvider clientId="532212257021-6bd4ruin4t4trlfd36ihagc4teagtmu1.apps.googleusercontent.com">
          <GoogleLogin
            onSuccess={(credentialResponse) => {
              console.log(credentialResponse);
            }}
            onError={() => {
              console.log('Login Failed');
            }}
            shape='pill'
            size='large'
          />
        </GoogleOAuthProvider>
      </div>

      <div>
        <Link
          to={t('/signup')}
          className="text-primary font-[700] text-center"
        >
          <p className="inline font-[600] text-[#A1A1A1]">
            ¿Aún no tienes una cuenta?
            <span className='text-[#0A7379] ml-2'>
              Regístrate
            </span>
          </p>
        </Link>
      </div>
    </form >
  );
}

export default ComponentMountainLogin;

import React, { useState } from "react";
import { t } from 'i18next';
import Description from "../../../ui/Description";
import InputField from "../../../ui/InputField";
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import BirdyTooltip from "../../../ui/BirdyTooltip";

const BirdybuyerOffer = ({ use, status }) => {

  return (
    <div className="max-w-screen-lg mx-auto bg-white rounded-[20px] border border-solid border-[#ececec] shadow mb-4 py-4 px-2">
      <div className="grid grid-cols-2 gap-3">
        <div className="flex justify-start basis-1/2 items-center">
          <span className="font-[700] text-primary">
            <span className="inline mr-2">
              {t('Oferta de pago al viajero')}
            </span>
            <BirdyTooltip text="¡Piensa un monto de dinero para que tu Birdybacker pueda ganar bien por traer tu encargo!" />
          </span>
        </div>
        <div className="flex justify-center items-center">
          <InputField
            label="Precio"
            placeholder="USD"
            type="text"
            onChange={() => { }}
          />
        </div>
      </div>
    </div>
  );
};

export default BirdybuyerOffer;
export const login = {
  login: {
    email: {
      required: { value: true, message: 'Email required' },
    },
    password: {
      required: { value: true, message: 'Password required' },
    },
  },
};

export const signup = {
  signup: {
    email: {
      required: { value: true, message: 'Email required' },
    },
    password: {
      required: { value: true, message: 'Password required' },
    },
    confirmPassword: {
      required: { value: true, message: 'Confirm password required' },
    },
  },
};

export const rulesFirstBuyer = {
  trip: {
    date: {
      required: { value: true, message: 'Campo requerido' },
    },
    aeroline: {
      required: { value: true, message: 'Field required' },
    },
    flight: {
      required: { value: true, message: 'Field required' },
    },
  },
};

export const rulesSecondBuyer = {
  section: {
    section: {
      required: { value: true, message: 'Choose a option' },
    },
  },
};

import { BiMap } from 'react-icons/bi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { MdOutlineModeEdit } from 'react-icons/md';
import DescriptionSmall from './DescriptionSmall';
import SubTitle from './SubTitle';
import { t } from 'i18next';

import { useDispatch } from 'react-redux';

const CardAddress = ({ address }) => {

  const dispatch = useDispatch();

  const handleMainAddress = (id) => {
  };


  return (
    <div
      className="relative bg-white w-full flex flex-col p-[1rem] rounded-[35px] border border-[#0A7379] shadow-md"
      key={address.id}
    >
      <div class="grid gap-1 grid-cols-2">
        <div class="items-start lg:ml-16 bg-red-300">
          <div>
            <BiMap className="inline text-[#A1A1A1] text-3xl lg:text-4xl mr-1 lg:mr-4" />
            <SubTitle title="Casa" />
          </div>

          <div className='lg:pl-14 pl-7'>
            <DescriptionSmall
              description={t('Cra 14 # 12 - 10')}
            />
            <small className="inline text-[#A1A1A1] font-medium">
              Manizales, Caldas
            </small>
          </div>
        </div>
        <div class="text-right lg:mr-16 pb-4 bg-blue-300">
          <div>
            <input checked id="purple-radio" type="radio" value="" name="colored-radio" class="w-4 h-4 text-[#0A7379] bg-gray-100 border-[#0A7379] focus:ring-[#0A7379] rounded-full"
              onClick={() => handleMainAddress(address.id)}
            />
          </div>
          <div class="mt-6">
            <MdOutlineModeEdit className="cursor-pointer hover:text-[#0A7379] inline text-[#A1A1A1] font-bold text-[25px]" />
            <RiDeleteBinLine className="cursor-pointer hover:text-[#0A7379] ml-[16px] inline text-[#A1A1A1] font-bold text-[25px]" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardAddress;
import { Button, TextInput } from 'flowbite-react';
import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changePhone } from '../../../features/auth/authSlide';
import Swal from 'sweetalert2';
import 'react-phone-input-2/lib/style.css';

const VerifyPhone = () => {
  const dispatch = useDispatch();
  const [phone, setphone] = useState('');
  const [ok, setok] = useState(false);
  const [code, setcode] = useState('');
  const [accept, setaccept] = useState(true);

  const navigate = useNavigate();

  const password = '123456';

  const checkPhone = () => {
    return (
      <div className="bg-secondary m-auto rounded-lg flex flex-col justify-center items-center p-[4rem] gap-[2rem]">
        <h2 className="text-[20px] text-primary">
          Ingresa el numero a verificar
        </h2>
        <div className="flex justify-center">
          <PhoneInput
            onlyCountries={['co', 'us']}
            countryCodeEditable={false}
            preserveOrder={['onlyCountries', 'preferredCountries']}
            country={'co'}
            value={phone}
            onChange={(phone) => {
              if (phone) {
                setphone(phone);
              }
            }}
          />
        </div>
        <Button
          disabled={phone ? false : true}
          onClick={() => {
            if (phone > 110000000) {
              setok(true);
            }
          }}
        >
          Verificar
        </Button>
      </div>
    );
  };

  const verifyPhone = () => {
    return (
      <div className="bg-secondary m-auto rounded-lg flex flex-col justify-center items-center p-[4rem] gap-[2rem]">
        <h2 className="text-[25px] text-primary">Código de verificación</h2>
        <p className="text-primary">Ingrese el código de verificación</p>
        <div className="flex justify-center">
          <TextInput
            defaultValue={code}
            maxLength={6}
            onChange={(e) => {
              setcode(e.target.value);
              if (e.target.value === password) {
                setaccept(false);
              } else {
                setaccept(true);
              }
            }}
          />
        </div>
        <div className="flex justify-center">
          <Button>Reenviar código de verificación</Button>
        </div>
        <div className="flex justify-center">
          <Button onClick={() => setok(false)}>Cambiar numero</Button>
        </div>
        <div className="flex justify-center">
          <Button
            disabled={accept}
            onClick={() => {
              Swal.fire('Saved!', '', 'success')
                .then((result) => {
                  if (result.isConfirmed) {
                    dispatch(changePhone({ phone_number: phone }));
                  } else if (result.isDenied) {
                    Swal.fire('Changes are not saved', '', 'info');
                  }
                })
                .finally(() => {
                  navigate('/settings');
                });
            }}
          >
            Verificar telefono
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-primary h-[100vh] pt-[81px] flex">
      {ok ? verifyPhone() : checkPhone()}
    </div>
  );
};

export default VerifyPhone;

import React, { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

//Layouts
import LayoutBuyer from './layouts/layoutBuyer';

// Nav
import NavNormal from './layouts/NavNormal';
import NavTraveler from './layouts/NavTraveler';

// Landing
import Home from './pages/Home';
import Shopper from './pages/Shopper';
import Traveler from './pages/Traveler';
import AboutUs from './pages/AboutUs';
import Security from './pages/Security';
import Login from './pages/login/Login';
import Signup from './pages/login/Signup';
import QA from './pages/Q&A';
import Blog from './pages/Blog';
import BlogDetails from './pages/BlogDetails';

// Buyer
import HomeBuyer from './pages/buyer/Home';
import Orders from './pages/buyer/Orders';
import Saved from './pages/buyer/Saved';
import Checkout from './pages/buyer/Checkout';
import CheckoutAddress from './pages/buyer/CheckoutAddress';

// Traveler
import HomeTraveler from './pages/traveler/Home';
import Services from './pages/traveler/Services';
import StatusPanel from './pages/traveler/service/StatusPanel';
import ServiceInProgress from './pages/traveler/service/ServiceInProgress';

//Common
import VerifyPhone from './pages/common/settings/VerifyPhone';
import VerifyEmail from './pages/common/settings/VerifyEmail';
import Settings from './pages/common/settings/Settings';

import './App.css';
import './assets/css/tailwind.css';
import './assets/css/animate.css';
import './language';
import WOW from 'wow.js';
import { t } from 'i18next';
import ConfirmSignup from './pages/login/ConfirmSignup';
import ForgotPassword from './pages/login/ForgotPassword';
import ConfirmForgotPassword from './pages/login/ConfirmForgotPassword';
import TermsConditions from './pages/TermsConditions';
import PoliticsPrivacy from './pages/PoliticsPrivacy';

new WOW().init();

function App() {
  const [autorizado, setAutorizado] = useState(false);
  const [input, setInput] = useState('');
  const [authorizedOnce, setAuthorizedOnce] = useState(false);

  useEffect(() => {
    // Check if the user has been authorized before
    const hasAuthorizedOnce = sessionStorage.getItem('authorizedOnce');
    if (hasAuthorizedOnce) {
      setAutorizado(true);
      setAuthorizedOnce(true);
    }
  }, []);

  const auth = useSelector((state) => state.auth);
  const pass = 'M&hd6s7BkF#^Q!CRCc2kaU4Is';

  const onChangeInput = (e) => {
    setInput(e.target.value);
  };

  const autorizar = () => {
    if (input === pass) {
      setAutorizado(true);
      // Store a flag in sessionStorage to indicate that the user has been authorized
      sessionStorage.setItem('authorizedOnce', 'true');
    } else {
      alert('Usted no esta autorizado');
    }
  };

  return (
    <>
      {autorizado ? (
        <HelmetProvider >
          <BrowserRouter>
            <Routes>
              {auth.success && auth.user.user_type === 'owner' ? (
                <Route path="/" element={<NavTraveler />}>
                  <Route path="/" element={<HomeTraveler />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/services" element={<Services />} />
                  <Route path="/service/panel/:id" element={<StatusPanel />} />
                  <Route path="/service/:id" element={<ServiceInProgress />} />
                  <Route path="/verifyPhone" element={<VerifyPhone />} />
                  <Route path="/verifyEmail" element={<VerifyEmail />} />
                </Route>
              ) : auth.success && auth.user.user_type === 'buyer' ? (
                <Route path="/" element={<LayoutBuyer />}>
                  <Route index element={<HomeBuyer />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/saved" element={<Saved />} />
                  <Route path="/orders" element={<Orders />} />
                  <Route path="/checkout/:id" element={<Checkout />} />
                  <Route path="/checkout/address/:id" element={<CheckoutAddress />} />
                  <Route path="/verifyPhone" element={<VerifyPhone />} />
                  <Route path="/verifyEmail" element={<VerifyEmail />} />
                </Route>
              ) : (
                <>
                  <Route element={<NavNormal />}>
                    <Route index path={'/'} element={<Home />} />
                    <Route path={t('/landing')} element={<Home />} />
                    <Route path={t('/nosotros')} element={<AboutUs />} />
                    <Route path={t('/seguridad')} element={<Security />} />
                    <Route path={t('/birdybuyer')} element={<Shopper />} />
                    <Route path={t('/birdybacker')} element={<Traveler />} />
                    <Route path="/faqs" element={<QA />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/blog/:id" element={<BlogDetails />} />
                  </Route>
                  <Route
                    path={t('/forgot-password')}
                    element={<ForgotPassword />}
                  />
                  <Route
                    path={t('/politicas-de-privacidad')}
                    element={<PoliticsPrivacy />}
                  />
                  <Route
                    path={t('/terminos-y-condiciones')}
                    element={<TermsConditions />}
                  />
                  <Route path={t('/confirm-signup')} element={<ConfirmSignup />} />
                  <Route
                    path={t('/confirm-forgot-password')}
                    element={<ConfirmForgotPassword />}
                  />
                  <Route path={t('/signup')} element={<Signup />} />
                  <Route path={t('/login')} element={<Login />} />
                </>
              )}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </BrowserRouter>
        </HelmetProvider>
      ) : (
      <div className='flex flex-col items-center mt-14'>
        <label>Ingrese el codigo para darle autorización</label>
        <input className='my-7 bg-gray-dark py-1 border border-gray-300' type="password" onChange={onChangeInput}/>
        <button className='bg bg-blue-700 p-2 b-2 text-white rounded-lg' onClick={autorizar}>Ingresar</button>
      </div>
      )}
    </>
  );
}

export default App;